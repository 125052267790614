import React, { useEffect, useRef, useState } from "react";
import UserBase from "../core/user/UserBase";
import { NavLink } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { isAuthenticated } from "../auth/helper";
import { activateEbook, ebookActivationStatus } from "./helper/userapicalls";
import { getValue } from "@testing-library/user-event/dist/utils";
import { Toast } from "primereact/toast";
import Timestamp from "react-timestamp";

const ActivateEbook = () => {
  const { user, token } = isAuthenticated();

  const [promoCode, setPromoCode] = useState("");

  const [activatedPromoCode, setActivatedPromoCode] = useState(false);
  const [activatedPromoCodeMessage, setActivatedPromoCodeMessage] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const toast = useRef(null);

  const preload = () => {
    //checking valid license before admitting the user inside chapters
    ebookActivationStatus(user._id,token)
    .then((data) => {
      if (data.error) {
        setActivatedPromoCodeMessage(data.error)
        setActivatedPromoCode(false)
      } else {
        setActivatedPromoCode(data);
      }
    })
    .catch((err) => {
      setActivatedPromoCode(false)
    });
  }

  useEffect(() => {
    preload()
  }, [])
  

  const onSubmit = () => {
    setSubmitted(true);
    setPromoCode(promoCode);
    if (promoCode) {
      activateEbook(user._id, token, promoCode)
        .then((data) => {
          if (data.error) {
            toast.current.show({
              severity: "error",
              summary: "Activation Failed",
              detail: data.error,
              life: 5000,
            });
          } else {
            setActivatedPromoCode(data);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Activated",
              life: 3000,
            });
          }
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Activation Failed",
            detail: err,
            life: 5000,
          });
        });
    }
  };

  const handleChange = (name) => (event) => {
    setSubmitted(false);
    const value = event.target.value;
    setPromoCode(value);
  };

  return (
    <UserBase title="Ebook Activation">
      <Toast ref={toast} />

      {activatedPromoCode && (
        <div>
           <div className="d-flex justify-content-center">
            <h2 className="text-primary">
              CONGRATULATIONS on activating the SCOPeBOOK
            </h2>
          </div>
          <div className="d-flex justify-content-center">
            <img src="/images/scopeBanner.png" style={{ height: "20rem" }} />
          </div>
          <div className="col-md-6 offset-md-3">
            <div className="d-flex justify-content-center text-center">
               <h2> You have activated the promocode {activatedPromoCode.code} alloted to {activatedPromoCode.allotedto}
              </h2>
            </div>
            </div>
            
            <div className="d-flex justify-content-center mt-2">
              <h2>  <Timestamp date={activatedPromoCode.activatedat} />
            </h2>
            </div>
          </div>
        
      )}


{!activatedPromoCode && activatedPromoCodeMessage && (
  <div className="col-12">
        <div className="offset-3 col-6 alert-danger text-center">
    <h2>{activatedPromoCodeMessage}</h2>
  </div>
  </div>
  
)}
      {!activatedPromoCode && (
        <div>
          <div className="d-flex justify-content-center">
            <h2 className="text-primary">
              Enter your Promo Code and Activate your SCOPeBOOK
            </h2>
          </div>
          <div className="d-flex justify-content-center">
            <img src="/images/scopeBanner.png" style={{ height: "20rem" }} />
          </div>
          <div className="col-md-6 offset-md-3">
            <div className="d-flex justify-content-center">
              <div className="field w-100">
                <div className="p-inputgroup w-100">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-key"></i>
                  </span>
                  <InputText
                    placeholder="Promo Code"
                    className="w-100"
                    value={promoCode}
                    onChange={handleChange("promoCode")}
                  />
                </div>
              </div>
            </div>
            {submitted && !promoCode && (
              <div className="d-flex justify-content-center mt-1">
                <div className="alert alert-danger">
                  Promo Code is required!
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center mt-2">
              <Button
                label="Activate"
                icon="pi pi-check"
                className="w-100"
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </UserBase>
  );
};

export default ActivateEbook;
