import { API } from "../../backend";
import axios from "axios";

export const uploadFile = (userId, token, data) => {
   return axios.post(`${API}/upload/${userId}`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
   })
    .then(response => {
        return response
    })
    .catch(err=>console.log(err))
}

// ===================START OF CHAPTER TITLE(S) CRUD OPERATIONS================
export const getStats = (userId,token) => {
    return fetch(`${API}/stats/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

// ===================START OF CHAPTER TITLE(S) CRUD OPERATIONS================
export const getAllChapterTitles = (userId,token,chapterId) => {
    return fetch(`${API}/chapter/titles/getAll/${userId}/${chapterId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createChapterTitle = (userId,token,chapterId,chapterTitle) => {
    return fetch(`${API}/chapter/titles/create/${userId}/${chapterId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterTitle)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateChapterTitle = (userId, token, chapterId, chapterTitleId, chapterTitle) => {
    return fetch(`${API}/chapter/titles/update/${userId}/${chapterId}/${chapterTitleId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterTitle)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteChapterTitle = (userId, token, chapterId, chapterTitleId) => {
    return fetch(`${API}/chapter/titles/delete/${userId}/${chapterId}/${chapterTitleId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkChapterTitle = (userId, token, chapterTitles) => {
    return fetch(`${API}/chapter/titles/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterTitles)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterTitle = (userId, token, chapterId, chapterTitleId) => {
    console.log(chapterTitleId)
    return fetch(`${API}/chapter/titles/get/${userId}/${chapterId}/${chapterTitleId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const uploadChapterTitlePhoto = (userId, token, data) => {
    return axios.post(`${API}/chapter/titles/upload/${userId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

// ===================END OF CHAPTER TITLE(S) CRUD OPERATIONS================


// ===================START OF CHAPTER(S) CRUD OPERATIONS================
// used for multiselect in procedures 
export const getAllChaptersList = (userId,token) => {
    return fetch(`${API}/chapter/getAllChapters/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const getAllChapters = (userId,token) => {
    return fetch(`${API}/chapter/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createChapter = (userId,token,chapter) => {
    return fetch(`${API}/chapter/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapter)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateChapter = (userId, token, chapterId, chapter) => {
    return fetch(`${API}/chapter/update/${userId}/${chapterId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapter)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}
export const updateBulkChapters = (userId, token, chapters) => {
    return fetch(`${API}/chapter/updateBulk/${userId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapters)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}
export const deleteChapter = (userId, token, chapterId) => {
    return fetch(`${API}/chapter/delete/${userId}/${chapterId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkChapter = (userId, token, chapters) => {
    return fetch(`${API}/chapter/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapters)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapter = (userId, token, chapterId) => {
    return fetch(`${API}/chapter/get/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// CHAPTER PHOTOS

export const uploadChapterPhoto = (userId, token, data) => {
    return axios.post(`${API}/chapter/upload/${userId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

 export const uploadMultipleChapterPhotos = (userId, chapterId, token, data) => {
    return axios.post(`${API}/upload/chapter/photos/multi/${userId}/${chapterId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

 export const deleteBulkChapterImages = (userId, token, chapterImageIds) => {
    return fetch(`${API}/chapter/deletePhotos/multi/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterImageIds)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

 export const getChapterImages = (userId,token,chapterId) => {
    return fetch(`${API}/chapter/getChapterPhotos/${userId}/${chapterId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

 export const deleteChapterPhoto = (userId,token,chapterImageId) => {
    return fetch(`${API}/chapter/deletePhoto/${userId}/${chapterImageId}`,{
        method:"DELETE",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

// BANNERS
export const updateChapterImage = (userId, token, chapterId, chapter) => {
    return fetch(`${API}/chapter/updateChapterImage/${userId}/${chapterId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapter)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkChapterBanners = (userId, token, chapterBannerIds) => {
    return fetch(`${API}/chapter/deleteBanners/multi/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterBannerIds)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const uploadMultipleChapterBanners = (userId, chapterId, token, data) => {
    return axios.post(`${API}/upload/chapter/banners/multi/${userId}/${chapterId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

export const getChapterBanners = (userId,token,chapterId) => {
    return fetch(`${API}/chapter/getChapterBanners/${userId}/${chapterId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const deleteChapterBanner = (userId,token,chapterBannerId) => {
    return fetch(`${API}/chapter/deleteBanner/${userId}/${chapterBannerId}`,{
        method:"DELETE",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

// REFERENCES
export const getAllChapterReferences = (userId,token,chapterId) => {
    return fetch(`${API}/chapter/getChapterReferences/${userId}/${chapterId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createChapterReference = (userId,token,chapterReference) => {
    return fetch(`${API}/chapter/createReference/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterReference)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateChapterReference = (userId, token, chapterReferenceId, chapterReference) => {
    return fetch(`${API}/chapter/updateReference/${userId}/${chapterReferenceId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterReference)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBulkChapterReferences = (userId, token, chapterReferences) => {
    return fetch(`${API}/chapter/updateBulkReferences/${userId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterReferences)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteChapterReference = (userId, token, chapterReferenceId) => {
    return fetch(`${API}/chapter/deleteReference/${userId}/${chapterReferenceId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkChapterReference = (userId, token, chapterReferenceIds) => {
    return fetch(`${API}/chapter/deletebulkReference/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterReferenceIds)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterReference = (userId, token, chapterReferenceId) => {
    return fetch(`${API}/chapter/get/${userId}/${chapterReferenceId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


// ===================END OF CHAPTER(S) CRUD OPERATIONS================

// CHECKLIST
export const uploadChapterChecklistBanner = (userId, token, data) => {
    return axios.post(`${API}/chapter/checklistBannerUpload/${userId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

export const getAllChecklists = (userId,token) => {
    return fetch(`${API}/chapter/getAllChecklists/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const getAllChapterChecklists = (userId,token,chapterId) => {
    return fetch(`${API}/chapter/getChapterChecklists/${userId}/${chapterId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createChapterChecklist = (userId,token,chapterChecklist) => {
    return fetch(`${API}/chapter/createChecklist/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterChecklist)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateChapterChecklist = (userId, token, chapterChecklistId, chapterChecklist) => {
    return fetch(`${API}/chapter/updateChecklist/${userId}/${chapterChecklistId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterChecklist)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBulkChapterChecklists = (userId, token, chapterChecklists) => {
    return fetch(`${API}/chapter/updateBulkChecklists/${userId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterChecklists)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteChapterChecklist = (userId, token, chapterChecklistId) => {
    return fetch(`${API}/chapter/deleteChecklist/${userId}/${chapterChecklistId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkChapterChecklist = (userId, token, chapterChecklistIds) => {
    return fetch(`${API}/chapter/deletebulkChecklist/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterChecklistIds)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterChecklist = (userId, token, chapterChecklistId) => {
    return fetch(`${API}/chapter/get/${userId}/${chapterChecklistId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


// ===================END OF CHAPTER CHECKLIST(S) CRUD OPERATIONS================

// ===================START OF PRICING(S) CRUD OPERATIONS================
export const getAllPricings = (userId,token) => {
    return fetch(`${API}/pricing/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createPricing = (userId,token,pricing) => {
    return fetch(`${API}/pricing/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(pricing)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updatePricing = (userId, token, pricingId, pricing) => {
    return fetch(`${API}/pricing/update/${userId}/${pricingId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(pricing)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deletePricing = (userId, token, pricingId) => {
    return fetch(`${API}/pricing/delete/${userId}/${pricingId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkPricing = (userId, token, pricings) => {
    return fetch(`${API}/pricing/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(pricings)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// ===================END OF PRICING(S) CRUD OPERATIONS================

// ===================START OF AUTHOR(S) CRUD OPERATIONS================
export const getAllAuthors = (userId,token) => {
    return fetch(`${API}/author/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createAuthor = (userId,token,author) => {
    return fetch(`${API}/author/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(author)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateAuthor = (userId, token, authorId, author) => {
    return fetch(`${API}/author/update/${userId}/${authorId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(author)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteAuthor = (userId, token, authorId) => {
    return fetch(`${API}/author/delete/${userId}/${authorId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkAuthor = (userId, token, authors) => {
    return fetch(`${API}/author/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(authors)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// ===================END OF AUTHOR(S) CRUD OPERATIONS================

// ===================START OF HOSPITAL(S) CRUD OPERATIONS================
export const getAllHospitals = (userId,token) => {
    return fetch(`${API}/hospital/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createHospital = (userId,token,hospital) => {
    return fetch(`${API}/hospital/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(hospital)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateHospital = (userId, token, hospitalId, hospital) => {
    return fetch(`${API}/hospital/update/${userId}/${hospitalId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(hospital)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteHospital = (userId, token, hospitalId) => {
    return fetch(`${API}/hospital/delete/${userId}/${hospitalId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkHospital = (userId, token, hospitals) => {
    return fetch(`${API}/hospital/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(hospitals)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// ===================END OF HOSPITAL(S) CRUD OPERATIONS================




// ===================START OF PROMO CODE(S) CRUD OPERATIONS================
export const createPromoCode = (userId,token,promoCode) => {
    return fetch(`${API}/promoCode/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(promoCode)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updatePromoCode = (userId, token, promoCodeId, promoCode) => {
    return fetch(`${API}/promoCode/update/${userId}/${promoCodeId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(promoCode)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deletePromoCode = (userId, token, promoCodeId) => {
    return fetch(`${API}/promoCode/delete/${userId}/${promoCodeId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkPromoCode = (userId, token, promoCodes) => {
    console.log(promoCodes)
    return fetch(`${API}/promoCode/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(promoCodes)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getAllPromoCodes = (userId, token) => {
    return fetch(`${API}/promoCode/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

// ===================END OF PROMO CODE(S) CRUD OPERATIONS================

// ===================START OF USER(S) CRUD OPERATIONS================
export const getAllUsers = (userId,token) => {
    return fetch(`${API}/userProfile/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createUser = (userId,token,user) => {
    return fetch(`${API}/userProfile/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateUser = (userId, token, userProfileId, user) => {
    return fetch(`${API}/userProfile/update/${userId}/${userProfileId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteUser = (userId, userProfileId, token) => {
    return fetch(`${API}/userProfile/delete/${userId}/${userProfileId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkUser = (userId, token, users) => {
    return fetch(`${API}/userProfile/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(users)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// ===================END OF USER(S) CRUD OPERATIONS================

// ===================START OF ACCESSORY(S) CRUD OPERATIONS================
export const getAllAccessories = (adminId,token) => {
    return fetch(`${API}/accessory/getAll/${adminId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const getAllChapterAccessories = (adminId, token) => {
    return fetch(`${API}/accessory/getAllChapter/${adminId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createAccessory = (userId,token,accessory) => {
    return fetch(`${API}/accessory/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(accessory)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const uploadMultipleChapterAccessoriesPhotos = (userId, token, data) => {
    return axios.post(`${API}/upload/chapterAccessories/photos/multi/${userId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }

export const deleteAccessoryPhoto = (userId, token, filename) => {
    return fetch(`${API}/accessory/photo/delete/${userId}?filename=${filename}`, {
        method:"DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
 }

export const updateAccessory = (userId, token, accessoryId, accessory) => {
    return fetch(`${API}/accessory/update/${userId}/${accessoryId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(accessory)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBulkAccessories = (userId, token, accessories) => {
    return fetch(`${API}/accessory/updateBulkAccessories/${userId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(accessories)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteAccessory = (userId, token, accessoryId) => {
    return fetch(`${API}/accessory/delete/${userId}/${accessoryId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkAccessory = (userId, token, accessories) => {
    return fetch(`${API}/accessory/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(accessories)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}
// ===================END OF ACCESSORY(S) CRUD OPERATIONS================

// ===================START OF PROCEDURE(S) CRUD OPERATIONS================
export const getAllProceduresList = (userId,token) => {
    return fetch(`${API}/procedure/getAllProcedures/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const getAllProcedures = (userId,token) => {
    return fetch(`${API}/procedure/getAll/${userId}`,{
        method:"GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const createProcedure = (userId,token,procedure) => {
    return fetch(`${API}/procedure/create/${userId}`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(procedure)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateProcedure = (userId, token, procedureId, procedure) => {
    return fetch(`${API}/procedure/update/${userId}/${procedureId}`, {
        method: "PUT",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(procedure)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBulkProcedures = (userId, token, procedures) => {
    return fetch(`${API}/procedure/updateBulkProcedures/${userId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(procedures)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteProcedure = (userId, token, procedureId) => {
    return fetch(`${API}/procedure/delete/${userId}/${procedureId}`, {
        method: "DELETE",
        headers: {
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteBulkProcedures = (userId, token, procedures) => {
    return fetch(`${API}/procedure/deletebulk/${userId}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept : "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(procedures)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const uploadProcedurePhoto = (userId, token, data) => {
    return axios.post(`${API}/procedure/upload/${userId}`, data, {
     headers: {
         Authorization: `Bearer ${token}`
     }
    })
     .then(response => {
         return response
     })
     .catch(err=>console.log(err))
 }
// ===================END OF PROCEDURE(S) CRUD OPERATIONS================