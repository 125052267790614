import React, { useEffect, useState } from "react";
import Base from "../core/static/Base";
import { sendDelegateVerificationEmail } from "../api/MailerAPI";
import { isAuthenticated } from "./helper";
import { Navigate } from "react-router-dom";

const ProfileVerificationPending = () => {
  const { user } = isAuthenticated();

  const [emailStatus, setEmailStatus] = useState(false);
  const [emailStatusMessage, setEmailStatusMessage] = useState("");

  if (isAuthenticated()) {
    if (user && user.role === "Admin" && user.verified === "Yes") {
      return <Navigate to="/admin/dashboard" />;
    } else if (user && user.role === "Delegate" && user.verified === "Yes") {
      return <Navigate to="/user/dashboard" />;
    }
  }

  const emailMessage = () => {
    return emailStatus && <div>{emailStatusMessage}</div>;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    sendDelegateVerificationEmail(user.email)
      .then((data) => {
        console.log(data);
        if (data.error) {
          setEmailStatusMessage(data.error);
          setEmailStatus(false);
        } else {
          setEmailStatusMessage(data.message);
          setEmailStatus(true);
        }
      })
      .catch((error) => {
        setEmailStatusMessage(error);
        setEmailStatus(false);
      });
  };

  return (
    <Base>
      <div style={{ height: "50px" }}></div>
      <div className="pt-5 mt-5 text-center">
        <h4>
          Your account has not yet been verified through your email! <br />
          Please check for the verification email in your Inbox or Spam. <br />
          If you still haven't received your email <br />
          <button className="btn btn-primary mt-2" onClick={onSubmit}>
            {" "}
            Resend Verification Email{" "}
          </button>
        </h4>

       <b className="p-2 "> {emailMessage()}</b>
        <img src="/images/scopeBanner.jpeg" className="mb-5" style={{ width: "30%" }} />
      </div>
    </Base>
  );
};

export default ProfileVerificationPending;
