import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../core/admin/AdminBase";
import { isAuthenticated } from "../auth/helper";

import {
  createHospital,
  deleteBulkHospital,
  deleteHospital,
  getAllHospitals,
  updateHospital
} from "./helper/adminapicalls";

const Hospitals = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  const [hospital, setHospital] = useState({
    _id: "",
    name: "",
    initials: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    formData: "",
  });

  const {
    _id,
    name,
    initials,
    address,
    city,
    state,
    country,
    postcode,
    formData,
  } = hospital;

  let emptyHospital = {
    name: "",
    initials: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    formData: "",
  };

  const [hospitals, setHospitals] = useState(null);

  const [hospitalDialog, setHospitalDialog] = useState(false);

  const [deleteHospitalDialog, setDeleteHospitalDialog] = useState(false);

  const [deleteHospitalsDialog, setDeleteHospitalsDialog] = useState(false);

  const [selectedHospitals, setSelectedHospitals] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);

  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllHospitals(user._id, token)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          setHospital({ ...hospital, error: data.error });
        } else {
          setHospitals(data);
          setHospital({ ...hospital, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

    // ============START OF CUD OPERATIONS ==================
    const saveHospital = () => {
        setSubmitted(true);
        setHospital({ ...hospital});
        if (!_id) {
          createHospital(user._id, token, hospital)
            .then((data) => {
              if (data.error) {
                setHospital({
                  ...hospital,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "Hospital Not Created",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Hospital Created",
                  life: 3000,
                });
              }
            })
            .catch((err) => console.log(err));
        } else {
          updateHospital(user._id, token, _id, hospital)
            .then((data) => {
              if (data.error) {
                setHospital({
                  ...hospital,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "Promo Code Not Updated",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Promo Code Updated",
                  life: 3000,
                });
              }
            })
            .catch((err) => console.log(err));
        }
        setHospitalDialog(false);
        setHospital(emptyHospital);
      };
    
      const editHospital = (hospital) => {
        setHospital({ ...hospital });
        setHospitalDialog(true);
      };
    
      const deleteHospitalDB = () => {
        deleteHospital(user._id, token, _id)
          .then((data) => {
            if (data.error) {
              setHospital({
                ...hospital,
                error: data.error,
                formData: new FormData(),
              });
              toast.current.show({
                severity: "error",
                summary: "Hospital Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeleteHospitalDialog(false);
              setHospital(emptyHospital);
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Hospital Deleted",
                life: 3000,
              });
              setDeleteHospitalDialog(false);
              setHospital(emptyHospital);
            }
          })
          .catch((err) => console.log(err));
      };
    
      const deleteSelectedHospitals = () => {
        let hospitals = selectedHospitals;
        const hospitalIds = [];
        {
          Object.entries(hospitals).map(([key, value]) =>
            hospitalIds.push(hospitals[key]._id)
          );
        }
        deleteBulkHospital(user._id, token, hospitalIds)
          .then((data) => {
            if (data.error) {
              toast.current.show({
                severity: "error",
                summary: "Hospitals Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeleteHospitalsDialog(false);
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Hospitals Deleted",
                life: 3000,
              });
              setDeleteHospitalsDialog(false);
            }
          })
          .catch((err) => console.log(err));
      };
      // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewHospital = () => {
    setHospital(emptyHospital);
    setSubmitted(false);
    setHospitalDialog(true);
  };

  const hideDialogHospital = () => {
    setSubmitted(false);
    setHospitalDialog(false);
  };

  const hideDeleteHospitalDialog = () => {
    setDeleteHospitalDialog(false);
  };

  const hideDeleteHospitalsDialog = () => {
    setDeleteHospitalsDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const hospitalDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogHospital}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveHospital} />
    </React.Fragment>
  );

  const deleteHospitalDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteHospitalDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteHospitalDB}
      />
    </React.Fragment>
  );

  const deleteHospitalsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteHospitalsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedHospitals}
      />
    </React.Fragment>
  );

  const confirmDeleteHospital = (hospital) => {
    setHospital(hospital);
    setDeleteHospitalDialog(true);
  };

  const confirmDeleteSelectedHospitals = () => {
    setDeleteHospitalsDialog(true);
  };

  const actionBodyTemplateHospital = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editHospital(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteHospital(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================



  // ============START OF EXPORT ==================
  const cols = [
    { field: '_id', header: 'ID' },
    { field: 'name', header: 'Name' },
    { field: 'initials', header: 'Initials' },
    { field: 'address', header: 'Address' },
    { field: 'city', header: 'city' },
    { field: 'state', header: 'state' },
    { field: 'country', header: 'Country' },
    { field: 'postcode', header: 'Post Code' }
];

const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  
  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);

            doc.autoTable(exportColumns, hospitals);
            doc.save('hospitals.pdf');
        });
    });
};

const exportExcel = () => {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(hospitals);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'hospitals');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    
    setHospital({ ...hospital, error: "", [name]: value });
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateHospital = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewHospital}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedHospitals}
          disabled={!selectedHospitals || !selectedHospitals.length}
        />
      </div>
    );
  };

  const rightToolbarTemplateHospital = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
             <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
            <Button type="button" className="m-1" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" className="m-1" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
     
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Hospitals</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Hospitals">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateHospital}
            right={rightToolbarTemplateHospital}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={hospitals}
            selection={selectedHospitals}
            onSelectionChange={(e) => setSelectedHospitals(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} promo codes"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateHospital}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="initials"
              header="Initials"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="address"
              header="Address"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="city"
              header="City"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="state"
              header="State"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="country"
              header="Country"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="postcode"
              header="Post Code"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={hospitalDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Hospital Details"
          modal
          className="p-fluid"
          footer={hospitalDialogFooter}
          onHide={hideDialogHospital}
        >
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <InputText
              id="name"
              value={name}
              onChange={handleChange("name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !name,
              })}
            />
            {submitted && !name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="initials" className="font-bold">
              Initials
            </label>
            <InputText
              id="initials"
              value={initials}
              onChange={handleChange("initials")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !initials,
              })}
            />
            {submitted && !initials && (
              <small className="p-error">Initials is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="address" className="font-bold">
              Address
            </label>
            <InputText
              id="address"
              value={address}
              onChange={handleChange("address")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !address,
              })}
            />
            {submitted && !address && (
              <small className="p-error">Address is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="city" className="font-bold">
             city
            </label>
            <InputText
              id="city"
              value={city}
              onChange={handleChange("city")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !city,
              })}
            />
            {submitted && !city && (
              <small className="p-error">City is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="state" className="font-bold">
             State
            </label>
            <InputText
              id="state"
              value={state}
              onChange={handleChange("state")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !state,
              })}
            />
            {submitted && !state && (
              <small className="p-error">State is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="country" className="font-bold">
             Country
            </label>
            <InputText
              id="country"
              value={country}
              onChange={handleChange("country")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !country,
              })}
            />
            {submitted && !country && (
              <small className="p-error">Country is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="postcode" className="font-bold">
             Post Code
            </label>
            <InputText
              id="postcode"
              value={postcode}
              onChange={handleChange("postcode")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !postcode,
              })}
            />
            {submitted && !postcode && (
              <small className="p-error">Post Code is required.</small>
            )}
          </div>
          
        

         
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteHospitalDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteHospitalDialogFooter}
          onHide={hideDeleteHospitalDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {hospital && (
              <span>
                Are you sure you want to delete <b>{name}</b> hospital?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteHospitalsDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteHospitalsDialogFooter}
          onHide={hideDeleteHospitalsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {hospital && (
              <span>
                Are you sure you want to delete the selected Hospitals?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminBase>
  );
};
export default Hospitals;
