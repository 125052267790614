import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkDelegateVerificationEmail } from "../api/MailerAPI";
import Base from "../core/static/Base";

const VerifyDelegateVerificationEmail = () => {
    let navigate = useNavigate();
    let verificationEmailParams = useParams()

    //getting the params from the url to the component
    let userEmail = verificationEmailParams.email
    let userVerificationCode = verificationEmailParams.code

    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    //setting the verification status variable
    const [verificationStatus, setVerificationStatus] = useState(false)
    const [verificationMessage, setVerificationMessage] = useState("")

    const preload  = () => {
        checkDelegateVerificationEmail(userEmail,userVerificationCode)
        .then((data)=>{
            console.log(data)
            if (data.error) {
                setVerificationStatus(false)
                setVerificationMessage(data.error)
                sleep(5000).then(() => {
                  navigate("/login");
                });
            }
            else{
                setVerificationStatus(true)
                sleep(5000).then(() => {
                    navigate("/login");
                  });
            }   
    })
        .catch((error) => {
          setVerificationStatus(false)
          setVerificationMessage(error)
          sleep(5000).then(() => {
            navigate("/login");
          });
        })
    }

    useEffect(() => {
      preload()
    }, [])
    

    const verificationFailureStatusDisplay = () => {
        return (
            !verificationStatus && (
              <div className="alert alert-danger text-center" style={{marginBottom: "0px"}}>
                <h2>There was some problem in verifying your account. {verificationMessage}</h2>
                <img src="/images/scopeEbook.png" style={{width:"25%"}}/>
              </div>
            )
        )
    }

    const verificationSuccessStatusDisplay = () => {
        return (
            verificationStatus && (
              <div className="alert alert-success text-center" style={{marginBottom: "0px"}}>
                <h2>Thank you for Verifying your account! You may now proceed to login to scopebook.uk</h2>
                <img src="/images/scopeEbook.png" style={{width:"25%"}}/>
              </div>
            )
        )
    }

    return (
        <Base title="Delegate Verification">
            <div className="pt-5 mt-5">
            {verificationSuccessStatusDisplay()}
            {verificationFailureStatusDisplay()}
            </div>
        </Base>
    )
}

export default VerifyDelegateVerificationEmail;