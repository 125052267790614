import React, { useEffect, useState, useRef } from "react";

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from "primereact/multiselect";
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { createAccessory, deleteAccessory, deleteAccessoryPhoto, deleteBulkAccessory, getAllChapterAccessories, getAllChaptersList, getAllProcedures, getAllProceduresList, updateAccessory, updateBulkAccessories, uploadMultipleChapterAccessoriesPhotos } from "../helper/adminapicalls";
import { isAuthenticated } from "../../auth/helper";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGESPATH } from "../../backend";
import { Dropdown } from "primereact/dropdown";
import { PickList } from "primereact/picklist";
import { Chips } from 'primereact/chips';
import AdminBase from "../../core/admin/AdminBase";
const Accessories = () => {
// =========================START OF DECLARATION OF VALUES==================
const { user, token } = isAuthenticated();

let navigate = useNavigate();

const accessoryStatusOptions = ["Pending", "Released", "Hold"];

const showInBannerOptions = ["Yes","No"];

const [selectedFiles, setSelectedFiles] = useState([]);

const [proceduresList, setProceduresList] = useState([]);
const [selectedProcedures, setSelectedProcedures] = useState(null);

const [chaptersList, setChaptersList] = useState([]);
const [selectedChapters, setSelectedChapters] = useState(null);



const [accessory, setAccessory] = useState({
  name: "",
  companies: [
    { name: "", url: "" } // Default item structure
  ],
  options: [
    { optionname: "", optionitems: [] } // Default item structure
  ],
  images:[],
  links: [
    { name: "", url: "" } // Default item structure
  ],
  videolinks: [
    { name: "adsf", url: "" } // Default item structure
  ],
  keywords: [],
  status: "",
  description: "",
  procedures: [],
  uploadedBy: user._id,
  chapters: [],
  showinbanner: "",
  error: "",
  formData: new FormData(), // Assuming you want formData as an instance of FormData
});

const { _id, name, company, options, images,links, videolinks,keywords, status, description, procedures, showinbanner, error, formData } =
  accessory;

let emptyAccessory = {
  name: "",
  companies: [
    { name: "", url: "" } // Default item structure
  ],
  options: [
    { optionname: "", optionitems: [] } // Default item structure
  ],
  images:[],
  links: [
    { name: "", url: "" } // Default item structure
  ],
  videolinks: [
    { name: "", url: "" } // Default item structure
  ],
  keywords: [],
  status: "",
  description: "",
  procedures: [],
  uploadedBy: user._id,
  chapters: [],
  showinbanner: "",
  error: "",
  formData: new FormData(), // Assuming you want formData as an instance of FormData
};
const [file, setFile] = useState(null);

const [accessories, setAccessories] = useState([]);
const [accessoriesListVisble, setAccessoriesListVisble] = useState(true);

const [hoveredIndex, setHoveredIndex] = useState(null);


const [accessoryDialog, setAccessoryDialog] = useState(false);

const [deleteAccessoryDialog, setDeleteAccessoryDialog] = useState(false);

const [deleteAccessoriesDialog, setDeleteAccessoriesDialog] = useState(false);
const [reorderAccessoriesDialog, setReorderAccessoriesDialog] = useState(false);

const [selectedAccessories, setSelectedAccessories] = useState(null);

const [submitted, setSubmitted] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);

//picklist
const [source, setSource] = useState([]);
const [target, setTarget] = useState([]);
//end of picklist

const toast = useRef(null);
const dt = useRef(null);
const fileUploader = useRef(null);
// =========================END OF DECLARATION OF VALUES==================

// =========================START OF PRELOAD ==================

const preload = () => {
  getAllChapterAccessories(user._id, token)
    .then((data) => {
      if (data.error) {
        setAccessory({ ...accessory, error: data.error });
      } else {
        setSource(data);
        setAccessories(data);
        setAccessory({ ...accessory, formData: new FormData()});
      }
    })
    .catch((err) => console.log(err));

    getAllProceduresList(user._id, token)
    .then((data) => {
        setProceduresList(data);
    })
    .catch((err) => console.log(err));

    getAllChaptersList(user._id, token)
    .then((data) => {
        setChaptersList(data);
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
  preload();
}, []);

// =========================END OF PRELOAD==================

const onPickListChange = (event) => {
  setSource(event.source);
  setTarget(event.target);
  let counter=0;
  event.target.forEach(item => {
    counter++;
    item.orderId = counter;
  });
  updateBulkAccessories(user._id, token, event.target)
  .then((data) => {
    if (data.error) {
      setAccessory({
        ...accessory,
        error: data.error,
        formData: new FormData(),
      });
      toast.current.show({
        severity: "error",
        summary: "Accessory Not Updated",
        detail: data.error,
        life: 5000,
      });
    } else {
      preload();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Accessory Updated",
        life: 3000,
      });
    }
  })
  .catch((err) => console.log(err));
};

// ============START OF CUD OPERATIONS ==================
const saveAccessory = () => {
  setSubmitted(true);
  if (!_id) {
    createAccessory(user._id, token, accessory)
      .then((data) => {
        if (data.error) {
          setAccessory({
            ...accessory,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Accessory Not Created",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Accessory Created",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    updateAccessory(user._id, token, _id, accessory)
      .then((data) => {
        if (data.error) {
          setAccessory({
            ...accessory,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Accessory Not Updated",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Accessory Updated",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  }
  setSelectedProcedures(null)
  setAccessoryDialog(false);
  setAccessory(emptyAccessory);
};

const editAccessory = (accessory) => {
  setAccessory({ ...accessory });
  setSelectedProcedures(accessory.procedures)
  setAccessoryDialog(true);
};

const deleteAccessoryDB = () => {
  deleteAccessory(user._id, token, _id)
    .then((data) => {
      if (data.error) {
        setAccessory({
          ...accessory,
          error: data.error,
          formData: new FormData(),
        });
        toast.current.show({
          severity: "error",
          summary: "Accessory Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteAccessoryDialog(false);
        setAccessory(emptyAccessory);
      } else {
        preload();
        setAccessory(emptyAccessory);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Accessory Deleted",
          life: 3000,
        });
        setDeleteAccessoryDialog(false);
        setAccessory(emptyAccessory);
      }
    })
    .catch((err) => console.log(err));
};

const deleteSelectedAccessories = () => {
  let accessories = selectedAccessories;
  const accessoryIds = [];
  {
    Object.entries(accessories).map(([key, value]) =>
      accessoryIds.push(accessories[key]._id)
    );
  }
  deleteBulkAccessory(user._id, token, accessoryIds)
    .then((data) => {
      if (data.error) {
        toast.current.show({
          severity: "error",
          summary: "Accessories Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteAccessoriesDialog(false);
      } else {
        preload();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Accessories Deleted",
          life: 3000,
        });
        setDeleteAccessoriesDialog(false);
      }
    })
    .catch((err) => console.log(err));
};
// ============END OF CUD OPERATIONS ==================

// =========================START OF DIALOGS OPEN AND HIDE ==================
const openNewAccessory = () => {
  setAccessory(emptyAccessory);
  setSubmitted(false);
  setAccessoryDialog(true);
};

const hideDialogAccessory = () => {
  setSubmitted(false);
  setSelectedProcedures(null)
  setAccessoryDialog(false);
};

const hideDeleteAccessoryDialog = () => {
  setDeleteAccessoryDialog(false);
};

const hideDeleteAccessoriesDialog = () => {
  setDeleteAccessoriesDialog(false);
};

const hideReorderAccessoriesDialog = () => {
  setReorderAccessoriesDialog(false);
  setTarget([])
  setAccessoriesListVisble(true);
};

// =========================END OF DIALOGS OPEN AND HIDE ==================

// ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
const accessoryDialogFooter = (
  <React.Fragment>
    <Button
      label="Cancel"
      icon="pi pi-times"
      outlined
      onClick={hideDialogAccessory}
    />
    <Button label="Save" icon="pi pi-check" onClick={saveAccessory} />
  </React.Fragment>
);

const deleteAccessoryDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteAccessoryDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteAccessoryDB}
    />
  </React.Fragment>
);

const deleteAccessoriesDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteAccessoriesDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteSelectedAccessories}
    />
  </React.Fragment>
);

const confirmDeleteAccessory = (accessory) => {
  setAccessory(accessory);
  setDeleteAccessoryDialog(true);
};

const confirmDeleteSelectedAccessories = () => {
  setDeleteAccessoriesDialog(true);
};

const reorderAccessories = () => {
  setAccessoriesListVisble(false);
  setReorderAccessoriesDialog(true);
};

const actionBodyTemplateAccessory = (rowData) => {
  return (
    <React.Fragment>
      <Button
        icon="pi pi-pencil"
        rounded
        outlined
        className="mr-2"
        onClick={() => editAccessory(rowData)}
      />
      <Button
        icon="pi pi-trash"
        rounded
        outlined
        className="mr-2"
        severity="danger"
        onClick={() => confirmDeleteAccessory(rowData)}
      />
    </React.Fragment>
  );
};

// ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

// ============START OF EXPORT ==================
const cols = [
  { field: "_id", header: "ID" },
  { field: "name", header: "Accessory Name" },
 
];

const exportColumns = cols.map((col) => ({
  title: col.header,
  dataKey: col.field,
}));

const exportCSV = () => {
  dt.current.exportCSV();
};

const exportPdf = () => {
  import("jspdf").then((jsPDF) => {
    import("jspdf-autotable").then(() => {
      const doc = new jsPDF.default(0, 0);

      doc.autoTable(exportColumns, accessories);
      doc.save("accessories.pdf");
    });
  });
};

const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(accessories);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(excelBuffer, "accessories");
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};

// ============END OF EXPORT ==================

// ============START OF HANDLE CHANGE ==================
const handleChange = (name) => (event) => {
  const value = event.target.value;
  setAccessory({ ...accessory, [name]: value, orderId: accessories.length+1 });
};

const handleLinkedProcedureChange = (e) => {
  const selectedProcedureIds = e.value.map(procedure => procedure._id);

  // Update both the selectedProcedures state and the procedures array in the accessory state
  setSelectedProcedures(e.value);

  setAccessory(prevAccessory => ({
    ...prevAccessory,
    procedures: selectedProcedureIds,
  }));
};
const handleLinkedChapterChange = (e) => {
  const selectedChapterIds = e.value.map(chapter => chapter._id);

  // Update both the selectedChapters state and the chapters array in the accessory state
  setSelectedChapters(e.value);

  setAccessory(prevAccessory => ({
    ...prevAccessory,
    chapters: selectedChapterIds,
  }));
};

const handleFileChange = (e) => {
  setSelectedFiles([...selectedFiles, ...e.files]);
};

const handleFileUpload = (event) => {
  try {
    setSubmitted(true);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('photos', file, file.name);
    });

    uploadMultipleChapterAccessoriesPhotos(user._id, token, formData).then((res) => { 
     
      // Assuming res.data is an array of file objects
      setAccessory({
        ...accessory,
        images: res.data.map(file => file.filename)
      });
      fileUploader.current.clear();


      setSelectedFiles([]);
      setSubmitted(false);

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Accessory Photos Uploaded",
        life: 3000,
      });
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

const handleDeleteImage = (filename) => {
  deleteAccessoryPhoto(user._id, token, filename).then((res) => { 
    setAccessory(prevAccessory => {
      const updatedImages = prevAccessory.images.filter(image => image !== filename);
    
      return {
        ...prevAccessory,
        images: updatedImages
      };
    });
    // Assuming res.data is an array of file objects
  console.log(`Deleting image: ${filename}`);
})
};

// ================= COMPANY =======================
const handleChangeCompanyName = (index, value) => {
  setAccessory((prevAccessory) => {
    const newCompanies = [...prevAccessory.companies];
    newCompanies[index].name = value;
    return { ...prevAccessory, companies: newCompanies };
  });
};

const handleChangeCompanyURL = (index, value) => {
  setAccessory((prevAccessory) => {
    const newCompanies = [...prevAccessory.companies];
    newCompanies[index].url = value;
    return { ...prevAccessory, companies: newCompanies };
  });
};

const handleAddCompany = () => {
  setAccessory((prevAccessory) => {
    return { ...prevAccessory, companies: [...prevAccessory.companies, { name: "", link: "" }] };
  });
};

const handleRemoveCompany = (index) => {
  setAccessory((prevAccessory) => {
    const newCompanies = [...prevAccessory.companies];
    newCompanies.splice(index, 1);
    return { ...prevAccessory, companies: newCompanies };
  });
};

// ================= END OF COMPANY =======================

// ================= OPTIONS =======================
const handleChangeOptionName = (index, value) => {
  setAccessory((prevAccessory) => {
    const newOptions = [...prevAccessory.options];
    newOptions[index].optionname = value;
    return { ...prevAccessory, options: newOptions };
  });
};

const handleChangeOptionItems = (index, value) => {
  setAccessory((prevAccessory) => {
    const newOptions = [...prevAccessory.options];
    newOptions[index].optionitems = value;
    return { ...prevAccessory, options: newOptions };
  });
};

const handleAddOption = () => {
  setAccessory((prevAccessory) => {
    return { ...prevAccessory, options: [...prevAccessory.options, { optionname: "", optionitems: "" }] };
  });
};

const handleRemoveOption = (index) => {
  setAccessory((prevAccessory) => {
    const newOptions = [...prevAccessory.options];
    newOptions.splice(index, 1);
    return { ...prevAccessory, options: newOptions };
  });
};

// ================= END OF OPTIONS =======================

// ================= LINKS =======================
const handleChangeLinkName = (index, value) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.links];
    newLinks[index].name = value;
    return { ...prevAccessory, links: newLinks };
  });
};

const handleChangeLinkURL = (index, value) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.links];
    newLinks[index].url = value;
    return { ...prevAccessory, links: newLinks };
  });
};

const handleAddLink = () => {
  setAccessory((prevAccessory) => {
    return { ...prevAccessory, links: [...prevAccessory.links, { name: "", url: "" }] };
  });
};

const handleRemoveLink = (index) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.links];
    newLinks.splice(index, 1);
    return { ...prevAccessory, links: newLinks };
  });
};

// ================= END OF LINKS =======================

// ================= VIDEO LINKS =======================
const handleChangeVideoLinkName = (index, value) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.videolinks];
    newLinks[index].name = value;
    return { ...prevAccessory, videolinks: newLinks };
  });
};

const handleChangeVideoLinkURL = (index, value) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.videolinks];
    newLinks[index].url = value;
    return { ...prevAccessory, videolinks: newLinks };
    
  });
};

const handleAddVideoLink = () => {
  setAccessory((prevAccessory) => {
    return { ...prevAccessory, videolinks: [...prevAccessory.videolinks, { name: "", url: "" }] };
  });
};

const handleRemoveVideoLink = (index) => {
  setAccessory((prevAccessory) => {
    const newLinks = [...prevAccessory.videolinks];
    newLinks.splice(index, 1);
    return { ...prevAccessory, videolinks: newLinks };
  });
};

// ================= END OF VIDEO LINKS =======================

// ============END OF HANDLE CHANGE ==================

// ============START OF TOOLBARS ==================
const leftToolbarTemplateAccessory = () => {
  return (
    <div className="flex flex-wrap gap-2">
      <Button
        label="New"
        icon="pi pi-plus"
        severity="success"
        className="m-2"
        onClick={openNewAccessory}
      />
      <Button
        label="Delete"
        icon="pi pi-trash"
        severity="danger"
        className="m-2"
        onClick={confirmDeleteSelectedAccessories}
        disabled={!selectedAccessories || !selectedAccessories.length}
      />

      <Button
        label="Reorder"
        icon="pi pi-arrows-v"
        severity="info"
        className="m-2"
        onClick={reorderAccessories}
      />
    </div>
  );
};

const rightToolbarTemplateAccessory = () => {
  return (
    <div className="flex align-items-center justify-content-end gap-2 float-end">
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        onClick={exportPdf}
        data-pr-tooltip="PDF"
      />
    </div>
  );
};

// ============END OF TOOLBARS ==================

// ============START OF STATUS SEVERITY ==================

const getSeverity = (accessory) => {
  switch (accessory.status) {
    case "Pending":
      return "info";

    case "Released":
      return "success";

    case "Hold":
      return "danger";

    default:
      return null;
  }
};

const statusBodyTemplate = (rowData) => {
  return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
};
// ============END OF STATUS SEVERITY ==================

// ============START OF TEMPLATEs ==================

const accessoryBodyTemplate = (rowData) => {
  return (
    <div>
      <h5> {rowData.name}</h5>
    </div>
  );
};

const pickListItemTemplate = (accessory) => {
  return (
    <div className="d-flex flex-row p-2 align-items-center gap-3">
      <div className="flex-1 flex flex-column gap-2">
        <span className="font-bold">{accessory.name}</span>
       
      </div>
    </div>
  );
};

// ============START OF TEMPLATEs ==================

// ============START OF HEADER ==================

const header = (
  <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
    </span>
  </div>
);

// ============END OF HEADER ==================

return (
  <AdminBase title="Accessories">
    <div>
        <div className="col-12">
            <h2 className="text-center text-primary">ACCESSORIES</h2>
        </div>
      <Toast ref={toast} />
      {/*  START OF DATA TABLE  */}
      {accessoriesListVisble && (
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateAccessory}
            right={rightToolbarTemplateAccessory}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={accessories}
            selection={selectedAccessories}
            onSelectionChange={(e) => setSelectedAccessories(e.value)}
            dataKey="_id"
            paginator
            resizableColumns
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} accessories"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateAccessory}
              exportable={false}
              header="Actions"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              body={accessoryBodyTemplate}
              header="Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="description"
              header="description"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
      )}
      {/*  END OF DATA TABLE  */}

      {/*  START OF CREATE & EDIT FORM   */}
      <Dialog
        visible={accessoryDialog}
        style={{ width: "65rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Accessory Details"
        modal
        className="p-fluid"
        footer={accessoryDialogFooter}
        onHide={hideDialogAccessory}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={name}
            onChange={handleChange("name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !name,
            })}
          />
          {submitted && !name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>


<div className="field pt-2">
{accessory.images && (
        <div className="image-container">
          {accessory.images.map((filename, index) => (
            <div key={index} className="image-wrapper" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
              <img
                src={`${IMAGESPATH}/${filename}`}
                alt={filename}
                className="product-image block m-auto pb-3"
              />
              {hoveredIndex === index && (
                <button onClick={() => handleDeleteImage(filename)} className="delete-button">
                   <i className="fas fa-trash-alt"></i>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
<FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={2000000}
              multiple
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !selectedFiles && (
              <small className="p-error">Atleast one image is required.</small>
            )}
</div>


  <div className="field pt-2">
    <label htmlFor="Companies" className="font-bold">
      Companies
    </label>
    <div className="p-col">
      <table className="table p-table p-component">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accessory.companies.map((company, index) => (
            <tr key={index}>
              <td>
                <InputText
                  value={company.name}
                  onChange={(e) => handleChangeCompanyName(index, e.target.value)}
                  placeholder="Company Name"
                />
              </td>
              <td>
                <InputText
                  value={company.url}
                  onChange={(e) => handleChangeCompanyURL(index, e.target.value)}
                  placeholder="URL"
                />
              </td>
              <td>
                <Button label="Remove" onClick={() => handleRemoveCompany(index)} className="btn-danger" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Button label="Add Company" onClick={handleAddCompany} className="btn-success"/>
  </div>


  <div className="field pt-2">
    <label htmlFor="Options" className="font-bold">
      Options
    </label>
    <div className="p-col">
      <table className="table p-table p-component">
        <thead>
          <tr>
            <th>Option Name</th>
            <th>Option Items</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accessory.options.map((option, index) => (
            <tr key={index}>
              <td>
                <InputText
                  value={option.optionname}
                  onChange={(e) => handleChangeOptionName(index, e.target.value)}
                  placeholder="Option Name"
                />
              </td>
              <td>
              <Chips value={option.optionitems}  placeholder="Option Items" onChange={(e) => handleChangeOptionItems(index, e.target.value)} />
              </td>
              <td>
                <Button label="Remove" onClick={() => handleRemoveOption(index)} className="btn-danger" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Button label="Add Option" onClick={handleAddOption} className="btn-success"/>
  </div>        

  <div className="field pt-2">
    <label htmlFor="Links" className="font-bold">
      Links
    </label>
    <div className="p-col">
      <table className="table p-table p-component">
        <thead>
          <tr>
            <th>Link Name</th>
            <th>Link URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accessory.links.map((link, index) => (
            <tr key={index}>
              <td>
                <InputText
                  value={link.name}
                  onChange={(e) => handleChangeLinkName(index, e.target.value)}
                  placeholder="Link Name"
                />
              </td>
              <td>
              <InputText
                  value={link.url}
                  onChange={(e) => handleChangeLinkURL(index, e.target.value)}
                  placeholder="Link URL"
                />
              </td>
              <td>
                <Button label="Remove" onClick={() => handleRemoveLink(index)} className="btn-danger" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Button label="Add Link" onClick={handleAddLink} className="btn-success"/>
  </div>  

  <div className="field pt-2">
    <label htmlFor="VideoLinks" className="font-bold">
     Video Links
    </label>
    <div className="p-col">
      <table className="table p-table p-component">
        <thead>
          <tr>
            <th>Video Link Name</th>
            <th>Video Link URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accessory.videolinks.map((videolink, index) => (
            <tr key={index}>
              <td>
                <InputText
                  value={videolink.name}
                  onChange={(e) => handleChangeVideoLinkName(index, e.target.value)}
                  placeholder="Video Link Name"
                />
              </td>
              <td>
              <InputText
                  value={videolink.url}
                  onChange={(e) => handleChangeVideoLinkURL(index, e.target.value)}
                  placeholder="Video URL"
                />
              </td>
              <td>
                <Button label="Remove" onClick={() => handleRemoveVideoLink(index)} className="btn-danger" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Button label="Add Video Link" onClick={handleAddVideoLink} className="btn-success"/>
  </div>  

        
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={description}
            onChange={handleChange("description")}
            required
            rows={3}
            cols={20}
          />
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Status
          </label>

          <Dropdown
            value={status}
            onChange={handleChange("status")}
            options={accessoryStatusOptions}
            placeholder="Select a Status"
            showClear
            className={classNames({
              "p-invalid": submitted && !status,
            })}
          />
          {submitted && !status && (
            <small className="p-error">Status is required.</small>
          )}
        </div>
        

        <div className="field">
            <label htmlFor="name" className="font-bold">
              Linked Procedures
            </label>

            <MultiSelect
              value={selectedProcedures}
              onChange={(e) => handleLinkedProcedureChange(e)}
              options={proceduresList}
              optionLabel="name" // Assuming each object in accessoriesList has a 'name' property
              placeholder="Select Procedures"
              className="w-full md:w-20rem"
              display="chip"
              itemTemplate={(option) => option.name} // Use the 'name' property from the accessoriesList
            />
            {submitted && !selectedProcedures && (
              <small className="p-error">Procedure is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              Linked Chapters
            </label>

            <MultiSelect
              value={selectedChapters}
              onChange={(e) => handleLinkedChapterChange(e)}
              options={chaptersList}
              optionLabel="name" // Assuming each object in accessoriesList has a 'name' property
              placeholder="Select Chapters"
              className="w-full md:w-20rem"
              display="chip"
              itemTemplate={(option) => option.name} // Use the 'name' property from the accessoriesList
            />
            
          </div>
       
        <div className="field">
          <label htmlFor="keywords" className="font-bold">
            Keywords
          </label>

          <Chips value={keywords} onChange={handleChange("keywords")} />

          
          {submitted && !keywords && (
            <small className="p-error">Keywords is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="showinbanner" className="font-bold">
            Show In Banner
          </label>

          <Dropdown
            value={showinbanner}
            onChange={handleChange("showinbanner")}
            options={showInBannerOptions}
            placeholder="Select a Choice"
            showClear
            className={classNames({
              "p-invalid": submitted && !showinbanner,
            })}
          />
          {submitted && !showinbanner && (
            <small className="p-error">Type is required.</small>
          )}
        </div>
      </Dialog>
      {/*  END OF CREATE & EDIT FORM   */}
      {/*  START OF DELETE FORM   */}
      <Dialog
        visible={deleteAccessoryDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteAccessoryDialogFooter}
        onHide={hideDeleteAccessoryDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {accessory && (
            <span>
              Are you sure you want to delete <b>{name}</b> accessory?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF DELETE FORM   */}

      {/*  START OF MULTIPLE DELETE FORM   */}
      <Dialog
        visible={deleteAccessoriesDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteAccessoriesDialogFooter}
        onHide={hideDeleteAccessoriesDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {accessory && (
            <span>
              Are you sure you want to delete the selected accessories?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF MULTIPLE DELETE FORM   */}

      {/*  START OF REORDER CHAPTER FORM   */}

      {reorderAccessoriesDialog && (
        <div className="card p-4">
          <div className="row m-3">
            <div className="col-6 text-left">
            <h2 className="text-primary">Reorder Accessories List</h2>
            </div>
            <div className="col-6 text-right">
              
              <Button
                label="Close Reorder"
                className=""
                onClick={hideReorderAccessoriesDialog}
              />
            </div>
          </div>
          <PickList
            source={source}
            target={target}
            onChange={onPickListChange}
            itemTemplate={pickListItemTemplate}
            filter
            filterBy="name"
            breakpoint="1400px"
            sourceHeader="Available"
            targetHeader="Selected"
            showSourceControls={false}
            sourceStyle={{ height: "24rem" }}
            targetStyle={{ height: "24rem" }}
            sourceFilterPlaceholder="Search by name"
            targetFilterPlaceholder="Search by name"
          />
        </div>
      )}

      {/*  END OF REORDER CHAPTER FORM   */}
    </div>
  </AdminBase>
);
}

export default Accessories