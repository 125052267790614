import React, { useEffect, useRef, useState } from 'react'
import { FileUpload } from 'primereact/fileupload';
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminChapterTitlesBase from '../../core/admin/AdminChapterTitlesBase'
import { useParams } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper';
import { deleteBulkChapterImages, deleteChapterPhoto, getChapterImages, updateChapterImage, uploadMultipleChapterPhotos } from '../helper/adminapicalls';
import { Chips } from "primereact/chips";
import { Tag } from "primereact/tag";
import { IMAGESPATH } from '../../backend';
const ChapterImages = () => {
  const { user, token } = isAuthenticated();
  const chapterParams = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false)


  const [chapterImage, setChapterImage] = useState({
    _id: "",
    name: "",
    imageName: "",
    path: "",
    keywords: [],
    error: "",
    formData: "",
  });

  const {
    _id,
    name,
    imageName,
    path,
    keywords,
    error,
    formData,
  } = chapterImage;

  let emptyChapterImage = {
    _id: "",
    name: "",
    imageName: "",
    path: "",
    keywords: [],
    error: "",
    formData: "",
  };

  const [chapterImages, setChapterImages] = useState(null)
  const [ChapterImageDialog, setChapterImageDialog] = useState(false);

  const [deleteChapterImageDialog, setDeleteChapterImageDialog] = useState(false);

  const [deleteChapterImagesDialog, setDeleteChapterImagesDialog] = useState(false);
  const [selectedChapterImages, setSelectedChapterImages] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [photo, setPhoto] = useState()
  const fileUploader = useRef(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...e.files]);
  };

  const preload = () => {
    getChapterImages(user._id, token, chapterParams.chapterId)
      .then((data) => {
          setChapterImages(data);
      })
      .catch((err) => console.log(err));
    }

  useEffect(() => {
    preload()
  },[])

  function copyToClipboard(text) {
    if(navigator.clipboard) {
        navigator.clipboard.writeText(text);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied URL",
          life: 2000,
        });
    }
}
  
  const handleFileUpload = (event) => {
    try {
      setSubmitted(true);
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('photos', file, file.name);
      });
      uploadMultipleChapterPhotos(user._id, chapterParams.chapterId, token, formData).then((res) => { 
        fileUploader.current.clear();
        setSelectedFiles([])
        setSubmitted(false)
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Photos Uploaded",
          life: 3000,
        });
        preload()
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setChapterImage({ ...chapterImage, [name]: value});
  };

  const saveChapterImage = () => {
    setSubmitted(true);
    setChapterImage({ ...chapterImage});
    console.log(chapterImage)
    updateChapterImage(user._id, token, _id, chapterImage)
        .then((data) => {
          if (data.error) {
            setChapterImage({
              ...chapterImage,
              error: data.error,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "ChapterImage Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "ChapterImage Updated",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
    setChapterImageDialog(false);
    setChapterImage(emptyChapterImage);
  };

  const deleteChapterImage = () => {
    deleteChapterPhoto(user._id, token, chapterImage._id)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Chapter Image Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChapterImageDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Chapter Image Deleted",
            life: 3000,
          });
          setDeleteChapterImageDialog(false);
        }
      })
      .catch((err) => console.log(err));
  }
  
  const deleteSelectedChapterImages = () => {
    let chapterImages = selectedChapterImages;
    const chapterImageIds = [];
    {
      Object.entries(chapterImages).map(([key, value]) =>
        chapterImageIds.push(chapterImages[key]._id)
      );
    }
    deleteBulkChapterImages(user._id, token, chapterImageIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Chapter Images Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChapterImagesDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Chapter Images Deleted",
            life: 3000,
          });
          setDeleteChapterImagesDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const editChapterImage = (chapterImage) => {
    setChapterImage({ ...chapterImage });
    setChapterImageDialog(true);
  };

   // ============START OF TOOLBARS ==================
   const leftToolbarTemplateChapter = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {/* <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          className="m-2"
          onClick={openNewChapter}
        /> */}
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          className="m-2"
          onClick={confirmDeleteSelectedChapterImages}
          disabled={!selectedChapterImages || !selectedChapterImages.length}
        />
      </div>
    );
  };

 const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Chapter Images</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  )
   // =========================START OF DIALOGS OPEN AND HIDE ==================
  

  const hideChapterImageDialog = () => {
    setSubmitted(false);
    setChapterImageDialog(false);
  };

  const hideDeleteChapterImageDialog = () => {
    setDeleteChapterImageDialog(false);
  };

  const hideDeleteChapterImagesDialog = () => {
    setDeleteChapterImagesDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  
  const chapterImageDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideChapterImageDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveChapterImage}  />
    </React.Fragment>
  );

  const deleteChapterImageDialogFooter = (rowData) => {
    return (<React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChapterImageDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => deleteChapterImage(rowData)}
      />
    </React.Fragment>
  )
  };

  const deleteChapterImagesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChapterImagesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedChapterImages}
      />
    </React.Fragment>
  );

  const confirmDeleteChapterImage = (ChapterImage) => {
    setChapterImage(ChapterImage);
    setDeleteChapterImageDialog(true);
  };

  const confirmDeleteSelectedChapterImages = (chapterImages) => {
    setDeleteChapterImagesDialog(true);
  };

  const actionBodyTemplateChapterImage = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-copy"
          rounded
          outlined
          severity="success"
          className="mr-2"
          onClick={() =>  copyToClipboard(`%i${IMAGESPATH}/${rowData.imageName}%i`)}
        />
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          severity="info"
          className="mr-2"
          onClick={() => editChapterImage(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteChapterImage(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

 

  // ============START OF STATUS SEVERITY ==================
 
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`${IMAGESPATH}/${rowData.imageName}`}
        alt={rowData.photo}
        width="64px"
        className="shadow-4"
      />
    );
  };


  const imageURLTemplate = (rowData) => {
    return (
      <div>
      <p>{`${IMAGESPATH}/${rowData.imageName}`} </p> 
      </div>
    );
  };

  // ============END OF STATUS SEVERITY ==================



  return (
    <AdminChapterTitlesBase>
        <div className="card">
        <Toast ref={toast} />
        <FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={2000000}
              multiple
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !selectedFiles && (
              <small className="p-error">Atleast one image is required.</small>
            )}
        </div>

        {/*  START OF DATA TABLE  */}
        <div className="card">
          {chapterImages && (
            <div class="card">
            <Toolbar
            className="mb-4"
            left={leftToolbarTemplateChapter}
          ></Toolbar>
            <DataTable
            ref={dt}
            value={chapterImages}
            selection={selectedChapterImages}
            onSelectionChange={(e) => setSelectedChapterImages(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} images"
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateChapterImage}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
             <Column
              body={imageBodyTemplate}
              header="Image"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              header="Path"
              body={imageURLTemplate}
              style={{ minWidth: "12rem" }}
            ></Column>
             <Column
              header="Keywords"
              field="keywords"
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable> 
          </div>
          )}
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={ChapterImageDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Chapter Details"
          modal
          className="p-fluid"
          footer={chapterImageDialogFooter}
          onHide={hideChapterImageDialog}
        >
           {imageName && (
              <img
                src={`${IMAGESPATH}/${imageName}`}
                alt={photo}
                style={{ height: 200, width: 200, margin: "auto" }}
                className="product-image block m-auto pb-3"
              />
            )}
          <FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={2000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !selectedFiles && (
              <small className="p-error">Atleast one image is required.</small>
            )}
          <span className="p-float-label">
            <Chips id="keywords" value={keywords} onChange={handleChange("keywords")} />
          <label htmlFor="keywords">Keywords</label>
          </span>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}

        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteChapterImageDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChapterImageDialogFooter}
          onHide={hideDeleteChapterImageDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapterImage && (
              <span>
                Are you sure you want to delete <b>{imageName}</b> from 
                ?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteChapterImagesDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChapterImagesDialogFooter}
          onHide={hideDeleteChapterImagesDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapterImages && (
              <span>
                Are you sure you want to delete the selected Chapter Images?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
    </AdminChapterTitlesBase>
  )
}

export default ChapterImages
