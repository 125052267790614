import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";

import { useNavigate, useParams } from "react-router-dom";
import AdminChapterTitlesBase from "../../core/admin/AdminChapterTitlesBase";
import { isAuthenticated } from "../../auth/helper";
import {
  createChapterTitle,
  deleteBulkChapterTitle,
  deleteChapterTitle,
  getAllChapterTitles,
  updateChapterTitle,
} from "../helper/adminapicalls";
import { Editor } from "primereact/editor";
import axios from 'axios';

const ChapterManageTitles = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();
  
  const chapterParams = useParams();
  let navigate = useNavigate();
  const [chapterTitles, setChapterTitles] = useState();
  const [chapterTitle, setChapterTitle] = useState({
    _id: "",
    heading: "",
    subheading: "",
    content: " ",
    status: "",
    comments: "",
    chapterid: chapterParams.chapterId,
    readingTime: 0
  });
  let emptyChapterTitle = {
    heading: "",
    subheading: "",
    content: " ",
    status: "",
    comments: "",
    chapterid: chapterParams.chapterId,
    readingTime: 0
  };
  const wordsPerMinute = 125; // Adjust this based on your estimation


  const { _id, heading, subheading, content, status, comments, chapterid, readingTime } =
    chapterTitle;

  // To manipulate the Create / Edit Title
  const [editChapterTitleFlag, setEditChapterTitleFlag] = useState(false);

  // Dialog Boxes Flags
  const [chapterTitleDialog, setChapterTitleDialog] = useState(false);
  const [chapterTitleCardDialog, setChapterTitleCardDialog] = useState(true);
  const [deleteChapterTitleDialog, setDeleteChapterTitleDialog] =
    useState(false);
  const [deleteChapterTitlesDialog, setDeleteChapterTitlesDialog] =
    useState(false);
  const [selectedChapterTitles, setSelectedChapterTitles] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  // Editors etc
  const [editorContent, setEditorContent] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);

 
  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllChapterTitles(user._id, token, chapterParams.chapterId)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          setChapterTitle({ ...chapterTitle, error: data.error });
        } else {
          setChapterTitles(data);
          setChapterTitle({ ...chapterTitle, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  // =========================END OF PRELOAD==================

  // ============START OF CUD OPERATIONS ==================
  const saveChapterTitle = () => {
    console.log(chapterTitle)
    setSubmitted(true);
    setChapterTitle({ ...chapterTitle });
    if (!_id) {
      createChapterTitle(user._id, token, chapterid, chapterTitle)
        .then((data) => {
          if (data.error) {
            setChapterTitle({
              ...chapterTitle,
            });
            toast.current.show({
              severity: "error",
              summary: "Title Not Created",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Title Created",
              life: 3000,
            });
            openManageChapterTitle();
          }
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Title Not Created",
            detail: "Something Went Wrong",
            life: 5000,
          });
        });
    } else {
      updateChapterTitle(user._id, token, chapterid, _id, chapterTitle)
        .then((data) => {
          if (data.error) {
            setChapterTitle({ chapterTitle });
            toast.current.show({
              severity: "error",
              summary: "Chapter Title Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Chapter Title Updated",
              life: 3000,
            });
            openManageChapterTitle();
          }
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Title Not Created",
            detail: "Something Went Wrong",
            life: 5000,
          });
        });
    }
  };

  const deleteChapterTitleDB = () => {
    deleteChapterTitle(user._id, token, chapterid, _id)
      .then((data) => {
        if (data.error) {
          setChapterTitle({
            ...chapterTitle,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Chapter Title Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChapterTitleDialog(false);
          setChapterTitle(emptyChapterTitle);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "ChapterTitle Deleted",
            life: 3000,
          });
          setDeleteChapterTitleDialog(false);
          setChapterTitle(emptyChapterTitle);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSelectedChapterTitles = () => {
    let chapterTitles = selectedChapterTitles;
    const chapterTitleIds = [];
    {
      Object.entries(chapterTitles).map(([key, value]) =>
        chapterTitleIds.push(chapterTitles[key]._id)
      );
    }
    deleteBulkChapterTitle(user._id, token, chapterTitleIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "ChapterTitles Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChapterTitlesDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "ChapterTitles Deleted",
            life: 3000,
          });
          setDeleteChapterTitlesDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============END OF CUD OPERATIONS ==================

  // =========================START OF CREATE CHAPTER TITLE UI ==================

  const leftPanel = () => {
    return (
      <div className="col-md-12 p-3">
        <h4 className="d-flex aligns-items-center">
          {" "}
          <Button
            type="button"
            className="m-1"
            style={{ width: 25, height: 25 }}
            icon="pi pi-arrow-left"
            severity="success"
            rounded
            onClick={openManageChapterTitle}
          />
          {editChapterTitleFlag == true ? "Edit " : "Create "}
          Title
          {/*TODO  */}
        </h4>
        <div className="field mt-4">
          Heading: 
        <Editor
            value={heading}
            placeholder="Heading"
            onTextChange={handleChange("heading")}
            style={{ height: "100px" }}
          />
        </div>
        <div className="field mt-4">
          Sub Heading: 
        <Editor
            value={subheading}
            placeholder="Sub Heading"
            onTextChange={handleChange("subheading")}
            style={{ height: "100px" }}
          />
        </div>
        <div className="field mt-4">
        Content: 
          <Editor
            value={content}
            onTextChange={handleChange("content")}
            style={{ height: "320px" }}
          />
        </div>

        <div className="field mt-4">
          <Button
            label="Save"
            className="w-100"
            icon="pi pi-check"
            onClick={saveChapterTitle}
          />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return <span className="ql-formats"></span>;
  };

  const headertoolbar = renderHeader();

  const replaceImage = (contentEditor) => {
    let haystack = contentEditor;
    let needle = "%i";
    let pos = 0; // Position Ref
    let imageIndexes = []; // Final output of all index's.

    let hayStackLower = haystack!="" ? haystack.toLowerCase() : "" ;
    // Loop to check all occurrences
    while (hayStackLower.indexOf(needle, pos) != -1) {
      imageIndexes.push(hayStackLower.indexOf(needle, pos));
      pos = hayStackLower.indexOf(needle, pos) + 1;
    }
    const finalImageIndexes = [];
    while(imageIndexes.length) finalImageIndexes.push(imageIndexes.splice(0,2));
    let finalReplacedContentEditor = contentEditor
    finalImageIndexes.forEach(item => {
      let imageurl=contentEditor.substring(  item[0] + 2,item[1])
      finalReplacedContentEditor = finalReplacedContentEditor.replace(/%i(.*?)%i/,`<img src="${imageurl}">`)
    });
    return finalReplacedContentEditor
  };

  const replaceVideo = (contentEditor) => {
    let haystack = contentEditor;
    let needle = "%v";
    let pos = 0; // Position Ref
    let imageIndexes = []; // Final output of all index's.

    let hayStackLower = haystack!="" ? haystack.toLowerCase() : "" ;
    // Loop to check all occurrences
    while (hayStackLower.indexOf(needle, pos) != -1) {
      imageIndexes.push(hayStackLower.indexOf(needle, pos));
      pos = hayStackLower.indexOf(needle, pos) + 1;
    }
    const finalImageIndexes = [];
    while(imageIndexes.length) finalImageIndexes.push(imageIndexes.splice(0,2));
    let finalReplacedContentEditor = contentEditor
    finalImageIndexes.forEach(item => {
      let videourl=contentEditor.substring(  item[0] + 2,item[1])
      finalReplacedContentEditor = finalReplacedContentEditor.replace(/%v(.*?)%v/,`<iframe src="${videourl}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`)
    });
    return finalReplacedContentEditor
  };

// THEVA ILLATHA AANI   4.11.2023 MS
// { 
// // async function imageToBase64(imageURL) {
// //   const response = await fetch(imageURL);
// //   const arrayBuffer = await response.arrayBuffer();
// //   const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
// //   return `<img src="data:image/png;base64,${base64String}" />`;
// // }
// // const replaceImage = (givenWord) => {
// //   let haystack = givenWord;
// //   let needle = "%i";
// //   let pos = 0; // Position Ref
// //   let result = []; // Final output of all index's.
// //   let hayStackLower = haystack.toLowerCase();
 
// //   // Loop to check all occurrences
// //   while (hayStackLower.indexOf(needle, pos) != -1) {
// //     result.push(hayStackLower.indexOf(needle, pos));
// //     pos = hayStackLower.indexOf(needle, pos) + 1;
// //   }

 

// //   const newArr = [];
// //   while(result.length) newArr.push(result.splice(0,2));
// //   console.log(newArr);

 
// //   let finalReplacedWord = givenWord
// // let i = 0;
// //   newArr.forEach(item => {
// //     console.log(i++)
// //     finalReplacedWord = finalReplacedWord.replace(/%i(.*?)%i/,'<img src='${givenWord.substring( item[0] + 2,item[1])}'/>')
// //   });

// //   console.log(finalReplacedWord)
// //   return (
// //     <div>
// //       {newArr.map((item) => {
// //          console.log(givenWord.substring(
// //           item[0] + 2,
// //           item[1]
// //         ))
// //           return (
// //               <img
// //                 src={givenWord.substring(
// //                   item[0] + 2,
// //                   item[1]
// //                 )}
// //               />
// //           );
// //       })}
// //     </div>
// //   );
// // };

// // const replaceImage = async (givenWord) => {
// //   console.log(givenWord)
// //   let haystack = givenWord;
// //   let needle = "%i";
// //   let pos = 0; // Position Ref
// //   let result = []; // Final output of all index's.
// //   let hayStackLower = haystack.toLowerCase();
 
// //   // Loop to check all occurrences
// //   while (hayStackLower.indexOf(needle, pos) != -1) {
// //       result.push(hayStackLower.indexOf(needle, pos));
// //       pos = hayStackLower.indexOf(needle, pos) + 1;
// //   }

// //   const newArr = [];
// //   while(result.length) newArr.push(result.splice(0,2));
 
// //   let finalReplacedWord = givenWord;

// //   for (const item of newArr) {
// //       const base64Image = await imageToBase64(givenWord.substring(item[0] + 2, item[1]));
// //       finalReplacedWord = finalReplacedWord.replace(/%i(.*?)%i/, base64Image);
// //   }

// //   console.log(finalReplacedWord);
// //   return finalReplacedWord
// // };
// }
// END OF THEVA ILLATHA AANI 4.11.2023 MS

  const rightPanel = () => {
    return (
      <div className="col-md-12 p-3">
        <Editor
          value={heading}
          readOnly={true}
          showHeader={false}
          style={{ height: "50px",border:0 }}
        />
        <Editor
          value={subheading}
          readOnly={true}
          showHeader={false}
          style={{ height: "50px",border:0 }}
        />
        
      <Editor
          value={replaceVideo(replaceImage(content))}
          readOnly={true}
          showHeader={false}
          style={{ height: "320px", border:0 }}
        />
      </div>
    );
  };

  // =========================END OF CREATE CHAPTER TITLE UI ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================

  const openNewChapterTitle = () => {
    setEditChapterTitleFlag(false);
    setChapterTitleCardDialog(false);
    setChapterTitle(emptyChapterTitle);
    setSubmitted(false);
    setChapterTitleDialog(true);
  };

  const openManageChapterTitle = () => {
    setEditChapterTitleFlag(false);
    setChapterTitleDialog(false);
    setChapterTitleCardDialog(true);
    setSubmitted(false);
  };

  const editChapterTitle = (chapterTitle) => {
    setEditChapterTitleFlag(true);
    setChapterTitle({ ...chapterTitle });
    setChapterTitleCardDialog(false);
    setChapterTitleDialog(true);
  };

  const hideDialogChapterTitle = () => {
    setSubmitted(false);
    setChapterTitleDialog(false);
  };

  const hideDeleteChapterTitleDialog = () => {
    setDeleteChapterTitleDialog(false);
  };

  const hideDeleteChapterTitlesDialog = () => {
    setDeleteChapterTitlesDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const chapterTitleDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogChapterTitle}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveChapterTitle} />
    </React.Fragment>
  );

  const deleteChapterTitleDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChapterTitleDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteChapterTitleDB}
      />
    </React.Fragment>
  );

  const deleteChapterTitlesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChapterTitlesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedChapterTitles}
      />
    </React.Fragment>
  );

  const confirmDeleteChapterTitle = (chapterTitle) => {
    setChapterTitle(chapterTitle);
    setDeleteChapterTitleDialog(true);
  };

  const confirmDeleteSelectedChapterTitles = () => {
    setDeleteChapterTitlesDialog(true);
  };

  const actionBodyTemplateChapterTitle = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editChapterTitle(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteChapterTitle(rowData)}
        />
      </React.Fragment>
    );
  };

  const stripTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };
  const headerTemplateChapterTitle = (rowData) => {
    return (
      stripTags(rowData.heading)
    );
  };

  const subheaderTemplateChapterTitle = (rowData) => {
    return (
      stripTags(rowData.subheading)
    );
    }
  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

  // ============START OF EXPORT ==================
  const cols = [
    { field: "_id", header: "ID" },
    { field: "heading", header: "Heading" },
    { field: "subheading", header: "Sub Heading" },
    { field: "order", header: "Order" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, chapterTitles);
        doc.save("chapterTitles.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(chapterTitles);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "chapterTitles");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
   // const value = name === "content" ? event.htmlValue : event.target.value;
    const value = event.htmlValue;
    const wordCount = countWords(content);
    const time = calculateReadingTime(wordCount, wordsPerMinute);
    setChapterTitle((prevChapterTitle) => ({
      ...prevChapterTitle,
      [name]: value,
      readingTime: time
    }));
  };

  function countWords(text) {
    // Implement your word counting logic here
    // For a simple example, you can split the text by spaces
    return text.split(/\s+/).length;
  }

  function calculateReadingTime(wordCount, wordsPerMinute) {
    const minutes = wordCount / wordsPerMinute;
    return Math.ceil(minutes);
  }

  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateChapterTitle = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewChapterTitle}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedChapterTitles}
          disabled={!selectedChapterTitles || !selectedChapterTitles.length}
        />
      </div>
    );
  };

  const rightToolbarTemplateChapterTitle = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help m-2"
          onClick={exportCSV}
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0"> Manage Chapter Titles</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  useEffect(() => {
    preload();
  }, []);

  // ============END OF HEADER ==================

  return (
    <AdminChapterTitlesBase title="ChapterTitles">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        {chapterTitleCardDialog && (
          <div className="card">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplateChapterTitle}
              right={rightToolbarTemplateChapterTitle}
            ></Toolbar>

            <DataTable
              ref={dt}
              value={chapterTitles}
              selection={selectedChapterTitles}
              onSelectionChange={(e) => setSelectedChapterTitles(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} promo codes"
              globalFilter={globalFilter}
              header={header}
            >
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column
                body={actionBodyTemplateChapterTitle}
                exportable={false}
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                body={headerTemplateChapterTitle}
                header="Heading"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                body={subheaderTemplateChapterTitle}
                header="subheading"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="orderId"
                header="Order"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>
        )}
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        {chapterTitleDialog && (
          <div className="row">
            <div className="col-6">{leftPanel()}</div>
            <div className="col-6 text-center">{rightPanel()}</div>
          </div>
        )}

        <Dialog
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Chapter Title Manage"
          modal
          className="p-fluid"
          footer={chapterTitleDialogFooter}
          onHide={hideDialogChapterTitle}
        >
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Heading
            </label>
            <InputText
              id="heading"
              value={heading}
              onChange={handleChange("heading")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !heading,
              })}
            />
            {submitted && !heading && (
              <small className="p-error">Heading is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="subheading" className="font-bold">
              Sub Heading
            </label>
            <InputText
              id="subheading"
              value={subheading}
              onChange={handleChange("subheading")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !subheading,
              })}
            />
            {submitted && !subheading && (
              <small className="p-error">Sub Heading is required.</small>
            )}
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteChapterTitleDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChapterTitleDialogFooter}
          onHide={hideDeleteChapterTitleDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapterTitle && (
              <span>
                Are you sure you want to delete <b>{heading}</b>?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteChapterTitlesDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChapterTitlesDialogFooter}
          onHide={hideDeleteChapterTitlesDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapterTitle && (
              <span>
                Are you sure you want to delete the selected ChapterTitles?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminChapterTitlesBase>
  );
};
export default ChapterManageTitles;
