import React from "react";
import Routers from "./routes";
import { createRoot } from "react-dom/client";
// Import necessary Font Awesome modules
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/helper/msal/authConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";


const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);

// Add Font Awesome icon library to the project
library.add(fas);

// Function to handle right-click event
function handleContextMenu(event) {
  event.preventDefault();
}

// Add the CSS class to disable right-click
container.classList.add('no-right-click');

// Attach the event listener to disable right-click globally
document.addEventListener('contextmenu', handleContextMenu);

root.render(
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId='631972828978-9kmg54npdmqc897rqhrb5jpnur30128k.apps.googleusercontent.com'>
      <Routers />
    </GoogleOAuthProvider>
  </MsalProvider>
);

// Cleanup event listener on module hot replacement
if (module.hot) {
  module.hot.dispose(() => {
    document.removeEventListener('contextmenu', handleContextMenu);
  });
}
