import React, { useEffect, useState } from "react";
import Base from "../core/static/Base";
import { register } from "../auth/helper";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendDelegateVerificationEmail } from "../api/MailerAPI";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./helper/msal/authConfig";

const Register = () => {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    retypepassword: "",
    error: "",
    success: false,
  });

  //verification code to be generated and inserted to the database and sent to verification email 
  const [verificationCode, setVerificationCode] = useState("")
  const [emailStatus, setEmailStatus] = useState(false)
  const [emailStatusMessage, setEmailStatusMessage] = useState("")
  const {
    firstname,
    lastname,
    email,
    password,
    retypepassword,
    error,
    success,
  } = values;

   // WINDOWS AUTHENTICATION

   const { instance } = useMsal();

   const handleMSALLogin = () => {
     instance.loginPopup(loginRequest).catch((e) => {
       console.log(e);
     });
   }
 
   // END OF WINDOWS AUTHENTICATION

  //==============================Helper Components ==================================================

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  //==============================End of Helper Components ==================================================  

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false });
    firstname.toLowerCase();
    lastname.toLowerCase();
    email.toLowerCase();
    register({ firstname, lastname, email, password, retypepassword })
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, success: false });
        } else {
          sendDelegateVerificationEmail(email).then(
            (data) => {
              if(data.error){
                setEmailStatusMessage(data.error)
                setEmailStatus(false)
              }else{
                setEmailStatusMessage(data.message)
                setEmailStatus(true)
              }
            }
          ).catch((error)=>{
            setEmailStatusMessage(error)
            setEmailStatus(false)
          })
          setValues({
            ...values,
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            retypepassword: "",
            error: "",
            success: true,
          });
        }
      })
      .catch((error) => {
        console.log("Error in registering onsubmit in frontend");
        console.log(error);
      });
  };

  const emailMessage = () => {
    return (
      emailStatus && (
        <div>{emailStatusMessage}</div>
      )
    )
  }

  const successMessage = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div
            className="alert alert-success"
            style={{ display: success ? "" : "none" }}
          >
            New account was created successfully. 
             please{" "}
            <NavLink to="/login">Login Here</NavLink> after verifying your Email Account.

            <br />
            <b>{emailMessage()}</b>
          </div>
        </div>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
          >
            {error}
          </div>
        </div>
      </div>
    );
  };

  const registerForm = () => {
    return (
      <div className="row">
        <div class="contact-us section" id="contact">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-us-content">
                  <div class="row">
                    <div class="col-lg-4" style={{ margin: "auto" }}>
                      <img src="/images/scopeEbook.png" />
                    </div>
                    <div class="col-lg-8">
                      <form id="contact-form" action="" method="post">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="section-heading">
                              <h2>
                                <em>Register</em>
                              </h2>
                              {successMessage()}
                              {errorMessage()}
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="text"
                                name="firstname"
                                id="firstname"
                                placeholder="Enter First Name"
                                value={firstname}
                                onChange={handleChange("firstname")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                placeholder="Enter Last Name"
                                value={lastname}
                                onChange={handleChange("lastname")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={handleChange("email")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={handleChange("password")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="password"
                                name="retypepassword"
                                id="retypepassword"
                                placeholder="Retype Password"
                                value={retypepassword}
                                onChange={handleChange("retypepassword")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <button
                                type="submit"
                                id="form-submit"
                                onClick={onSubmit}
                                class="orange-button"
                              >
                                Register
                              </button>
                            </fieldset>
                          </div>

                          {/* <div className="col-md-6">
                            <button className="SSOButton MSButton" onClick={() => handleMSALLogin()}>
                            <i class="fa-brands fa-windows"></i>  Sign in with Microsoft
      </button>
      </div>
      <div className="col-md-6">
      <button className="SSOButton GButton" onClick={() => handleMSALLogin()}>
                            <i class="fa-brands fa-google"></i>  Sign in with Google
      </button>
      </div> */}
                        </div>
                      </form>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Scope Ebook - Register</title>
      </Helmet>
      <Base title="Register" description="Scope Ebook">
        {registerForm()}
      </Base>
    </div>
  );
};

export default Register;
