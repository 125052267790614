import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../core/admin/AdminBase";
import { isAuthenticated } from "../auth/helper";
import { Tag } from "primereact/tag";

import { FileUpload } from "primereact/fileupload";

import { IMAGESPATH } from "../backend";
import { createPricing, deleteBulkPricing, deletePricing, getAllPricings, updatePricing } from "./helper/adminapicalls";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";

const ManagePricing = () => {

  let navigate = useNavigate();
 
  const viewPricingPage = () => {
    window.open("/pricing","_blank")
  }


  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  
  const codeActivated = ["Yes","No"];

  const [pricing, setPricing] = useState({
    _id: "",
    title: "",
    description: "",
    monthlyprice: "",
    yearlyprice: "",
    listings: "",
    activated: ""
  });

  const {
    _id,
    title,
    description,
    monthlyprice,
    yearlyprice,
    listings,
    activated,
    formData,
  } = pricing;

  let emptyPricing = {
    title: "",
    description: "",
    monthlyprice: "",
    yearlyprice: "",
    listings: "",
    activated: "",
    formData: "",
  };

  const [pricings, setPricings] = useState(null);

  const [pricingDialog, setPricingDialog] = useState(false);

  const [deletePricingDialog, setDeletePricingDialog] = useState(false);

  const [deletePricingsDialog, setDeletePricingsDialog] = useState(false);

  const [selectedPricings, setSelectedPricings] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const fileUploader = useRef(null);

  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllPricings(user._id, token)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Fetching Pricings List Failed",
            detail: data.error,
            life: 5000,
          });
        } else {
          setPricings(data);
          setPricing({ ...pricing, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================



  // ============START OF CUD OPERATIONS ==================
  const savePricing = () => {
    setSubmitted(true);
    setPricing({ ...pricing });
    if (!_id) {
      createPricing(user._id, token, pricing)
        .then((data) => {
          if (data.error) {
            setPricing({
              ...pricing,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Pricing Not Created",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Pricing Created",
              life: 3000,
            });
            setPricingDialog(false);
            setPricing(emptyPricing);
          }
        })
        .catch((err) => console.log(err));
    } else {
      updatePricing(user._id, token, _id, pricing)
        .then((data) => {
          if (data.error) {
            setPricing({
              ...pricing,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Pricing Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Pricing Updated",
              life: 3000,
            });
            setPricingDialog(false);
            setPricing(emptyPricing);
          }
        })
        .catch((err) => console.log(err));
    }

  };

  const editPricing = (pricing) => {
    setPricing({ ...pricing });
    setPricingDialog(true);
  };

  const deletePricingDB = () => {
    deletePricing(user._id, token, _id)
      .then((data) => {
        if (data.error) {
          setPricing({
            ...pricing,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Pricing Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeletePricingDialog(false);
          setPricing(emptyPricing);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Pricing Deleted",
            life: 3000,
          });
          setDeletePricingDialog(false);
          setPricing(emptyPricing);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSelectedPricings = () => {
    let pricings = selectedPricings;
    const pricingIds = [];
    {
      Object.entries(pricings).map(([key, value]) =>
        pricingIds.push(pricings[key]._id)
      );
    }
    deleteBulkPricing(user._id, token, pricingIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Pricings Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeletePricingsDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Pricings Deleted",
            life: 3000,
          });
          setDeletePricingsDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewPricing = () => {
    setPricing(emptyPricing);
    setSubmitted(false);
    setPricingDialog(true);
  };

  const hideDialogPricing = () => {
    setSubmitted(false);
    setPricingDialog(false);
  };

  const hideDeletePricingDialog = () => {
    setDeletePricingDialog(false);
  };

  const hideDeletePricingsDialog = () => {
    setDeletePricingsDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const pricingDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogPricing}
      />
      <Button label="Save" icon="pi pi-check" onClick={savePricing} />
    </React.Fragment>
  );

  const deletePricingDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletePricingDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletePricingDB}
      />
    </React.Fragment>
  );

  const deletePricingsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletePricingsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedPricings}
      />
    </React.Fragment>
  );

  const confirmDeletePricing = (pricing) => {
    setPricing(pricing);
    setDeletePricingDialog(true);
  };

  const confirmDeleteSelectedPricings = () => {
    setDeletePricingsDialog(true);
  };

  const actionBodyTemplatePricing = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editPricing(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeletePricing(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

  // ============START OF EXPORT ==================
  const cols = [
    { field: "_id", header: "ID" },
    { field: "title", header: "Title" },
    { field: "description", header: "Description" },
    { field: "monthlyprice", header: "Monthly Price" },
    { field: "yearlyprice", header: "Yearly Price" },
    { field: "listings", header: "Listings" },
    { field: "activated", header: "Activated" }    
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, pricings);
        doc.save("pricings.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(pricings);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "pricings");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setPricing({ ...pricing, error: "", [name]: value });
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplatePricing = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewPricing}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedPricings}
          disabled={!selectedPricings || !selectedPricings.length}
        />
      </div>
    );
  };

  const rightToolbarTemplatePricing = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help m-2"
          onClick={exportCSV}
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-eye"
          severity="info"
          rounded
          onClick={viewPricingPage}
        />
      </div>
    );
  };

    // ============START OF STATUS SEVERITY ==================

    const getSeverity = (promoCode) => {
      switch (promoCode.activated) {
        case "No":
          return "warning";
  
        case "Yes":
          return "success";
  
        default:
          return null;
      }
    };
  
    const activatedBodyTemplate = (rowData) => {
      return <Tag value={rowData.activated} severity={getSeverity(rowData)}></Tag>;
    };
    // ============END OF STATUS SEVERITY ==================

  // ============END OF TOOLBARS ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Pricings</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Pricings">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplatePricing}
            right={rightToolbarTemplatePricing}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={pricings}
            selection={selectedPricings}
            onSelectionChange={(e) => setSelectedPricings(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pricings"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplatePricing}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="title"
              header="Title"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="description"
              header="Description"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="monthlyprice"
              header="Monthly Price"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="yearlyprice"
              header="Yearly Price"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="listings"
              header="Listings"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="activated"
              header="Activated"
              body={activatedBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={pricingDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Pricing Details"
          modal
          className="p-fluid"
          footer={pricingDialogFooter}
          onHide={hideDialogPricing}
        >
          <div className="field mt-3">
            <label htmlFor="title" className="font-bold">
              Title
            </label>
            <InputText
              id="title"
              value={title}
              onChange={handleChange("title")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !title,
              })}
            />
            {submitted && !title && (
              <small className="p-error">Title is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Description
            </label>
            <InputText
              id="description"
              value={description}
              onChange={handleChange("description")}
              required
              className={classNames({
                "p-invalid": submitted && !description,
              })}
            />
            {submitted && !description && (
              <small className="p-error">Description is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="monthlyprice" className="font-bold">
              Monthly Price
            </label>
            <InputText
              id="monthlyprice"
              value={monthlyprice}
              onChange={handleChange("monthlyprice")}
              required
              className={classNames({
                "p-invalid": submitted && !monthlyprice,
              })}
            />
            {submitted && !monthlyprice && (
              <small className="p-error">Monthly Price is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="yearlyprice" className="font-bold">
              Yearly Price
            </label>
            <InputText
              id="yearlyprice"
              value={yearlyprice}
              onChange={handleChange("yearlyprice")}
              required
              className={classNames({
                "p-invalid": submitted && !yearlyprice,
              })}
            />
            {submitted && !yearlyprice && (
              <small className="p-error">Yearly Price is required.</small>
            )}
          </div>

          <div className="field mt-3">
            <label htmlFor="listings" className="font-bold">
              Listings
            </label>
            <InputText
              id="listings"
              value={listings}
              onChange={handleChange("listings")}
              required
              className={classNames({
                "p-invalid": submitted && !listings,
              })}
            />
            {submitted && !listings && (
              <small className="p-error">Listings is required.</small>
            )}
          </div>
          
          <div className="field mt-3">
            <label htmlFor="name" className="font-bold">
              Activated
            </label>

            <Dropdown
              value={activated}
              onChange={handleChange("activated")}
              options={codeActivated}
              placeholder="Select a Activated"
              showClear
              className={classNames({
                "p-invalid": submitted && !activated,
              })}
            />
            {submitted && !activated && (
              <small className="p-error">Activated is required.</small>
            )}
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deletePricingDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deletePricingDialogFooter}
          onHide={hideDeletePricingDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {pricing && (
              <span>
                Are you sure you want to delete{" "}
                <b>
                  {monthlyprice} {yearlyprice}
                </b>{" "}
                pricing?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deletePricingsDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deletePricingsDialogFooter}
          onHide={hideDeletePricingsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {pricing && (
              <span>Are you sure you want to delete the selected Pricings?</span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminBase>
  );
};
export default ManagePricing;
