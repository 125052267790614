import React, { useEffect, useState, useRef } from "react";
import AdminChapterTitlesBase from "../../core/admin/AdminChapterTitlesBase";
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { createChapterReference, deleteBulkChapterReference, deleteChapterReference, getAllChapterReferences, getChapterReferences, updateBulkChapterReferences, updateChapterReference } from "../helper/adminapicalls";
import { isAuthenticated } from "../../auth/helper";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGESPATH } from "../../backend";
import { Dropdown } from "primereact/dropdown";
import { PickList } from "primereact/picklist";
import { Chips } from 'primereact/chips';
const ChapterReferences = () => {
// =========================START OF DECLARATION OF VALUES==================
const { user, token } = isAuthenticated();
const chapterParams = useParams();
const chapterId = chapterParams.chapterId;
let navigate = useNavigate();

const referenceStatusOptions = ["Pending", "Released", "Hold"];

const referenceTypeOptions = ["Reading", "Video", "Image", "Product","Hybrid"];

const showInBannerOptions = ["Yes","No"];

const [reference, setReference] = useState({
  name: "",
  link: "",
  description: "",
  status: "",
  imageurl: "",
  videourl: "",
  type: '',
  showinbanner: 'No',
  uploadedBy: user._id,
  chapterId: chapterId,
  keywords: '',
  error: "",
  formData: "",
});

const { _id, name, link, description, status, imageurl, videourl, type, showinbanner, keywords, error, formData } =
  reference;

let emptyReference = {
    name: "",
    link: "",
    description: "",
    status: "",
    imageurl: "",
    videourl: "",
    type: '',
    showinbanner: 'No',
    uploadedBy: user._id,
    chapterId: chapterId,
    keywords: '',
    error: "",
    formData: "",
};
const [file, setFile] = useState(null);

const [references, setReferences] = useState([]);
const [referencesListVisble, setReferencesListVisble] = useState(true);

const [referenceDialog, setReferenceDialog] = useState(false);

const [deleteReferenceDialog, setDeleteReferenceDialog] = useState(false);

const [deleteReferencesDialog, setDeleteReferencesDialog] = useState(false);
const [reorderReferencesDialog, setReorderReferencesDialog] = useState(false);

const [selectedReferences, setSelectedReferences] = useState(null);
const [selectedAuthor, setSelectedAuthor] = useState(null);

const [submitted, setSubmitted] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);

//picklist
const [source, setSource] = useState([]);
const [target, setTarget] = useState([]);
//end of picklist

const toast = useRef(null);
const dt = useRef(null);
const fileUploader = useRef(null);
// =========================END OF DECLARATION OF VALUES==================

// =========================START OF PRELOAD ==================

const preload = () => {
  getAllChapterReferences(user._id, token, chapterId)
    .then((data) => {
      if (data.error) {
        setReference({ ...reference, error: data.error });
      } else {
        setSource(data);
        setReferences(data);
        setReference({ ...reference, formData: new FormData()});
      }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
  preload();
}, []);

// =========================END OF PRELOAD==================

const onPickListChange = (event) => {
  setSource(event.source);
  setTarget(event.target);
  let counter=0;
  event.target.forEach(item => {
    counter++;
    item.orderId = counter;
  });
  updateBulkChapterReferences(user._id, token, event.target)
  .then((data) => {
    if (data.error) {
      setReference({
        ...reference,
        error: data.error,
        formData: new FormData(),
      });
      toast.current.show({
        severity: "error",
        summary: "Reference Not Updated",
        detail: data.error,
        life: 5000,
      });
    } else {
      preload();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Reference Updated",
        life: 3000,
      });
    }
  })
  .catch((err) => console.log(err));

};

// ============START OF CUD OPERATIONS ==================
const saveReference = () => {
  setSubmitted(true);
  setReference({ ...reference});
  console.log(reference)
  if (!_id) {
    createChapterReference(user._id, token, reference)
      .then((data) => {
        if (data.error) {
          setReference({
            ...reference,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Reference Not Created",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Reference Created",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    updateChapterReference(user._id, token, _id, reference)
      .then((data) => {
        if (data.error) {
          setReference({
            ...reference,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Reference Not Updated",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Reference Updated",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  }
  setSelectedAuthor(null);
  setReferenceDialog(false);
  setReference(emptyReference);
};

const editReference = (reference) => {
  setReference({ ...reference });
  setSelectedAuthor(reference.author);
  setReferenceDialog(true);
};

const deleteReferenceDB = () => {
  deleteChapterReference(user._id, token, _id)
    .then((data) => {
      if (data.error) {
        setReference({
          ...reference,
          error: data.error,
          formData: new FormData(),
        });
        toast.current.show({
          severity: "error",
          summary: "Reference Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteReferenceDialog(false);
        setReference(emptyReference);
      } else {
        preload();
        setReference(emptyReference);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Reference Deleted",
          life: 3000,
        });
        setDeleteReferenceDialog(false);
        setReference(emptyReference);
      }
    })
    .catch((err) => console.log(err));
};

const deleteSelectedReferences = () => {
  let references = selectedReferences;
  const referenceIds = [];
  {
    Object.entries(references).map(([key, value]) =>
      referenceIds.push(references[key]._id)
    );
  }
  deleteBulkChapterReference(user._id, token, referenceIds)
    .then((data) => {
      if (data.error) {
        toast.current.show({
          severity: "error",
          summary: "References Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteReferencesDialog(false);
      } else {
        preload();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "References Deleted",
          life: 3000,
        });
        setDeleteReferencesDialog(false);
      }
    })
    .catch((err) => console.log(err));
};
// ============END OF CUD OPERATIONS ==================

// =========================START OF DIALOGS OPEN AND HIDE ==================
const openNewReference = () => {
  setReference(emptyReference);
  setSelectedAuthor(null);
  setSubmitted(false);
  setReferenceDialog(true);
};

const hideDialogReference = () => {
  setSubmitted(false);
  setReferenceDialog(false);
};

const hideDeleteReferenceDialog = () => {
  setDeleteReferenceDialog(false);
};

const hideDeleteReferencesDialog = () => {
  setDeleteReferencesDialog(false);
};

const hideReorderReferencesDialog = () => {
  setReorderReferencesDialog(false);
  setTarget([])
  setReferencesListVisble(true);
};

// =========================END OF DIALOGS OPEN AND HIDE ==================

// ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
const referenceDialogFooter = (
  <React.Fragment>
    <Button
      label="Cancel"
      icon="pi pi-times"
      outlined
      onClick={hideDialogReference}
    />
    <Button label="Save" icon="pi pi-check" onClick={saveReference} />
  </React.Fragment>
);

const deleteReferenceDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteReferenceDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteReferenceDB}
    />
  </React.Fragment>
);

const deleteReferencesDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteReferencesDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteSelectedReferences}
    />
  </React.Fragment>
);

const confirmDeleteReference = (reference) => {
  setReference(reference);
  setDeleteReferenceDialog(true);
};

const confirmDeleteSelectedReferences = () => {
  setDeleteReferencesDialog(true);
};

const reorderReferences = () => {
  setReferencesListVisble(false);
  setReorderReferencesDialog(true);
};

const actionBodyTemplateReference = (rowData) => {
  return (
    <React.Fragment>
      <Button
        icon="pi pi-pencil"
        rounded
        outlined
        className="mr-2"
        onClick={() => editReference(rowData)}
      />
      <Button
        icon="pi pi-trash"
        rounded
        outlined
        className="mr-2"
        severity="danger"
        onClick={() => confirmDeleteReference(rowData)}
      />
    </React.Fragment>
  );
};

// ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

// ============START OF EXPORT ==================
const cols = [
  { field: "_id", header: "ID" },
  { field: "name", header: "Reference Name" },
  { field: "author.firstname", header: "Author Firstname" },
  { field: "author.lastname", header: "Author Lastname" },
  { field: "author.designation", header: "Author Designation" },
  { field: "author.organisation", header: "Author Organisation" },
];

const exportColumns = cols.map((col) => ({
  title: col.header,
  dataKey: col.field,
}));

const exportCSV = () => {
  dt.current.exportCSV();
};

const exportPdf = () => {
  import("jspdf").then((jsPDF) => {
    import("jspdf-autotable").then(() => {
      const doc = new jsPDF.default(0, 0);

      doc.autoTable(exportColumns, references);
      doc.save("references.pdf");
    });
  });
};

const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(references);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(excelBuffer, "references");
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};

// ============END OF EXPORT ==================

// ============START OF HANDLE CHANGE ==================
const handleChange = (name) => (event) => {
  const value = event.target.value;
  setReference({ ...reference, [name]: value, orderId: references.length+1 });
};

// ============END OF HANDLE CHANGE ==================

// ============START OF TOOLBARS ==================
const leftToolbarTemplateReference = () => {
  return (
    <div className="flex flex-wrap gap-2">
      <Button
        label="New"
        icon="pi pi-plus"
        severity="success"
        className="m-2"
        onClick={openNewReference}
      />
      <Button
        label="Delete"
        icon="pi pi-trash"
        severity="danger"
        className="m-2"
        onClick={confirmDeleteSelectedReferences}
        disabled={!selectedReferences || !selectedReferences.length}
      />

      <Button
        label="Reorder"
        icon="pi pi-arrows-v"
        severity="info"
        className="m-2"
        onClick={reorderReferences}
      />
    </div>
  );
};

const rightToolbarTemplateReference = () => {
  return (
    <div className="flex align-items-center justify-content-end gap-2 float-end">
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        onClick={exportPdf}
        data-pr-tooltip="PDF"
      />
    </div>
  );
};

// ============END OF TOOLBARS ==================

// ============START OF STATUS SEVERITY ==================

const getSeverity = (reference) => {
  switch (reference.status) {
    case "Pending":
      return "info";

    case "Released":
      return "success";

    case "Hold":
      return "danger";

    default:
      return null;
  }
};

const statusBodyTemplate = (rowData) => {
  return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
};
// ============END OF STATUS SEVERITY ==================

// ============START OF TEMPLATEs ==================

const referenceBodyTemplate = (rowData) => {
  return (
    <div>
      <h5> {rowData.name}</h5>
    </div>
  );
};

const pickListItemTemplate = (reference) => {
  return (
    <div className="d-flex flex-row p-2 align-items-center gap-3">
      <div className="flex-1 flex flex-column gap-2">
        <span className="font-bold">{reference.name}</span>
       
      </div>
    </div>
  );
};

// ============START OF TEMPLATEs ==================

// ============START OF HEADER ==================

const header = (
  <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
    </span>
  </div>
);

// ============END OF HEADER ==================

return (
  <AdminChapterTitlesBase title="References">
    <div>
        <div className="col-12">
            <h2 className="text-center text-primary">REFERENCES</h2>
        </div>
      <Toast ref={toast} />
      {/*  START OF DATA TABLE  */}
      {referencesListVisble && (
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateReference}
            right={rightToolbarTemplateReference}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={references}
            selection={selectedReferences}
            onSelectionChange={(e) => setSelectedReferences(e.value)}
            dataKey="_id"
            paginator
            resizableColumns
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} references"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateReference}
              exportable={false}
              header="Actions"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              body={referenceBodyTemplate}
              header="Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="description"
              header="description"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
      )}
      {/*  END OF DATA TABLE  */}

      {/*  START OF CREATE & EDIT FORM   */}
      <Dialog
        visible={referenceDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Reference Details"
        modal
        className="p-fluid"
        footer={referenceDialogFooter}
        onHide={hideDialogReference}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={name}
            onChange={handleChange("name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !name,
            })}
          />
          {submitted && !name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="link" className="font-bold">
            link
          </label>
          <InputText
            id="name"
            value={link}
            onChange={handleChange("link")}
            required
            
            className={classNames({
              "p-invalid": submitted && !link,
            })}
          />
          {submitted && !link && (
            <small className="p-error">Link is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={description}
            onChange={handleChange("description")}
            required
            rows={3}
            cols={20}
          />
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Status
          </label>

          <Dropdown
            value={status}
            onChange={handleChange("status")}
            options={referenceStatusOptions}
            placeholder="Select a Status"
            showClear
            className={classNames({
              "p-invalid": submitted && !status,
            })}
          />
          {submitted && !status && (
            <small className="p-error">Status is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="imageurl" className="font-bold">
            Image URL
          </label>
          <InputText
            id="name"
            value={imageurl}
            onChange={handleChange("imageurl")}
            required
            
            className={classNames({
              "p-invalid": submitted && !imageurl,
            })}
          />
          {submitted && !imageurl && (
            <small className="p-error">Image URL is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="videourl" className="font-bold">
            Video URL
          </label>
          <InputText
            id="name"
            value={videourl}
            onChange={handleChange("videourl")}
            required
            
            className={classNames({
              "p-invalid": submitted && !videourl,
            })}
          />
          {submitted && !videourl && (
            <small className="p-error">Video URL is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>

          <Dropdown
            value={type}
            onChange={handleChange("type")}
            options={referenceTypeOptions}
            placeholder="Select a Type"
            showClear
            className={classNames({
              "p-invalid": submitted && !type,
            })}
          />
          {submitted && !type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="keywords" className="font-bold">
            Keywords
          </label>

          <Chips value={keywords} onChange={handleChange("keywords")} />

          
          {submitted && !keywords && (
            <small className="p-error">Keywords is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="showinbanner" className="font-bold">
            Show In Banner
          </label>

          <Dropdown
            value={showinbanner}
            onChange={handleChange("showinbanner")}
            options={showInBannerOptions}
            placeholder="Select a Choice"
            showClear
            className={classNames({
              "p-invalid": submitted && !showinbanner,
            })}
          />
          {submitted && !showinbanner && (
            <small className="p-error">Show in Banner is required.</small>
          )}
        </div>
      </Dialog>
      {/*  END OF CREATE & EDIT FORM   */}
      {/*  START OF DELETE FORM   */}
      <Dialog
        visible={deleteReferenceDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteReferenceDialogFooter}
        onHide={hideDeleteReferenceDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {reference && (
            <span>
              Are you sure you want to delete <b>{name}</b> reference?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF DELETE FORM   */}

      {/*  START OF MULTIPLE DELETE FORM   */}
      <Dialog
        visible={deleteReferencesDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteReferencesDialogFooter}
        onHide={hideDeleteReferencesDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {reference && (
            <span>
              Are you sure you want to delete the selected references?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF MULTIPLE DELETE FORM   */}

      {/*  START OF REORDER CHAPTER FORM   */}

      {reorderReferencesDialog && (
        <div className="card p-4">
          <div className="row m-3">
            <div className="col-6 text-left">
            <h2 className="text-primary">Reorder References List</h2>
            </div>
            <div className="col-6 text-right">
              
              <Button
                label="Close Reorder"
                className=""
                onClick={hideReorderReferencesDialog}
              />
            </div>
          </div>
          <PickList
            source={source}
            target={target}
            onChange={onPickListChange}
            itemTemplate={pickListItemTemplate}
            filter
            filterBy="name"
            breakpoint="1400px"
            sourceHeader="Available"
            targetHeader="Selected"
            showSourceControls={false}
            sourceStyle={{ height: "24rem" }}
            targetStyle={{ height: "24rem" }}
            sourceFilterPlaceholder="Search by name"
            targetFilterPlaceholder="Search by name"
          />
        </div>
      )}

      {/*  END OF REORDER CHAPTER FORM   */}
    </div>
  </AdminChapterTitlesBase>
);
}

export default ChapterReferences