import React from "react";
import Base from "./Base";
import { Helmet } from "react-helmet";
import { Accordion, AccordionTab } from "primereact/accordion";
const Features = () => {
  return (
    <div>
      <Helmet>
        <title>Scope Ebook - Features</title>
      </Helmet>

      <Base>
        <div class="services section" id="services">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="section-heading text-center">
                      <h2>
                        We Provide <em>Practical Approach</em> &amp;{" "}
                        <span>Training</span> For Your Career
                      </h2>
                    </div>
                  </div>
                  <div className="row my-2">
                  <div className="col-lg-12">
                    <p className="text-blue para text-justify">
                      In the rapidly advancing field of medical endoscopy,
                      staying up-to-date with the latest trends, procedures, and
                      associated accessories is crucial for healthcare
                      professionals. The ScopeBook is your ultimate eBook
                      companion, designed to provide an in-depth exploration of
                      all things related to the endoscopy unit. Whether you're a
                      seasoned endoscopist or a medical student eager to learn,
                      this eBook will serve as your indispensable resource.
                      Let's delve into the key features that make ScopeBook an
                      essential tool for anyone involved in endoscopy.
                    </p>
                  </div>
                  </div>
                  <div className="row">
                  <div class="col-lg-3 col-sm-3 text-center">
                    <div class="service-item">
                      <div class="icon">
                        <img
                          src="assets/images/services-01.jpg"
                          alt="discover SEO"
                          class="templatemo-feature"
                        />
                      </div>
                      <h4>Discover Latest Endoscopy Trends</h4>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-3 text-center">
                    <div class="service-item">
                      <div class="icon">
                        <img
                          src="assets/images/services-02.jpg"
                          alt="data analysis"
                          class="templatemo-feature"
                        />
                      </div>
                      <h4>Real-Time Condition Representations</h4>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-3 text-center">
                    <div class="service-item">
                      <div class="icon">
                        <img
                          src="assets/images/services-03.jpg"
                          alt="precise data"
                          class="templatemo-feature"
                        />
                      </div>
                      <h4>Precise Analysis &amp; Solution</h4>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-3 text-center">
                    <div class="service-item">
                      <div class="icon">
                        <img
                          src="assets/images/services-04.jpg"
                          alt="SEO marketing"
                          class="templatemo-feature"
                        />
                      </div>
                      <h4>Explore the Field of Endoscopy</h4>
                    </div>
                  </div>
                  </div>
                  <div className="row my-2 ">
                  <div className="col-6">
                  <Accordion>
                    <AccordionTab header="Comprehensive Coverage of Endoscopy Trends">
                      <p className="m-0">
                        ScopeBook is meticulously curated to cover the most
                        recent trends in the field of endoscopy. It provides
                        insights into emerging technologies, innovative
                        techniques, and groundbreaking research that are shaping
                        the future of endoscopic procedures. Readers can expect
                        to gain a deep understanding of where the field is
                        heading and how to adapt to new developments.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Detailed Procedure Descriptions">
                      <p className="m-0">
                        One of the primary focuses of ScopeBook is to offer
                        comprehensive descriptions of various endoscopic
                        procedures. Each procedure is explained in detail, from
                        patient preparation to post-procedure care. Clear,
                        step-by-step instructions, accompanied by high-quality
                        illustrations and videos, ensure that both beginners and
                        experienced practitioners can benefit from the eBook.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Accessory Insights">
                      <p className="m-0">
                        The endoscopy unit relies on a wide array of specialized
                        accessories to ensure successful procedures. ScopeBook
                        provides an exhaustive catalog of these accessories,
                        detailing their functions, benefits, and best practices
                        for their usage. From endoscopes and light sources to
                        insufflators and biopsy forceps, you'll find all the
                        information you need to make informed decisions about
                        equipment selection and maintenance.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Expert Contributors">
                      <p className="m-0">
                        ScopeBook boasts contributions from leading experts in
                        the field of endoscopy. These experts share their
                        practical insights, clinical experiences, and best
                        practices, making this eBook a trusted source of
                        knowledge. Their expertise ensures that ScopeBook
                        remains a reliable reference for healthcare
                        professionals.
                      </p>
                    </AccordionTab>
                
                    
                    
                   
                  </Accordion>
                  </div>
                  <div className="col-6">
                  <Accordion>
                    <AccordionTab header="Interactive Features">
                      <p className="m-0">
                        To enhance the learning experience, ScopeBook
                        incorporates interactive elements such as quizzes, case
                        studies, and self-assessment tools. These features allow
                        readers to test their knowledge and apply what they've
                        learned in real-world scenarios, promoting active
                        learning and skill development.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Search and Navigation">
                      <p className="m-0">
                        ScopeBook offers a user-friendly interface with robust
                        search and navigation features. Readers can easily find
                        specific topics or procedures of interest, making it a
                        convenient resource for quick reference during clinical
                        practice.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Regular Updates">
                      <p className="m-0">
                        The field of endoscopy is constantly evolving. ScopeBook
                        is committed to providing regular updates to keep
                        readers informed about the latest advancements,
                        guidelines, and best practices. Subscribers can access
                        these updates to ensure their knowledge remains current.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Reference Links and Supported Materials">
                      <p className="m-0">
                          In addition to comprehensive textual content,
                        ScopeBook offers an extensive collection of reference
                        links and supported materials. These include hyperlinks
                        to relevant research papers, clinical guidelines, and
                        online resources, ensuring that readers have easy access
                        to the latest studies, official recommendations, and
                        additional learning materials. Whether you want to dive
                        deeper into a specific topic or stay current with
                        evolving standards, these reference links and supported
                        materials serve as valuable supplements to your
                        knowledge, enriching your understanding of the endoscopy
                        field.
                      </p>
                    </AccordionTab>
                  </Accordion>
                  </div>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </Base>
    </div>
  );
};

export default Features;
