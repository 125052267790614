import React, { useEffect, useState } from 'react'
import UserBase from '../core/user/UserBase'
import { NavLink } from 'react-router-dom'

const UserDashboard = () => {

  const [error, setError] = useState(null);

  
  return (
    <UserBase>
      <div className="d-flex justify-content-center"> 
        
    <h2 className='text-primary'>Welcome To ScopEbook - Pilot Version</h2>  
    </div>
    <div className="d-flex justify-content-center"> 
      <img src="/images/scopeBanner.png" style={{height:"20rem"}} />
      </div>
    <div className="d-flex justify-content-center"> 
     <h2>Get Early Access to Our <span className='text-primary'>ScopeEbook</span>!</h2>
      </div>
    <div className="d-flex justify-content-center"> 
     <h2>Your feedback will be valued!</h2>
      </div>
    <div className="d-flex justify-content-center"> 
     <h2><NavLink to="/user/activate">Activate and Get Started</NavLink></h2>
      </div>
      
    </UserBase>
  )
}

export default UserDashboard
