import React from "react";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Base = ({children}) => {
  return (
    <div>
      <Helmet>
        <link href="/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="/assets/css/fontawesome.css" />
        <link
          rel="stylesheet"
          href="/assets/css/templatemo-tale-seo-agency.css"
        />
        <link href="/styles.css" rel="stylesheet" ></link>
        <link rel="stylesheet" href="/assets/css/owl.css" />
        <link rel="stylesheet" href="/assets/css/animate.css" />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
      <Menu />
      <div className="bg-head text-center text-white m-4"></div>
      <div>{children}</div>
      <footer>
        <div class="container">
          <div class="col-lg-12">
            <p className="footer-text">
              Copyright © 2024
              <NavLink
                target="_blank"
                className="mx-1 fw-bold text-white"
                to="https://scopehealthuk.com"
              >
                Scope Health UK
              </NavLink>
              . All rights reserved | Powered by
              <NavLink
                target="_blank"
                className="mx-1 text-white fw-bold"
                to="https://maylancertechios.com"
              >
                Maylancer Techios
              </NavLink>
            </p>
          </div>
        </div>
      </footer>
      <script src="/vendor/jquery/jquery.min.js"></script>
      <script src="/vendor/bootstrap/js/bootstrap.min.js"></script>

      <script src="/assets/js/isotope.min.js"></script>
      <script src="/assets/js/owl-carousel.js"></script>
      <script src="/assets/js/tabs.js"></script>
      <script src="/assets/js/popup.js"></script>
      <script src="/assets/js/custom.js"></script>
    </div>
  );
};

export default Base;
