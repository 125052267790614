import React, { Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isAuthenticated, logout } from "../../auth/helper";

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div>
    

      <div class="pre-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-sm-9">
              <div class="left-info">
                <ul>
                  <li>
                    <a href="#">
                      <i class="fa fa-globe"></i>www.scopehealthuk.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-envelope"></i>admin@scopebook.uk
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-map-marker"></i> Wlimslow, United Kingdom
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-sm-3">
              <div class="social-icons">
                <ul>
                  <li>
                    <a href="#">
                      <i class="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header class="header-area header-sticky">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <nav class="main-nav">
                <a href="/" class="logo">
                  <img
                    src="/images/ebookLogoWhite.png"
                    alt="Scope Ebook Logo"
                  />
                </a>
                <ul class="nav">
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/features"
                    >
                      Features
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/pricing"
                    >
                      Pricing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/register"
                    >
                      Register
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#ffffff" : "#B3E5FC",
                        };
                      }}
                      className="nav-link"
                      to="/login"
                    >
                      Login
                    </NavLink>
                  </li>
                </ul>
                <a class="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Menu;
