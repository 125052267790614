import React, { useEffect, useState } from "react";
import Base from "../core/static/Base";
import { authenticate, isAuthenticated, login } from "../auth/helper";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { UnauthenticatedTemplate, AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./helper/msal/authConfig";
import MSALogin from "./MSALogin";
import { GSignIn, MSSignIn, oauthSignIn, sendIdTokenToBackend } from "./helper/SsoApiCalls";
import axios from "axios";
import { API } from "../backend";


const Login = () => {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    didRedirect: false,
  });
   
  const { email, password, error, loading, didRedirect } = values;
  const { user } = isAuthenticated();

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((data) => {
                MSSignIn(data.accessToken).then((data)=>{
                    authenticate(data, ()=> {
                        performRedirect(data.user)
                    })
                });
               
            });
    }
  

    const performRedirect = (user) => {
        if (user) {
            if (user.verified === "No") {
                navigate("/profileVerificationPending");
            } else {
                if (user.role === "Admin") {
                    navigate("/admin/dashboard");
                } else {
                    navigate("/user/dashboard");
                }
            }
        }
    }

    useEffect(() => {
        RequestProfileData()
    }, [accounts])
    
    return (
        <>
                
        </>
    );
};
  
  const performRedirect = () => {
    if (didRedirect) {
      if(user.verified==="No"){
        return <Navigate to="/profileVerificationPending" replace />
      }else{
      if (user && user.role === "Admin") {
        return <Navigate to="/admin/dashboard" replace />;
      } else {
        return <Navigate to="/user/dashboard" replace/>;
      }
    }
  }
    if (isAuthenticated()) {
      return <NavLink to="/" />;
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    email.toLowerCase();
    login({ email, password })
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              didRedirect: true,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err)
      });
  };

  // WINDOWS AUTHENTICATION

  const { instance } = useMsal();

  const handleMSALLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }

  // END OF WINDOWS AUTHENTICATION

  // GOOGLE AUTHENTICATION
  const handleGlogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const tokens = await axios.post(`${API}/auth/google`, {  
        code: code
      })
      authenticate(tokens.data, () => {
        if(tokens.data.user.verified==="No"){
          navigate("/profileVerificationPending")
        }else{
        if (tokens.data.user && tokens.data.user.role === "Admin") {
          navigate("/admin/dashboard")
        } else {
          navigate("/user/dashboard")
        }
      }
          })
    },
    flow: 'auth-code',
    onFailure: (error) => {
      // Handle failure scenario here
      setValues({ ...values, error: "Google Login Failed", loading: false });
    }
  })
 
  // END OF GOOGLE AUTHENTICATION
  const loadingMessage = () => {
    return (
      loading && (
        <div className="alert alert-info">
          <h2>Loading....</h2>
        </div>
      )
    );
  };

  const errorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
          >
            {JSON.stringify(error)}
          </div>
        </div>
      </div>
    );
  };

  const loginForm = () => {
    return (
      <div className="row">
        <div class="contact-us section" id="contact">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-us-content">
                  <div class="row">
                    <div class="col-lg-4" style={{ margin: "auto" }}>
                      <img src="/images/scopeEbook.png" />
                    </div>
                    <div class="col-lg-8">
                    <div class="row">
                      <form id="contact-form" action="" method="post">
                       
                          <div class="col-lg-12">
                            <div class="section-heading">
                              <h2>
                                <em>Login</em>
                              </h2>
                              {loadingMessage()}
                              {errorMessage()}
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder="E-mail"
                                value={email}
                                onChange={handleChange("email")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={handleChange("password")}
                                required="required"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <button
                                type="submit"
                                id="form-submit"
                                onClick={onSubmit}
                                class="orange-button"
                              >
                                Login
                              </button>
                            </fieldset>
                           </div>
                          
                           {/* <div className="col-12 text-end px-3 py-1">
                            <span className="text-end text-primary text-md" onClick={()=>navigate("/forgotPassword")}>Forgot Password?</span>
                            </div> */}
                            </form>
                           


                            <div className="row">
                            <div className="col-md-6">
                            <button className="SSOButton MSButton" onClick={() => handleMSALLogin()}>
                            <i class="fa-brands fa-windows"></i>  Sign in with Microsoft
      </button>
      </div>
      <div className="col-md-6">

      <button className="GButton SSOButton" onClick={() => handleGlogin()}> <i class="fa-brands fa-google"></i> Sign in with Google </button>
       
      {/* <button className="SSOButton GButton" onClick={() => handleMSALLogin()}>
                            <i class="fa-brands fa-google"></i>  Sign in with Google
      </button> */}
      </div>
      </div>
                            
                            
     


     
        
                          


                       
</div>
                        
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Scope Ebook - Login</title>
      </Helmet>
      <Base>
       
        <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5>
                    <center>
                    {loginForm()}
                    </center>
                </h5>
            </UnauthenticatedTemplate>
  
    
        {performRedirect()}
      </Base>
    </div>
  );
};

export default Login;
