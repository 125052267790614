import React from "react";
import { NavLink } from "react-router-dom";
const AdminMenu = () => {

  return (
    <div id="navbarScroll">
      {" "}
      <ul
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion position-sticky navbar-nav-scroll" style={{overflowX: "hidden"}} >
        <a
          class="sidebar-brand d-flex align-items-center justify-content-center"
          href=""
        >
          <img
            src="/images/ebookLogoWhite.png"
            alt="Scope Ebook Logo"
            style={{ width: "100%" }}
          />
        </a>
        <hr class="sidebar-divider my-0" />
        <li class="nav-item active">
          <NavLink className="nav-link" to="/admin/dashboard">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <hr class="sidebar-divider" />
        <div class="sidebar-heading">Manage</div>
       
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/accessories">
          <i class="pi pi-users"></i>
            <span>Accessories</span>
          </NavLink>
        </li>
       
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/authors">
          <i class="pi pi-file-o"></i>
            <span>Authors</span>
          </NavLink>
        </li>
        
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/chapters">
          <i class="pi pi-file-o"></i>
            <span>Chapters</span>
          </NavLink>
        </li>
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/hospitals">
          <i class="pi pi-calendar-plus"></i>
            <span>Hospitals</span>
          </NavLink>
        </li>
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/procedures">
          <i class="pi pi-users"></i>
            <span>Procedures</span>
          </NavLink>
        </li>
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/promoCodes">
          <i class="pi pi-star-fill"></i>
            <span>Promo Codes</span>
          </NavLink>
        </li>
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/users">
          <i class="pi pi-users"></i>
            <span>Users</span>
          </NavLink>
        </li>
        <hr class="sidebar-divider" />
        <li class="nav-item">
          <NavLink className="nav-link" to="/admin/pricing">
          <i class="pi pi-users"></i>
            <span>Ebook Pricing</span>
          </NavLink>
        </li>

        <hr class="sidebar-divider" />




        <div class="sidebar-card d-none d-lg-flex" >
          <img
            class="sidebar-card-illustration mb-2"
            style={{height:"10rem"}}
            src="/images/scopeebookCircle.png"
            alt="..."
          />
        </div>
      </ul>
    </div>
  );
};

export default AdminMenu;
