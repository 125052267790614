import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import {isAuthenticated} from "./index"

const AdminRoutes = () =>  {
  return (
        isAuthenticated() && isAuthenticated().user.role === "Admin"  ? <Outlet /> : <Navigate to="/login" />
        )
    }
  

export default AdminRoutes