import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../core/admin/AdminBase";
import { isAuthenticated } from "../auth/helper";

import { Tag } from "primereact/tag";
import {
  createPromoCode,
  deleteBulkPromoCode,
  deletePromoCode,
  getAllPromoCodes,
  updatePromoCode,
} from "./helper/adminapicalls";

const PromoCodes = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  const codeStatus = ["Issued","Inactive", "Active", "Revoked","Cancelled"];

  const [promoCode, setPromoCode] = useState({
    _id: "",
    code: "",
    allotedto: "",
    status: "",
    promocodetype: "",
    comments: "",
    error: "",
    formData: "",
  });

  const {
    _id,
    code,
    allotedto,
    status,
    promocodetype,
    comments,
    error,
    formData,
  } = promoCode;

  let emptyPromoCode = {
    id: null,
    code: "",
    allotedto: "",
    status: "",
    promocodetype: "",
    comments: "",
    error: "",
    formData: "",
  };

  const [promoCodes, setPromoCodes] = useState(null);

  const [promoCodeDialog, setPromoCodeDialog] = useState(false);

  const [deletePromoCodeDialog, setDeletePromoCodeDialog] = useState(false);

  const [deletePromoCodesDialog, setDeletePromoCodesDialog] = useState(false);

  const [selectedPromoCodes, setSelectedPromoCodes] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);

  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllPromoCodes(user._id, token)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          setPromoCode({ ...promoCode, error: data.error });
        } else {
          setPromoCodes(data);
          setPromoCode({ ...promoCode, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

    // ============START OF CUD OPERATIONS ==================
    const savePromoCode = (promoCode) => {
        setSubmitted(true);
        setPromoCode({ ...promoCode, error: "", loading: true });
        if (!_id) {
          createPromoCode(user._id, token, {
            code,
            allotedto,
            status,
            promocodetype,
            comments,
          })
            .then((data) => {
              if (data.error) {
                setPromoCode({
                  ...promoCode,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "Promo Code Not Created",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Promo Code Created",
                  life: 3000,
                });
              }
            })
            .catch((err) => console.log(err));
        } else {
          updatePromoCode(user._id, token, _id, {
            code,
            allotedto,
            status,
            promocodetype,
            comments,
          })
            .then((data) => {
              if (data.error) {
                setPromoCode({
                  ...promoCode,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "Promo Code Not Updated",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "Promo Code Updated",
                  life: 3000,
                });
              }
            })
            .catch((err) => console.log(err));
        }
        setPromoCodeDialog(false);
        setPromoCode(emptyPromoCode);
      };
    
      const editPromoCode = (promoCode) => {
        setPromoCode({ ...promoCode });
        setPromoCodeDialog(true);
      };
    
      const deletePromoCodeDB = () => {
        deletePromoCode(user._id, token, _id)
          .then((data) => {
            if (data.error) {
              setPromoCode({
                ...promoCode,
                error: data.error,
                formData: new FormData(),
              });
              toast.current.show({
                severity: "error",
                summary: "Promo Code Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeletePromoCodeDialog(false);
              setPromoCode(emptyPromoCode);
            } else {
              preload();
              setPromoCode({
                ...promoCode,
                code: "",
                allotedto: "",
                status: "",
                promocodetype: "",
                comments: "",
              });
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Promo Code Deleted",
                life: 3000,
              });
              setDeletePromoCodeDialog(false);
              setPromoCode(emptyPromoCode);
            }
          })
          .catch((err) => console.log(err));
      };
    
      const deleteSelectedPromoCodes = () => {
        let promocodes = selectedPromoCodes;
        const promoCodeIds = [];
        {
          Object.entries(promocodes).map(([key, value]) =>
            promoCodeIds.push(promocodes[key]._id)
          );
        }
        deleteBulkPromoCode(user._id, token, promoCodeIds)
          .then((data) => {
            if (data.error) {
              toast.current.show({
                severity: "error",
                summary: "Promo Codes Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeletePromoCodesDialog(false);
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Promo Codes Deleted",
                life: 3000,
              });
              setDeletePromoCodesDialog(false);
            }
          })
          .catch((err) => console.log(err));
      };
      // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewPromoCode = () => {
    setPromoCode(emptyPromoCode);
    setSubmitted(false);
    setPromoCodeDialog(true);
  };

  const hideDialogPromoCode = () => {
    setSubmitted(false);
    setPromoCodeDialog(false);
  };

  const hideDeletePromoCodeDialog = () => {
    setDeletePromoCodeDialog(false);
  };

  const hideDeletePromoCodesDialog = () => {
    setDeletePromoCodesDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const promoCodeDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogPromoCode}
      />
      <Button label="Save" icon="pi pi-check" onClick={savePromoCode} />
    </React.Fragment>
  );

  const deletePromoCodeDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletePromoCodeDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletePromoCodeDB}
      />
    </React.Fragment>
  );

  const deletePromoCodesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletePromoCodesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedPromoCodes}
      />
    </React.Fragment>
  );

  const confirmDeletePromoCode = (promoCode) => {
    setPromoCode(promoCode);
    setDeletePromoCodeDialog(true);
  };

  const confirmDeleteSelectedPromoCodes = () => {
    setDeletePromoCodesDialog(true);
  };

  const actionBodyTemplatePromoCode = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editPromoCode(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeletePromoCode(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================



  // ============START OF EXPORT ==================
  const cols = [
    { field: '_id', header: 'ID' },
    { field: 'code', header: 'Code' },
    { field: 'allotedto', header: 'Alloted To' },
    { field: 'status', header: 'Status' },
    { field: 'promocodetype', header: 'Promo Code Type' },
    { field: 'comments', header: 'Comments' }
];

const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  
  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);

            doc.autoTable(exportColumns, promoCodes);
            doc.save('promocodes.pdf');
        });
    });
};

const exportExcel = () => {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(promoCodes);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'promocodes');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setPromoCode({ ...promoCode, error: "", [name]: value });
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplatePromoCode = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewPromoCode}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedPromoCodes}
          disabled={!selectedPromoCodes || !selectedPromoCodes.length}
        />
      </div>
    );
  };

  const rightToolbarTemplatePromoCode = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
             <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
            <Button type="button" className="m-1" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" className="m-1" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
     
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF STATUS SEVERITY ==================

  const getSeverity = (promoCode) => {
    switch (promoCode.status) {
      case "Inactive":
        return "warning";

      case "Active":
        return "success";

      case "Revoked":
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };
  // ============END OF STATUS SEVERITY ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Promo Codes</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Promo Codes">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplatePromoCode}
            right={rightToolbarTemplatePromoCode}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={promoCodes}
            selection={selectedPromoCodes}
            onSelectionChange={(e) => setSelectedPromoCodes(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} promo codes"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplatePromoCode}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="code"
              header="Code"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="allotedto"
              header="Alloted To"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="promocodetype"
              header="Promo Code Type"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="comments"
              header="Comments"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
       
          </DataTable>
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={promoCodeDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Promo Code Details"
          modal
          className="p-fluid"
          footer={promoCodeDialogFooter}
          onHide={hideDialogPromoCode}
        >
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Code
            </label>
            <InputText
              id="name"
              value={code}
              onChange={handleChange("code")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !code,
              })}
            />
            {submitted && !code && (
              <small className="p-error">Code is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Alloted To
            </label>
            <InputText
              id="name"
              value={allotedto}
              onChange={handleChange("allotedto")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !allotedto,
              })}
            />
            {submitted && !allotedto && (
              <small className="p-error">Alloted To is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Status
            </label>

            <Dropdown
              value={status}
              onChange={handleChange("status")}
              options={codeStatus}
              placeholder="Select a Status"
              showClear
              className={classNames({
                "p-invalid": submitted && !status,
              })}
            />
            {submitted && !status && (
              <small className="p-error">Status is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Promo Code Type
            </label>
            <InputText
              id="name"
              value={promocodetype}
              onChange={handleChange("promocodetype")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !promocodetype,
              })}
            />
            {submitted && !promocodetype && (
              <small className="p-error">Promo Code Type is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="description" className="font-bold">
              Comments
            </label>
            <InputTextarea
              id="description"
              value={comments}
              onChange={handleChange("comments")}
              required
              rows={3}
              cols={20}
            />
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deletePromoCodeDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deletePromoCodeDialogFooter}
          onHide={hideDeletePromoCodeDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {promoCode && (
              <span>
                Are you sure you want to delete <b>{code}</b> alloted to{" "}
                <b>{allotedto}</b>?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deletePromoCodesDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deletePromoCodesDialogFooter}
          onHide={hideDeletePromoCodesDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {promoCode && (
              <span>
                Are you sure you want to delete the selected Promo Codes?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminBase>
  );
};
export default PromoCodes;
