import React, { useEffect } from "react";
import Base from "./Base";
import { Helmet } from "react-helmet";

import { Button } from "primereact/button";
import { useState } from "react";

import { SelectButton } from "primereact/selectbutton";
import { getAllPublicPricings } from "../helper/publicuserapicalls";
import { json } from "react-router-dom";

const Pricing = () => {
  const options = ["Monthly", "Yearly"];
  const [value, setValue] = useState(options[0]);
  const [pricings, setPricings] = useState([]);
 
  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllPublicPricings()
      .then((data) => {
        console.log(data);
        setPricings(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);


  const listingsSplit = (listings) => {
    const listingItems = listings.split('|')
    return listingItems
  }


  // =========================END OF PRELOAD==================

  const pricingItems = pricings.map((pricing) => <div className="col-3 p-2">
  <div className="card">
    <div className=" h-full">
      <div
        className="shadow-2 h-full flex flex-column"
        style={{ borderRadius: "6px" }}
      >
        <div className="p-4 bg-blue pricing-text mb-2 ">
         {pricing.title}
        </div>
        <div className="pricing-description-text flex align-items-center  p-2" style={{height:"100px"}}>
         {pricing.description}
        </div>
        <hr className=" mx-0 border-top-1 border-bottom-none border-300" />
      
        {/* Monthly Price */}
        {value=="Monthly" && (
        <div className="flex align-items-center bg-blue p-4">
          <span className="pricing-price">
            £ {pricing.monthlyprice}{" "}
          </span>
          <span className="ml-2 pricing-price-duration">
             / Month
          </span>
        </div>
        )}
        {/* End of Monthly Price */}

         {/* Yearly Price */}
         {value=="Yearly" && (
         <div className="flex align-items-center bg-blue p-4">
          <span className="pricing-price">
            £ {pricing.monthlyprice}{" "}
          </span>
          <span className="ml-2 pricing-price-duration">
             / Year
          </span>
        </div>
         )}
        {/* End of Yearly Price */}
        <hr className=" mx-0 border-top-1 border-bottom-none border-300" />
        <div className="pricing-description-text flex align-items-center  p-2" style={{height:"100px"}}>
          <ul>
          {
          listingsSplit(pricing.listings).map(listingItem => (
            <li>{listingItem}</li>
          ))
          }
          </ul>
          </div>
        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
        <div>
        <Button
        className="pricing-button"
          label="Buy Now"
       
         text
         raised
         outlined
        />
        </div>
      </div>
    </div>
  </div>
</div>);

  return (
    <div>
      <Helmet>
        <title>Scope Ebook - Pricing</title>
      </Helmet>

      <Base>
        <div class="infos" id="infos">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-content">
                  <div class="row">
                    <div className="surface-0">
                      <div class="section-heading text-center">
                        <h2>
                          Choose Your <em>ScopEbook </em> Subscription Plan
                        </h2>
                        {/* <div className="col-md-12 text-center mb-5">
                          <div className=" flex justify-content-center">
                            <SelectButton
                              value={value}
                              onChange={(e) => setValue(e.value)}
                              options={options}
                            />
                          </div>
                        </div> */}

                        <div className="container ">
                          <div className="row">
                            <div className="text-center">
                            <img src="/images/scopeBanner.png" style={{width:"50%"}}  />
                            </div>
                            {/* {pricingItems} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Base>
    </div>
  );
};

export default Pricing;
