import React, { useEffect, useRef, useState } from "react";
import AdminBase from "./AdminBase";
import { useNavigate, useParams } from "react-router-dom";
import { getChapter } from "../../admin/helper/adminapicalls";
import { isAuthenticated } from "../../auth/helper";
import { Toast } from "primereact/toast";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";

import { Splitter, SplitterPanel } from "primereact/splitter";


const AdminChapterTitlesBase = ({children}) => {
  const { user, token } = isAuthenticated();
  const chapterParams = useParams();
  const [chapter, setChapter] = useState("");
  const toast = useRef(null);

  let navigate = useNavigate();

  const preload = () => {
    getChapter(user._id, token, chapterParams.chapterId).then((data) => {
      if (data.error) {
        toast.current.show({
          severity: "error",
          summary: "Chapter Not Found",
          detail: data.error,
          life: 5000,
        });
      } else {
        setChapter(data);
      }
    });
  };

  useEffect(() => {
    preload();
  }, []);



  // ============START OF FORM FUNCTIONS ==================

 
  // ============END OF FORM FUNCTIONS ==================

  // ============START OF MENU FUNCTIONS ==================
  const overviewChapter = () => {
    navigate(`/admin/chapter/overview/${chapter._id}`);
  };

  const createTitlesChapter = () => {
    navigate(`/admin/chapter/titles/${chapter._id}`);
  };

  const manageTitlesChapter = () => {
    navigate(`/admin/chapter/manageTitles/${chapter._id}`);
  };

  const imagesChapter = () => {
    navigate(`/admin/chapter/images/${chapter._id}`);
  };

  const bannersChapter = () => {
    navigate(`/admin/chapter/banners/${chapter._id}`);
  };

  const referencesChapter = () => {
    navigate(`/admin/chapter/references/${chapter._id}`);
  };

  const checklistsChapter = () => {
    navigate(`/admin/chapter/checklists/${chapter._id}`);
  };

  const accessoriesChapter = () => {
    navigate(`/admin/chapter/accessories/${chapter._id}`);
  };


  const closeChapter = () => {
    navigate(`/admin/chapters/`);
  };
  // ============END OF MENU FUNCTIONS ==================

  // ============START OF MENU ITEMS ==================

  const menuItems = [
    {
      label: "Overview",
      icon: "pi pi-fw pi-file",
      command:()=> overviewChapter(),
    },
    {
      label: "Titles",
      icon: "pi pi-fw pi-file",
      command: () => manageTitlesChapter()
    },
    {
      label: "Images",
      icon: "pi pi-fw pi-image",
      command: () => imagesChapter()
    },
    {
      label: "Banner",
      icon: "pi pi-fw pi-pencil",
      command: () => bannersChapter()
    },
    {
      label: "Summation ",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Checklist",
          icon: "pi pi-fw pi-bars",
          command: () => checklistsChapter()
        },
        {
          label: "References",
          icon: "pi pi-fw pi-bookmark",
          command: () => referencesChapter()
        },
      ],
    },
    {
      label: "Close",
      icon: "pi pi-fw pi-power-off",
      command: () => closeChapter(),
    },
  ];
  // ============END OF MENU ITEMS ==================

  const start = (
    <i className="pi pi-book info" style={{ fontSize: "2rem" }}></i>
  );
  const end = <InputText placeholder="Search" type="text" className="w-full" />;

  return (
    <AdminBase title={`Chapter -  ${chapter.name}`}>
      <Toast ref={toast} />
      <Splitter layout="vertical">
        <SplitterPanel className="flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Menubar model={menuItems} start={start} end={end} />
              </div>
            </div>
          </div>
        </SplitterPanel>
        <SplitterPanel className="flex">
          {children}
        </SplitterPanel>
      </Splitter>
    </AdminBase>
  );
};

export default AdminChapterTitlesBase;
