import { API } from "../backend";

export const sendDelegateVerificationEmail = (Email) => {
    return fetch(`${API}/mailing/delegateVerification/${Email}`,{
        method:"GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const checkDelegateVerificationEmail = (Email,Code) => {
    return fetch(`${API}/verifyUser/${Email}/${Code}`,{
        method:"GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}