import React, { useEffect, useState } from "react";
import UserBase from "../core/user/UserBase";
import { isAuthenticated } from "../auth/helper";
import { ebookActivationStatus, getChapters } from "./helper/userapicalls";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { IMAGESPATH } from "../backend";

import { Avatar } from "primereact/avatar";
import Timestamp from "react-timestamp";

const EbookChapters = () => {
  const { user, token } = isAuthenticated();
  const [activated, setActivated] = useState(false);
  
  const [chapters, setChapters] = useState([]);

  const preload = () => {
    //checking valid license before admitting the user inside chapters
    ebookActivationStatus(user._id, token)
      .then((data) => {
        if (data.error) {
          setActivated(false);
        } else {
          setActivated(true);
          getChapters(user._id, token)
            .then((data) => {
              setChapters(data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setActivated(false);
      });
  };

  useEffect(() => {
    preload();
  }, []);

  const notActivated = () => {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <h2 className="text-primary">Awaiting Activation</h2>
        </div>
        <div className="d-flex justify-content-center">
          <img src="/images/scopeBanner.png" style={{ height: "30rem" }} />
        </div>
      </div>
    );
  };

  const header = (rowData) => (
    <img alt="Card" src={`${IMAGESPATH}/${rowData.photo}`} />
  );
  const footer = (rowData) => (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        label="Rate"
        icon="pi pi-check"
        className="p-button-outlined p-button-secondary"
      />
      <Button label="Continue" icon="pi pi-arrow-right" />
    </div>
  );

  const chapterItems = chapters.map((chapter, index) => {
    const counter = index+1;
    return(
    <div class="col-3 my-4" key={chapter._id}>
      
    <div class="card" >
      <div class="p-2 text-center bg-primary text-white">
            CHAPTER {counter}   
      </div>
      <img src={`${IMAGESPATH}/${chapter.photo}`} class="card-img-top" alt="..." />
      <div class="card-body text-center" style={{padding:"0rem"}}>
      <div class="p-2 text-center bg-primary text-white">
        <h6 class="card-title">
          Author<br/>
        <Avatar image={`${IMAGESPATH}/${chapter.author.photo}`} size="large"  shape="circle" /> <br />
          {`${chapter.author.salutation} ${chapter.author.firstname} ${chapter.author.lastname}`}
          <p class="card-text"> {`${chapter.author.designation}`}, {`${chapter.author.organisation} `}</p>
          </h6>
        
        <a href={chapter.author.scopelink} target="_blank" class="btn btn-light mb-1">Author Profile</a>
        <a href={`/user/chapter/${chapter._id}`} class="btn btn-light mb-1">Read Now</a>  
         <p>Estimated Reading Time: {chapter.readingTime.reduce((accumulator, currentValue) => accumulator + currentValue, 0)} minutes <br/>
              <small>
                Last updated <Timestamp date={chapter.updatedat} />{" "}
              </small>
            </p>
            </div>
      </div>
    </div>

     
    </div>
  )});

  return (
    <UserBase title="Scope Ebook Chapters">
      {activated && chapters && <div className="row">{chapterItems}</div>}
      {activated && chapters.length == 0 && (
        <div>
          <div className="d-flex justify-content-center">
            <h2 className="text-primary">Chapters Coming Soon</h2>
          </div>
          <div className="d-flex justify-content-center">
            <img src="/images/loading.png" style={{ height: "30rem" }} />
          </div>
        </div>
      )}
      {!activated && notActivated()}
    </UserBase>
  );
};

export default EbookChapters;
