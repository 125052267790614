import { redirect } from "react-router-dom"
import {API} from "../../backend"
import { useMsal } from "@azure/msal-react"


export const register = user => {
    console.log(user)
    return fetch(`${API}/register`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const login = user => {
    return fetch(`${API}/login`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const authenticate = (data,next) => {
    if(typeof window !== undefined){
        localStorage.setItem("jwt",JSON.stringify(data))
        next()
    }
}

export const logout = (next) => {
    if(typeof window !== undefined){
        localStorage.removeItem("jwt")
        next()
         return fetch(`${API}/logout`,{
            method:"GET"
        })
        .then((response) => {
            return redirect("/login")
    })
        .catch((err) => {
            console.log(err)
            return redirect("/login")
    })
    }
}

export const mslogout = () => {
    if(typeof window !== undefined){
        localStorage.removeItem("jwt")
         return fetch(`${API}/logout`,{
            method:"GET"
        })
        .then((response) => {
            return redirect("/login")
    })
        .catch((err) => {
            console.log(err)
            return redirect("/login")
    })
    }
}

export const isAuthenticated = () => {
    if(typeof window == undefined){
       return false
    }
    if(localStorage.getItem("jwt")){
        return JSON.parse(localStorage.getItem("jwt"))
    }else{
        return false
    }
}