import { API } from "../../backend";
import axios from "axios";

// ===================START OF PRICING(S) CRUD OPERATIONS================
export const getAllPublicPricings = () => {
    return fetch(`${API}/pricing/public/getAll/`,{
        method:"GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}
