import React, { useEffect, useState, useRef } from "react";
import AdminChapterTitlesBase from "../../core/admin/AdminChapterTitlesBase";
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { createChapterChecklist, deleteBulkChapterChecklist, deleteChapterChecklist, getAllChapterChecklists, getChapterChecklists, updateBulkChapterChecklists, updateChapterChecklist, uploadChapterChecklistBanner } from "../helper/adminapicalls";
import { isAuthenticated } from "../../auth/helper";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGESPATH } from "../../backend";
import { Dropdown } from "primereact/dropdown";
import { PickList } from "primereact/picklist";
import { Chips } from 'primereact/chips';
const ChapterChecklist = () => {
// =========================START OF DECLARATION OF VALUES==================
const { user, token } = isAuthenticated();
const chapterParams = useParams();
const chapterId = chapterParams.chapterId;
let navigate = useNavigate();

const checklistStatusOptions = ["Pending", "Released", "Hold"];

const [checklist, setChecklist] = useState({
    name: "",
    items: [
      { textDisplayed: "", link: "" } // Default item structure
    ],
    description: "",
    status: "",
    banner: "",
    uploadedBy: user._id,
    chapterId: chapterId,
    keywords: [],
    error: "",
    formData: new FormData(), // Assuming you want formData as an instance of FormData
  });
  

const { _id, name, items, description, status, banner,keywords, error, formData } =
  checklist;

  const itemProperties = items.map(item => ({ textDisplayed: item.textDisplayed, link: item.link }));


let emptyChecklist = {
    name: "",
    items: [
      { textDisplayed: "", link: "" } // Default item structure
    ],
    description: "",
    status: "",
    banner: "",
    uploadedBy: user._id,
    chapterId: chapterId,
    keywords: '',
    error: "",
    formData: new FormData(), // Assuming you want formData as an instance of FormData
};
const [file, setFile] = useState(null);

const [checklists, setChecklists] = useState([]);
const [checklistsListVisble, setChecklistsListVisble] = useState(true);

const [checklistDialog, setChecklistDialog] = useState(false);

const [deleteChecklistDialog, setDeleteChecklistDialog] = useState(false);

const [deleteChecklistsDialog, setDeleteChecklistsDialog] = useState(false);
const [reorderChecklistsDialog, setReorderChecklistsDialog] = useState(false);

const [selectedChecklists, setSelectedChecklists] = useState(null);
const [selectedAuthor, setSelectedAuthor] = useState(null);

const [submitted, setSubmitted] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);

//picklist
const [source, setSource] = useState([]);
const [target, setTarget] = useState([]);
//end of picklist

const toast = useRef(null);
const dt = useRef(null);
const fileUploader = useRef(null);
// =========================END OF DECLARATION OF VALUES==================

// =========================START OF PRELOAD ==================

const preload = () => {
  getAllChapterChecklists(user._id, token, chapterId)
    .then((data) => {
      if (data.error) {
        setChecklist({ ...checklist, error: data.error });
      } else {
        setSource(data);
        setChecklists(data);
        setChecklist({ ...checklist, formData: new FormData()});
      }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
  preload();
}, []);

// =========================END OF PRELOAD==================

const onPickListChange = (event) => {
  setSource(event.source);
  setTarget(event.target);
  let counter=0;
  event.target.forEach(item => {
    counter++;
    item.orderId = counter;
  });
  updateBulkChapterChecklists(user._id, token, event.target)
  .then((data) => {
    if (data.error) {
      setChecklist({
        ...checklist,
        error: data.error,
        formData: new FormData(),
      });
      toast.current.show({
        severity: "error",
        summary: "Checklist Not Updated",
        detail: data.error,
        life: 5000,
      });
    } else {
      preload();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Checklist Updated",
        life: 3000,
      });
    }
  })
  .catch((err) => console.log(err));

};

// ============START OF CUD OPERATIONS ==================
const saveChecklist = () => {
  setSubmitted(true);
  setChecklist({ ...checklist});
  console.log(checklist)
  if (!_id) {
    createChapterChecklist(user._id, token, checklist)
      .then((data) => {
        if (data.error) {
          setChecklist({
            ...checklist,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Checklist Not Created",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Checklist Created",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    updateChapterChecklist(user._id, token, _id, checklist)
      .then((data) => {
        if (data.error) {
          setChecklist({
            ...checklist,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Checklist Not Updated",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Checklist Updated",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  }
  setSelectedAuthor(null);
  setChecklistDialog(false);
  setChecklist(emptyChecklist);
};

const editChecklist = (checklist) => {
  setChecklist({ ...checklist });
  setSelectedAuthor(checklist.author);
  setChecklistDialog(true);
};

const deleteChecklistDB = () => {
  deleteChapterChecklist(user._id, token, _id)
    .then((data) => {
      if (data.error) {
        setChecklist({
          ...checklist,
          error: data.error,
          formData: new FormData(),
        });
        toast.current.show({
          severity: "error",
          summary: "Checklist Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteChecklistDialog(false);
        setChecklist(emptyChecklist);
      } else {
        preload();
        setChecklist(emptyChecklist);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Checklist Deleted",
          life: 3000,
        });
        setDeleteChecklistDialog(false);
        setChecklist(emptyChecklist);
      }
    })
    .catch((err) => console.log(err));
};

const deleteSelectedChecklists = () => {
  let checklists = selectedChecklists;
  const checklistIds = [];
  {
    Object.entries(checklists).map(([key, value]) =>
      checklistIds.push(checklists[key]._id)
    );
  }
  deleteBulkChapterChecklist(user._id, token, checklistIds)
    .then((data) => {
      if (data.error) {
        toast.current.show({
          severity: "error",
          summary: "Checklists Not Deleted",
          detail: data.error,
          life: 5000,
        });
        setDeleteChecklistsDialog(false);
      } else {
        preload();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Checklists Deleted",
          life: 3000,
        });
        setDeleteChecklistsDialog(false);
      }
    })
    .catch((err) => console.log(err));
};
// ============END OF CUD OPERATIONS ==================

// =========================START OF DIALOGS OPEN AND HIDE ==================
const openNewChecklist = () => {
  setChecklist(emptyChecklist);
  setSelectedAuthor(null);
  setSubmitted(false);
  setChecklistDialog(true);
};

const hideDialogChecklist = () => {
  setSubmitted(false);
  setChecklistDialog(false);
};

const hideDeleteChecklistDialog = () => {
  setDeleteChecklistDialog(false);
};

const hideDeleteChecklistsDialog = () => {
  setDeleteChecklistsDialog(false);
};

const hideReorderChecklistsDialog = () => {
  setReorderChecklistsDialog(false);
  setTarget([])
  setChecklistsListVisble(true);
};

// =========================END OF DIALOGS OPEN AND HIDE ==================

// ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
const checklistDialogFooter = (
  <React.Fragment>
    <Button
      label="Cancel"
      icon="pi pi-times"
      outlined
      onClick={hideDialogChecklist}
    />
    <Button label="Save" icon="pi pi-check" onClick={saveChecklist} />
  </React.Fragment>
);

const deleteChecklistDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteChecklistDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteChecklistDB}
    />
  </React.Fragment>
);

const deleteChecklistsDialogFooter = (
  <React.Fragment>
    <Button
      label="No"
      icon="pi pi-times"
      outlined
      onClick={hideDeleteChecklistsDialog}
    />
    <Button
      label="Yes"
      icon="pi pi-check"
      severity="danger"
      onClick={deleteSelectedChecklists}
    />
  </React.Fragment>
);

const confirmDeleteChecklist = (checklist) => {
  setChecklist(checklist);
  setDeleteChecklistDialog(true);
};

const confirmDeleteSelectedChecklists = () => {
  setDeleteChecklistsDialog(true);
};

const reorderChecklists = () => {
  setChecklistsListVisble(false);
  setReorderChecklistsDialog(true);
};

const actionBodyTemplateChecklist = (rowData) => {
  return (
    <React.Fragment>
      <Button
        icon="pi pi-pencil"
        rounded
        outlined
        className="mr-2"
        onClick={() => editChecklist(rowData)}
      />
      <Button
        icon="pi pi-trash"
        rounded
        outlined
        className="mr-2"
        severity="danger"
        onClick={() => confirmDeleteChecklist(rowData)}
      />
    </React.Fragment>
  );
};

// ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

// ============START OF EXPORT ==================
const cols = [
  { field: "_id", header: "ID" },
  { field: "name", header: "Checklist Name" },
  { field: "author.firstname", header: "Author Firstname" },
  { field: "author.lastname", header: "Author Lastname" },
  { field: "author.designation", header: "Author Designation" },
  { field: "author.organisation", header: "Author Organisation" },
];

const exportColumns = cols.map((col) => ({
  title: col.header,
  dataKey: col.field,
}));

const exportCSV = () => {
  dt.current.exportCSV();
};

const exportPdf = () => {
  import("jspdf").then((jsPDF) => {
    import("jspdf-autotable").then(() => {
      const doc = new jsPDF.default(0, 0);

      doc.autoTable(exportColumns, checklists);
      doc.save("checklists.pdf");
    });
  });
};

const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(checklists);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(excelBuffer, "checklists");
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};

// ============END OF EXPORT ==================

// ============START OF HANDLE CHANGE ==================
const handleChange = (name) => (event) => {
  const value = event.target.value;
  setChecklist({ ...checklist, [name]: value, orderId: checklists.length+1 });
};


const handleChangeItemText = (index, value) => {
  setChecklist((prevChecklist) => {
    const newItems = [...prevChecklist.items];
    newItems[index].textDisplayed = value;
    return { ...prevChecklist, items: newItems };
  });
};

const handleChangeItemLink = (index, value) => {
  setChecklist((prevChecklist) => {
    const newItems = [...prevChecklist.items];
    newItems[index].link = value;
    return { ...prevChecklist, items: newItems };
  });
};

const handleAddItem = () => {
  setChecklist((prevChecklist) => {
    return { ...prevChecklist, items: [...prevChecklist.items, { textDisplayed: "", link: "" }] };
  });
};

const handleRemoveItem = (index) => {
  setChecklist((prevChecklist) => {
    const newItems = [...prevChecklist.items];
    newItems.splice(index, 1);
    return { ...prevChecklist, items: newItems };
  });
};

const handleFileChange = ({ files }) => {
    setFile(files[0]);
  };

const handleFileUpload = (event) => {
    try {
      setSubmitted(false);
      const data = new FormData();
      data.append("banner", file, file.name);
      uploadChapterChecklistBanner(user._id, token, data).then((res) => {
        setChecklist({ ...checklist, banner: res.data.filename });
        fileUploader.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Photo Uploaded",
          life: 3000,
        });
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

// ============END OF HANDLE CHANGE ==================

// ============START OF TOOLBARS ==================
const leftToolbarTemplateChecklist = () => {
  return (
    <div className="flex flex-wrap gap-2">
      <Button
        label="New"
        icon="pi pi-plus"
        severity="success"
        className="m-2"
        onClick={openNewChecklist}
      />
      <Button
        label="Delete"
        icon="pi pi-trash"
        severity="danger"
        className="m-2"
        onClick={confirmDeleteSelectedChecklists}
        disabled={!selectedChecklists || !selectedChecklists.length}
      />

      <Button
        label="Reorder"
        icon="pi pi-arrows-v"
        severity="info"
        className="m-2"
        onClick={reorderChecklists}
      />
    </div>
  );
};

const rightToolbarTemplateChecklist = () => {
  return (
    <div className="flex align-items-center justify-content-end gap-2 float-end">
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        className="m-1"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        onClick={exportPdf}
        data-pr-tooltip="PDF"
      />
    </div>
  );
};

// ============END OF TOOLBARS ==================

// ============START OF STATUS SEVERITY ==================

const getSeverity = (checklist) => {
  switch (checklist.status) {
    case "Pending":
      return "info";

    case "Released":
      return "success";

    case "Hold":
      return "danger";

    default:
      return null;
  }
};

const statusBodyTemplate = (rowData) => {
  return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
};
// ============END OF STATUS SEVERITY ==================

// ============START OF TEMPLATEs ==================

const checklistBodyTemplate = (rowData) => {
  return (
    <div>
      <h5> {rowData.name}</h5>
    </div>
  );
};

const pickListItemTemplate = (checklist) => {
  return (
    <div className="d-flex flex-row p-2 align-items-center gap-3">
      <div className="flex-1 flex flex-column gap-2">
        <span className="font-bold">{checklist.name}</span>
       
      </div>
    </div>
  );
};

// ============START OF TEMPLATEs ==================

// ============START OF HEADER ==================

const header = (
  <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
    </span>
  </div>
);

// ============END OF HEADER ==================

return (
  <AdminChapterTitlesBase title="Checklists">
    <div>
        <div className="col-12">
            <h2 className="text-center text-primary">CHECKLISTS</h2>
        </div>
      <Toast ref={toast} />
      {/*  START OF DATA TABLE  */}
      {checklistsListVisble && (
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateChecklist}
            right={rightToolbarTemplateChecklist}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={checklists}
            selection={selectedChecklists}
            onSelectionChange={(e) => setSelectedChecklists(e.value)}
            dataKey="_id"
            paginator
            resizableColumns
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} checklists"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateChecklist}
              exportable={false}
              header="Actions"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              body={checklistBodyTemplate}
              header="Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="description"
              header="description"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
      )}
      {/*  END OF DATA TABLE  */}

      {/*  START OF CREATE & EDIT FORM   */}
      <Dialog
        visible={checklistDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Checklist Details"
        modal
        className="p-fluid"
        footer={checklistDialogFooter}
        onHide={hideDialogChecklist}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={name}
            onChange={handleChange("name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !name,
            })}
          />
          {submitted && !name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>

       
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={description}
            onChange={handleChange("description")}
            required
            rows={3}
            cols={20}
          />
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Status
          </label>

          <Dropdown
            value={status}
            onChange={handleChange("status")}
            options={checklistStatusOptions}
            placeholder="Select a Status"
            showClear
            className={classNames({
              "p-invalid": submitted && !status,
            })}
          />
          {submitted && !status && (
            <small className="p-error">Status is required.</small>
          )}
        </div>
        <div className="field mt-3">
            <label htmlFor="photo" className="font-bold">
              Banner
            </label>
            <br />
            {banner && (
              <img
                src={`${IMAGESPATH}/${banner}`}
                alt={banner}
                style={{ height: 200, width: 200, margin: "auto" }}
                className="product-image block m-auto pb-3"
              />
            )}
            <FileUpload
              name="banner"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !banner && (
              <small className="p-error">Banner is required.</small>
            )}
          </div>
       
          <div className="field">
    <label htmlFor="items" className="font-bold">
      Items
    </label>
    <div className="p-col">
      <table className="p-table p-component">
        <thead>
          <tr>
            <th>Text Displayed</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {checklist.items.map((item, index) => (
            <tr key={index}>
              <td>
                <InputText
                  value={item.textDisplayed}
                  onChange={(e) => handleChangeItemText(index, e.target.value)}
                  placeholder="Text Displayed"
                />
              </td>
              <td>
                <InputText
                  value={item.link}
                  onChange={(e) => handleChangeItemLink(index, e.target.value)}
                  placeholder="Link"
                />
              </td>
              <td>
                <Button label="Remove" onClick={() => handleRemoveItem(index)} className="btn-danger" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Button label="Add Item" onClick={handleAddItem} className="btn-success"/>
  </div>
        

        <div className="field">
          <label htmlFor="keywords" className="font-bold">
            Keywords
          </label>

          <Chips value={keywords} onChange={handleChange("keywords")} />

          
          {submitted && !keywords && (
            <small className="p-error">Keywords is required.</small>
          )}
        </div>

        
      </Dialog>
      {/*  END OF CREATE & EDIT FORM   */}
      {/*  START OF DELETE FORM   */}
      <Dialog
        visible={deleteChecklistDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteChecklistDialogFooter}
        onHide={hideDeleteChecklistDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {checklist && (
            <span>
              Are you sure you want to delete <b>{name}</b> checklist?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF DELETE FORM   */}

      {/*  START OF MULTIPLE DELETE FORM   */}
      <Dialog
        visible={deleteChecklistsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteChecklistsDialogFooter}
        onHide={hideDeleteChecklistsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {checklist && (
            <span>
              Are you sure you want to delete the selected checklists?
            </span>
          )}
        </div>
      </Dialog>
      {/*  END OF MULTIPLE DELETE FORM   */}

      {/*  START OF REORDER CHAPTER FORM   */}

      {reorderChecklistsDialog && (
        <div className="card p-4">
          <div className="row m-3">
            <div className="col-6 text-left">
            <h2 className="text-primary">Reorder Checklists List</h2>
            </div>
            <div className="col-6 text-right">
              
              <Button
                label="Close Reorder"
                className=""
                onClick={hideReorderChecklistsDialog}
              />
            </div>
          </div>
          <PickList
            source={source}
            target={target}
            onChange={onPickListChange}
            itemTemplate={pickListItemTemplate}
            filter
            filterBy="name"
            breakpoint="1400px"
            sourceHeader="Available"
            targetHeader="Selected"
            showSourceControls={false}
            sourceStyle={{ height: "24rem" }}
            targetStyle={{ height: "24rem" }}
            sourceFilterPlaceholder="Search by name"
            targetFilterPlaceholder="Search by name"
          />
        </div>
      )}

      {/*  END OF REORDER CHAPTER FORM   */}
    </div>
  </AdminChapterTitlesBase>
);
}

export default ChapterChecklist