import React, { useCallback, useEffect, useState } from "react";
import UserBase from "../core/user/UserBase";
import { useNavigate, useParams } from "react-router-dom";
import { isAuthenticated } from "../auth/helper";
import {
  ebookActivationStatus,
  getChapter,
  getChapterAccessories,
  getChapterChecklists,
  getChapterProcedures,
  getChapterReferences,
  getChapterTitles,
} from "./helper/userapicalls";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { SpeedDial } from "primereact/speeddial";
import { SelectButton } from "primereact/selectbutton";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Galleria } from "primereact/galleria";
import { classNames } from "primereact/utils";
import { IMAGESPATH } from "../backend";
import { FRONTAPP } from "../backend";
import { Avatar } from "primereact/avatar";
import { ScrollPanel } from "primereact/scrollpanel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tooltip } from "primereact/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { Sidebar } from "primereact/sidebar";
import { Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";

const Chapter = () => {
  const [activatedPromoCode, setActivatedPromoCode] = useState(false);
  const [activatedPromoCodeMessage, setActivatedPromoCodeMessage] =
    useState(false);

  let navigate = useNavigate();
  const { user, token } = isAuthenticated();
  const chapterParams = useParams();
  const chapterId = chapterParams.chapterId;

  const [chapter, setChapter] = useState([]);
  const [chapterTitles, setChapterTitles] = useState([]);
  const [chapterBanners, setChapterBanners] = useState([]);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);

  const [references, setReferences] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [procedures, setProcedures] = useState([]);

  //  AUDIO CONTROLS AND LOGIC
  const [voices, setVoices] = useState([]);
  const [voicesDropDown, setVoicesDropDown] = useState("");
  const [voiceGender, setVoiceGender] = useState("Aaron"); // 'male', 'female', 'neutral'
  const [speechSpeed, setSpeechSpeed] = useState(1.0); // Adjust as needed
  const [selectedSpeed, setSelectedSpeed] = useState(1); // Default speed

  const [selectedAudioOption, setSelectedAudioOption] = useState(null);
  const [paused, setPaused] = useState(false);
  const [text, setText] = useState("");
  const [speaking, setSpeaking] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const synth = window.speechSynthesis;
  const utterance = useRef(new SpeechSynthesisUtterance());
  const [highlightedWord, setHighlightedWord] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    utterance.current.text = text;
  }, [text]);

  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault();
      alert('Copying text is not allowed.');
    };

    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);

  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault();
      alert('Copying text is not allowed.');
    };

    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);

  const elementRef = useRef(null);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const element = elementRef.current;
    if (element) {
      element.addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (element) {
        element.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);
  

  const handlePlay = () => {
    const divElement = document.getElementById("audioContent"); // Replace 'yourDivId' with the actual ID of your div
    const readingText = extractTextFromDiv(divElement) || ""; // Extract text from the div or use an empty string if it's null/undefined

    utterance.current.text = text;
    utterance.current.rate = speechSpeed; // Set the speech rate

    // Select the voice by name (change 'Aaron' to the desired voice name)
    const selectedVoice = voices.find((voice) => voice.name === voiceGender);

    if (selectedVoice) {
      utterance.current.voice = selectedVoice;
    } else {
      // Fallback: Use the default voice if the selected voice is not available
      utterance.current.voice = voices.find((voice) => voice.default);
    }

    if (!paused) {
      setStartTime(Date.now());
      setProgress(0); // Reset progress to start
      setHighlightedWord(null); // Reset highlighted word
      setText(readingText); // Set the text for synthesis
      utterance.current.text = readingText; // Set the text for synthesis

      synth.speak(utterance.current);
      setSpeaking(true);

      utterance.current.addEventListener("boundary", (event) => {
        const charIndex = event.charIndex;
        const totalChars = readingText.length;

        // Calculate progress as a percentage
        const currentProgress = (charIndex / totalChars) * 100;
        setProgress(currentProgress >= 100 ? 100 : currentProgress);

        // Check if the speech has completed
        if (currentProgress >= 100) {
          setEndTime(Date.now());
        }
      });
    } else {
      synth.resume(); // Resume playback if paused
      setSpeaking(true);
      setPaused(false);
    }
  };
  const handleStop = () => {
    synth.cancel();
    setSpeaking(false);
    setPaused(false); // Clear the paused state
    setEndTime(Date.now());
    setHighlightedWord(null);
    setProgress(0); // Reset progress to start
    setStartTime(null); // Reset start time
  };
  const handlePause = () => {
    if (speaking) {
      synth.pause();
      setSpeaking(false);
      setEndTime(Date.now());
      setPaused(true); // Add a new state to indicate that it's paused
    }
  };


  useEffect(() => {
    
    const handleVoicesChanged = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      console.log(
        "Available Voices:",
        voices.map((voice) => voice.name)
      );
    };

    window.speechSynthesis.addEventListener(
      "voiceschanged",
      handleVoicesChanged
    );
    handleVoicesChanged();

    return () => {
      window.speechSynthesis.removeEventListener(
        "voiceschanged",
        handleVoicesChanged
      );
    };
  }, []);

  const voiceItems = voices.map((voice, index) => ({
    label: voice.name,
    command: () => setVoiceGender(voice.name),
  }));
  const items = [...voiceItems];
  //  END OF AUDIO CONTROLS AND LOGIC
  useEffect(() => {
    preload();
    if (speaking) {
      utterance.current.addEventListener("boundary", (event) => {
        const charIndex = event.charIndex;
        const totalChars = text.length;

        // Calculate progress as a percentage
        const currentProgress = (charIndex / totalChars) * 100;
        setProgress(currentProgress);

        // Find the word that contains the character at charIndex
        const words = text.split(/\s+/);
        const currentWord = words.find(
          (word) =>
            charIndex >= text.indexOf(word) &&
            charIndex < text.indexOf(word) + word.length
        );

        // Find the index of the currentWord
        const currentIndex = words.indexOf(currentWord);
        setHighlightedWord(currentIndex);
      });

      utterance.current.addEventListener("end", () => {
        setSpeaking(false);
        setEndTime(Date.now());
        setProgress(100);
      });
    }
  }, [speaking, text]);

  const handleGenderChange = (event) => {
    setVoiceGender(event.target.value);
  };

  const handleSpeedChange = (event) => {
    setSpeechSpeed(parseFloat(event.target.value));
  };

  const handleAudioOptionChange = (e) => {
    const selectedValue = e.value;

    // Handle the click event for each option
    switch (selectedValue) {
      case "play":
        handlePlay();
        break;
      case "pause":
        handlePause();
        break;
      case "stop":
        handleStop();
        break;
      default:
        break;
    }

    // Update the selected option
    setSelectedAudioOption(selectedValue);
  };

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  //  START OF BANNER

  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showThumbnails, setShowThumbnails] = useState(false);
  const [isAutoPlayActive, setAutoPlayActive] = useState(true);
  const [isFullScreen, setFullScreen] = useState(false);

  const galleria = useRef(null);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "960px",
      numVisible: 4,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    setAutoPlayActive(galleria.current.isAutoPlayActive());
  }, [isAutoPlayActive]);

  const onItemChange = (event) => {
    setActiveIndex(event.index);
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      closeFullScreen();
    } else {
      openFullScreen();
    }
  };

  const onFullScreenChange = () => {
    setFullScreen((prevState) => !prevState);
  };

  const openFullScreen = () => {
    let elem = document.querySelector(".custom-galleria");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const closeFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const bindDocumentListeners = () => {
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    document.addEventListener("msfullscreenchange", onFullScreenChange);
  };

  const unbindDocumentListeners = () => {
    document.removeEventListener("fullscreenchange", onFullScreenChange);
    document.removeEventListener("mozfullscreenchange", onFullScreenChange);
    document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
    document.removeEventListener("msfullscreenchange", onFullScreenChange);
  };

  const thumbnailTemplate = (item) => {
    return (
      <div className="grid grid-nogutter justify-content-center">
        <img
          src={item.thumbnailImageSrc}
          alt={item.alt}
          style={{ display: "block" }}
        />
      </div>
    );
  };

  const itemTemplate = (item) => {
    if (isFullScreen) {
      return <img src={item.path} alt={item.imagename} />;
    }

    return (
      <img
        src={item.path}
        alt={item.imagename}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const renderFooter = () => {
    let autoPlayClassName = classNames("pi", {
      "pi-play": !isAutoPlayActive,
      "pi-pause": isAutoPlayActive,
    });

    let fullScreenClassName = classNames("pi", {
      "pi-window-maximize": !isFullScreen,
      "pi-window-minimize": isFullScreen,
    });

    return <div className="custom-galleria-footer"></div>;
  };

  const footer = renderFooter();
  const galleriaClassName = classNames("custom-galleria", {
    fullscreen: isFullScreen,
  });

  // END OF BANNER

  const preload = () => {
    //checking valid license before admitting the user inside chapters
    ebookActivationStatus(user._id, token)
      .then((data) => {
        if (data.error) {
          setActivatedPromoCodeMessage(data.error);
          setActivatedPromoCode(false);
        } else {
          setActivatedPromoCode(data);
          getChapter(user._id, token, chapterId)
            .then((data) => {
              setChapter(data);
              if (data && data.banners) {
                const updatedImages = data.banners.map((banner) => ({
                  imageName: banner.imageName,
                  path: `${IMAGESPATH}/${banner.imageName}`,
                }));
                setImages(updatedImages);
              }
              getChapterTitles(user._id, token, chapterId)
                .then((chapterContents) => {
                  if (chapterContents.error) {
                    toast.current.show({
                      severity: "error",
                      summary: "Something Went Wrong",
                      detail: chapterContents.error,
                      life: 5000,
                    });
                    sleep(5000).then(() => {
                      navigate("/user/chapters");
                    });
                  } else {
                    setChapterTitles(chapterContents);
                  }
                })
                .catch((err) => {
                  toast.current.show({
                    severity: "error",
                    summary: "Something Went Wrong",
                    detail: "Cannot Retrieve the Chapter Contents ",
                    life: 5000,
                  });
                  sleep(5000).then(() => {
                    navigate("/user/chapters");
                  });
                });
            })
            .catch((err) => {
              toast.current.show({
                severity: "error",
                summary: "Something Went Wrong",
                detail: "Cannot Retrieve the Chapter Contents ",
                life: 5000,
              });
              sleep(5000).then(() => {
                navigate("/user/chapters");
              });
            });

          getChapterAccessories(user._id, token, chapterId)
            .then((data) => {
              setAccessories(data);
              console.log(data);
            })
            .catch((err) => console.log(err));

          getChapterChecklists(user._id, token, chapterId)
            .then((data) => {
              setChecklists(data);
              console.log(data);
            })
            .catch((err) => console.log(err));

          getChapterReferences(user._id, token, chapterId)
            .then((data) => {
              setReferences(data);
              console.log(data);
            })
            .catch((err) => console.log(err));

          getChapterProcedures(user._id, token, chapterId)
            .then((data) => {
              setProcedures(data);
              console.log(data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        setActivatedPromoCode(false);
      });
  };

  const replaceImage = (contentEditor) => {
    let haystack = contentEditor;
    let needle = "%i";
    let pos = 0; // Position Ref
    let imageIndexes = []; // Final output of all index's.

    let hayStackLower = haystack != "" ? haystack.toLowerCase() : "";
    // Loop to check all occurrences
    while (hayStackLower.indexOf(needle, pos) != -1) {
      imageIndexes.push(hayStackLower.indexOf(needle, pos));
      pos = hayStackLower.indexOf(needle, pos) + 1;
    }
    const finalImageIndexes = [];
    while (imageIndexes.length)
      finalImageIndexes.push(imageIndexes.splice(0, 2));
    let finalReplacedContentEditor = contentEditor;
    finalImageIndexes.forEach((item) => {
      let imageurl = contentEditor.substring(item[0] + 2, item[1]);
      finalReplacedContentEditor = finalReplacedContentEditor.replace(
        /%i(.*?)%i/,
        `<div class="ql-align-center"><img src="${imageurl}" width="450"/></div>`
      );
    });
    return finalReplacedContentEditor;
  };

  const replaceVideo = (contentEditor) => {
    let haystack = contentEditor;
    let needle = "%v";
    let pos = 0; // Position Ref
    let imageIndexes = []; // Final output of all index's.

    let hayStackLower = haystack != "" ? haystack.toLowerCase() : "";
    // Loop to check all occurrences
    while (hayStackLower.indexOf(needle, pos) != -1) {
      imageIndexes.push(hayStackLower.indexOf(needle, pos));
      pos = hayStackLower.indexOf(needle, pos) + 1;
    }
    const finalImageIndexes = [];
    while (imageIndexes.length)
      finalImageIndexes.push(imageIndexes.splice(0, 2));
    let finalReplacedContentEditor = contentEditor;
    finalImageIndexes.forEach((item) => {
      let videourl = contentEditor.substring(item[0] + 2, item[1]);
      finalReplacedContentEditor = finalReplacedContentEditor.replace(
        /%v(.*?)%v/,
        `<iframe src="${videourl}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
      );
    });
    return finalReplacedContentEditor;
  };

  const audioOptions = [
    { icon: "pi pi-play", value: "play" },
    { icon: "pi pi-pause", value: "pause" },
    { icon: "pi pi-stop", value: "stop" },
  ];

  const speedOptions = [
    { label: "0.5x", value: 0.5 },
    { label: "0.8x", value: 0.8 },
    { label: "1x", value: 1 },
    { label: "1.3x", value: 1.3 },
    { label: "1.5x", value: 1.5 },
  ];
  const speedItems = speedOptions.map((option) => ({
    label: option.label,
    command: () => setSpeechSpeed(parseFloat(option.value)),
  }));
  const speedButtonItems = [...speedItems];
  const audioTemplate = (option) => {
    return <i className={option.icon}></i>;
  };
  const extractTextFromDiv = (divElement) => {
    return Array.from(divElement.childNodes)
      .map((child) => child.innerText)
      .join(" ");
  };
  const bannerSection = (
    <Splitter style={{ padding: 0 }}>
      <SplitterPanel
        className="flex align-items-center justify-content-center"
        size={50}
        minSize={50}
      >
        <div className="col-12 bg-primary text-white p-1 text-center">
          AUTHOR INFO
        </div>
        <Galleria
          ref={galleria}
          value={images}
          activeIndex={activeIndex}
          onItemChange={onItemChange}
          showThumbnails={showThumbnails}
          showItemNavigators
          showItemNavigatorsOnHover
          numVisible={5}
          circular
          autoPlay
          transitionInterval={3000}
          responsiveOptions={responsiveOptions}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          footer={footer}
          style={{ maxWidth: "640px" }}
          className={galleriaClassName}
        />
      </SplitterPanel>
      <SplitterPanel
        className="flex align-items-center justify-content-center"
        size={50}
        minSize={50}
      >
        <div className="col-12 bg-primary text-white p-1 text-center">
          CHAPTER INFO
        </div>
        <Galleria
          ref={galleria}
          value={images}
          activeIndex={activeIndex}
          onItemChange={onItemChange}
          showThumbnails={showThumbnails}
          showItemNavigators
          showItemNavigatorsOnHover
          numVisible={5}
          circular
          autoPlay
          transitionInterval={3000}
          responsiveOptions={responsiveOptions}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          footer={footer}
          style={{ maxWidth: "640px" }}
          className={galleriaClassName}
        />
      </SplitterPanel>
    </Splitter>
  );

  const headerControls = chapterTitles.map((chapterItem) => (
    <div className="m-2">
      <div className="row">
        <div className="col-md-3 col-sm-12 mb-2 text-center">
          <Avatar
            image={`${IMAGESPATH}/${chapter.author.photo}`}
            size="large"
            shape="circle"
          />
          <div className="mt-2">
            <small>
              {`${chapter.author.salutation} ${chapter.author.firstname} ${chapter.author.lastname}`}{" "}
              <br />
              {`${chapter.author.designation}, ${chapter.author.organisation}`}
            </small>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 mb-2 text-center">
          <div className="d-flex justify-content-between mb-2">
            <SplitButton
              label={voiceGender || "Select Voice"}
              icon="pi pi-microphone"
              model={items}
              size="small"
              className="float-left"
              severity="help"
            />
            <SelectButton
              value={selectedAudioOption}
              onChange={handleAudioOptionChange}
              itemTemplate={audioTemplate}
              optionLabel="Play"
              options={audioOptions}
              className="float-left"
            />
            <SplitButton
              label={`Speed: ${speechSpeed}x`}
              icon="pi pi-clock"
              model={speedButtonItems}
              size="small"
              className="float-left"
              severity="help"
            />
            <Tooltip target=".tooltip-target" content="Reading Mode" />
            <Button
              icon={() => <FontAwesomeIcon icon={faExpand} />}
              onClick={() => setVisible(true)}
              className="tooltip-target"
            />
          </div>
          <div className="text-right">
            <small>Reading Time: {chapterItem.readingTime} Mins</small>
          </div>
          <progress value={progress} className="w-100" max={100}></progress>
        </div>
      </div>
    </div>
  ));
  const chapterItems = (height) => {
    return chapterTitles.map((chapterItem) => (
      <div className="demoPage">
        <ScrollPanel
          style={{ width: "100%", height: height || "60vh" }}
          key={chapterItem._id}
          id="audioContent"
        >
          <Editor
            value={chapterItem.heading}
            showHeader={false}
            style={{ border: 0 }}
            readOnly={true}
          />
          <Editor
            value={chapterItem.subheading}
            showHeader={false}
            style={{ border: 0 }}
            readOnly={true}
          />
          <Editor
            value={replaceVideo(replaceImage(chapterItem.content))}
            readOnly={true}
            showHeader={false}
            style={{ border: 0 }}
          />
        </ScrollPanel>
      </div>
    ));
  };

  const procedureTemplate = (procedure) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img
            src={`${IMAGESPATH}/${procedure.photo}`}
            alt={procedure.name}
            className="w-6 shadow-2"
          />
        </div>
        <div>
          <h4 className="mb-1">{procedure.name}</h4>

          <Tag value={procedure.status}></Tag>
          <div className="mt-5 flex flex-wrap gap-2 justify-content-center">
            <Button icon="pi pi-search" className="p-button p-button-rounded" />
            <Button
              icon="pi pi-star-fill"
              className="p-button-success p-button-rounded"
            />
          </div>
        </div>
      </div>
    );
  };

  // ACCESSORY TEMPLATE UI
  const [selectedAccessory, setSelectedAccessory] = useState(null);

  const handleAccessoryButtonClick = useCallback(
    (accessory) => {
      setSelectedAccessory(accessory);
    },
    [setSelectedAccessory]
  );

  const handleAccessoryDialogHide = () => {
    setSelectedAccessory(null);
  };
  const accessoryImageTemplate = (item) => {
    return (
      <img src={`${IMAGESPATH}/${item}`} alt={item} style={{ width: "100%" }} />
    );
  };
  const AccessoryTemplate = ({ accessory }) => (
    <div>
      <button
        onClick={() => handleAccessoryButtonClick(accessory)}
        className="border-1 surface-border bg-primary rounded-2 border-round m-2 text-center py-2 px-3"
      >
        <div className="mb-3">
          <img
            src={`${IMAGESPATH}/${accessory.images[0]}`}
            alt={accessory.name}
            className="w-6 shadow-2"
            style={{ height: "auto", width: "20%" }}
          />
        </div>
        <div>
          <h4 className="mb-1 text-white">{accessory.name}</h4>
        </div>
      </button>

      {selectedAccessory === accessory && (
        <Dialog
          header={`Accessory - ${
            selectedAccessory ? selectedAccessory.name : ""
          }`}
          visible={true}
          style={{ width: "50vw" }}
          onHide={handleAccessoryDialogHide}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tbody>
              <tr>
                <td
                  colSpan="2"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#eee",
                    padding: "10px",
                  }}
                >
                  <h3>{accessory.name}</h3>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>Description:</strong> {accessory.description}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>Images:</strong>
                  <Galleria
                    value={accessory.images}
                    style={{ maxWidth: "350px" }}
                    showThumbnails={false}
                    changeItemOnIndicatorHover
                    showIndicators
                    item={accessoryImageTemplate}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Companies:</strong>
                  <ul>
                    {accessory.companies.map((company) => (
                      <li key={company._id.$oid}>
                        {company.name} -{" "}
                        <a href={company.url} rel="noreferrer" target="_blank">
                          {company.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <strong>Options:</strong>
                  <ul>
                    {accessory.options.map((option) => (
                      <li key={option._id.$oid}>
                        {option.optionname}: {option.optionitems.join(", ")}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Links:</strong>
                  <ul>
                    {accessory.links.map((link) => (
                      <li key={link._id.$oid}>
                        {link.name} -{" "}
                        <a href={link.url} rel="noreferrer" target="_blank">
                          {link.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <strong>Videos:</strong>
                  <ul>
                    {accessory.videolinks.map((link) => (
                      <li key={link._id.$oid}>
                        {link.name} -{" "}
                        <a href={link.url} rel="noreferrer" target="_blank">
                          {link.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Procedures:</strong>
                  <ul>
                    {accessory.procedures.map((procedure) => (
                      <li key={procedure._id}>
                        <a
                          href={`${FRONTAPP}/procedure/${procedure._id}`}
                          target="_blank"
                        >
                          {procedure.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <strong>Chapters:</strong>
                  <ul>
                    {accessory.chapters.map((chapter) => (
                      <li key={chapter._id}>
                        <a
                          href={`${FRONTAPP}/user/chapter/${chapter._id}`}
                          target="_blank"
                        >
                          {chapter.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </Dialog>
      )}
    </div>
  );
  // END OF ACCESSORY TEMPLATE UI

  // PROCEDURE TEMPLATE UI
  const [selectedProcedure, setSelectedProcedure] = useState(null);

  const handleProcedureButtonClick = useCallback(
    (procedure) => {
      setSelectedProcedure(procedure);
    },
    [setSelectedProcedure]
  );

  const handleProcedureDialogHide = () => {
    setSelectedProcedure(null);
  };
  const procedureImageTemplate = (item) => {
    return (
      <img src={`${IMAGESPATH}/${item}`} alt={item} style={{ width: "100%" }} />
    );
  };
  const ProcedureTemplate = ({ procedure }) => (
    <div>
      <button
        onClick={() => handleProcedureButtonClick(procedure)}
        className="border-1 surface-border bg-primary rounded-2 border-round m-2 text-center py-2 px-3"
      >
        <div className="mb-3">
          <img
            src={`${IMAGESPATH}/${procedure.photo}`}
            alt={procedure.name}
            className="w-6 shadow-2"
            style={{ height: "auto", width: "20%" }}
          />
        </div>
        <div>
          <h4 className="mb-1 text-white">{procedure.name}</h4>
        </div>
      </button>

      {selectedProcedure === procedure && (
        <Dialog
          header={`Procedure - ${
            selectedProcedure ? selectedProcedure.name : ""
          }`}
          visible={true}
          style={{ width: "50vw" }}
          onHide={handleProcedureDialogHide}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td colSpan="2">
                  <p className="m-0">{procedure.name}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>Description:</strong> {procedure.description}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>References:</strong>
                  <ul>
                    {procedure.references.map((reference) => (
                      <li key={reference._id}>
                        {reference.name} -{" "}
                        <a
                          href={reference.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {reference.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <strong>Chapters:</strong>
                  <ul>
                    {procedure.chapters.map((chapter) => (
                      <li key={chapter._id}>
                        <a
                          href={`${FRONTAPP}/user/chapter/${chapter._id}`}
                          target="_blank"
                        >
                          {chapter.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Accessories:</strong>
                  <ul>
                    {procedure.accessories.map((accessory) => (
                      <li key={accessory._id}>
                        <a
                          href={`${FRONTAPP}/user/chapter/${chapter._id}`}
                          target="_blank"
                        >
                          {accessory.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <strong>Checklists:</strong>
                  <ul>
                    {procedure.checklist.map((checklist) => (
                      <li key={checklist._id}>
                        <a
                          href={`${FRONTAPP}/user/checklist/${checklist._id}`}
                          target="_blank"
                        >
                          {checklist.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </Dialog>
      )}
    </div>
  );
  // END OF PROCEDURE TEMPLATE UI

  // REFERENCES TEMPLATE UI
  const [selectedReference, setSelectedReference] = useState(null);

  const handleReferenceButtonClick = useCallback(
    (reference) => {
      setSelectedReference(reference);
    },
    [setSelectedReference]
  );

  const handleReferenceDialogHide = () => {
    setSelectedReference(null);
  };
  const referenceImageTemplate = (item) => {
    return (
      <img src={`${IMAGESPATH}/${item}`} alt={item} style={{ width: "100%" }} />
    );
  };
  const ReferenceTemplate = ({ reference }) => (
    <div>
      <ul>
        <li>
          {reference.name} - {reference.link} - {reference.description}
        </li>
      </ul>
    </div>
  );
  // END OF REFERENCES TEMPLATE UI

  const referenceItems = (
    <Accordion activeIndex={0}>
      {/* REFERENCE ACCORDION */}
      {references && references.length >= 1 && (
        <AccordionTab header="References">
          <p className="m-0">
            <div>
              <ol type="1">
                {references.map((reference) => (
                  <li key={reference.id}>
                    {reference.name} -{" "}
                    <a href={reference.link} target="_blank" rel="noreferrer">
                      {reference.link}
                    </a>{" "}
                    <br />
                    Description : {reference.description} <br />
                    Keywords : {reference.keywords}
                    <br />
                    Video : {reference.videourl}
                    <br />
                    Image : {reference.imageurl}
                    <br />
                    Type : {reference.type}
                  </li>
                ))}
              </ol>
            </div>
          </p>
        </AccordionTab>
      )}
      {/* END OF REFERENCE ACCORDION */}

      {/* CHECKLIST ACCORDION */}
      {checklists && checklists.length >= 1 && (
        <AccordionTab header="Checklists">
          <p className="m-0">
            <div>
              <ol type="1">
                {checklists.map((checklist) => (
                  <li key={checklist._id}>
                    <a
                      href={`${FRONTAPP}/user/checklist/${checklist._id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {checklist.name}
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </p>
        </AccordionTab>
      )}
      {/* END OF CHECKLIST ACCORDION */}

      {/* ACCESSORY ACCORDION */}
      {accessories && accessories.length >= 1 && (
        <AccordionTab header="Accessories">
          <p className="m-0">
            <Carousel
              value={accessories}
              numVisible={3}
              numScroll={3}
              responsiveOptions={responsiveOptions}
              className="custom-carousel"
              circular
              autoplayInterval={3000}
              itemTemplate={(accessory) => (
                <AccessoryTemplate accessory={accessory} />
              )}
            />
          </p>
        </AccordionTab>
      )}
      {/* END OF ACCESSORY ACCORDION */}

      {/* PROCEDURE ACCORDION */}
      {procedures && procedures.length >= 1 && (
        <AccordionTab header="Procedures">
          <p className="m-0">
            <Carousel
              value={procedures}
              numVisible={3}
              numScroll={3}
              responsiveOptions={responsiveOptions}
              className="custom-carousel"
              circular
              autoplayInterval={3000}
              itemTemplate={(procedure) => (
                <ProcedureTemplate procedure={procedure} />
              )}
            />
          </p>
        </AccordionTab>
      )}
      {/* END OF PROCEDURE ACCORDION */}
    </Accordion>
  );

  return (
    <UserBase title={chapter.name}>
      <Toast ref={toast} />
   
      {!activatedPromoCode && activatedPromoCodeMessage && (
        <div className="col-12">
          <div className="offset-3 col-6 alert-danger text-center">
            <h2>{activatedPromoCodeMessage}</h2>
          </div>
        </div>
      )}
      <div className="row no-select no-copy" >
        {/* FULL SCREEN MODE */}
        <div className="card flex justify-content-center">
          <Sidebar
            visible={visible}
            onHide={() => setVisible(false)}
            fullScreen
          >
            <h2 className="text-center text-primary">{chapter.name}</h2>
            {chapterItems("80vh")}
          </Sidebar>
        </div>
        {/* END OF FULL SCREEN MODE */}
        {bannerSection}
        {headerControls}
        {chapterItems("60vh")}
        {referenceItems}
      </div>

      {/* end of activatedpromocode */}
    </UserBase>
  );
};

export default Chapter;
