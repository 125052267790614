import React, { useEffect, useState } from "react";
import AdminMenu from "./AdminMenu";
import AdminTopMenu from "./AdminTopMenu";
import { Helmet } from "react-helmet";
import { isAuthenticated, logout, mslogout } from "../../auth/helper";
import { NavLink, useNavigate } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { useMsal } from "@azure/msal-react";

const AdminBase = ({ title = "Dashboard", children }) => {
  let navigate = useNavigate()
  
  const [homeAccountId, setHomeAccountId] = useState('')

  const { instance } = useMsal();


  // const handleLogout = async () => {
  //   const logoutRequest = {
  //     account: instance.getAccountByHomeId(homeAccountId),
  //     postLogoutRedirectUri: "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:3000/login"
  //   };
  //   try {
  //     navigate("/login")
  //     await instance.logoutPopup(logoutRequest);
  //     logout()
  //   } catch (error) {
  //     // Handle logout failure
      
  //     console.log(error)
  //     console.log("MSLogin Failure")
  //   }
    
  //}

  function gLogout() {
    googleLogout();
    logout()
    navigate("/login")
};

 
  function handleLogout() {
      const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:3000/login"
      };
      try {
        instance.logoutPopup(logoutRequest);
        window.location.href="/login"
        mslogout()
      } catch (error) {
        console.log(error)
        console.log("MSLogin Failure")
      }
      
    }

  const getHomeAccountIdFromCache = () => {
    const allAccounts = instance.getAllAccounts(); // Get all accounts from cache
    if (allAccounts.length > 0) {
      const firstAccount = allAccounts[0]; // Assuming you want to get the homeAccountId of the first account
      return firstAccount.homeAccountId;
    }
    return null; // No accounts found in cache
  };

  useEffect(() => {
      setHomeAccountId(getHomeAccountIdFromCache());
  }, [])
  
  const {
        user: { firstname, lastname, email, role }
      } = isAuthenticated();

  return (
    <div>
      <Helmet>
        <link
          href="/adminAssets/vendor/fontawesome-free/css/all.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
          rel="stylesheet"
        />
        <link href="/adminAssets/css/sb-admin-2.min.css" rel="stylesheet" />
        
        
        <script src="/adminAssets/vendor/jquery/jquery.min.js"></script>
        <script src="/adminAssets/vendor/bootstrap/js/bootstrap.js"></script>

      </Helmet>
      <div
        id="wrapper"
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          position: "fixed",
          overflowY: "auto",
        }}
      >
        {AdminMenu()}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {AdminTopMenu({firstname,lastname})}

            <div class="container-fluid">
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">{title}</h1>
                <a
                  href="#"
                  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                  <i class="fas fa-download fa-sm text-white-50"></i> Generate
                  Report
                </a>



              </div>
              {children}
            </div>
          </div>
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Scope Health UK 2024 | Powered By Maylancer Techios Pvt Ltd</span>
              </div>
            </div>
          </footer>
         
       
   

    <script src="/adminAssets/vendor/jquery-easing/jquery.easing.min.js"></script>

    <script src="/adminAssets/js/sb-admin-2.min.js"></script>

    <script src="/adminAssets/vendor/chart.js/Chart.min.js"></script>

    <script src="/adminAssets/js/demo/chart-area-demo.js"></script>
    <script src="/adminAssets/js/demo/chart-pie-demo.js"></script> 
    
        </div>
      </div>

      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
      </a>

      <div
        class="modal fade"
        id="logoutModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                class="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <NavLink className="btn btn-primary" onClick={logout}>
                Logout
              </NavLink>
              <NavLink className="btn btn-primary" onClick={handleLogout}>
                MS Logout
              </NavLink>
              <NavLink className="btn btn-primary" onClick={gLogout}>
                G Logout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBase;
