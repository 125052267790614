import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../../core/admin/AdminBase";
import { isAuthenticated } from "../../auth/helper";
import { MultiSelect } from "primereact/multiselect";
import { PickList } from "primereact/picklist";

import { Tag } from "primereact/tag";

import { FileUpload } from "primereact/fileupload";
import {
  createProcedure,
  deleteBulkProcedure,
  deleteBulkProcedures,
  deleteProcedure,
  getAllAccessories,
  getAllChapterChecklists,
  getAllChapters,
  getAllChaptersList,
  getAllChecklists,
  getAllProcedures,
  updateBulkProcedures,
  updateProcedure,
  uploadProcedurePhoto,
} from "../helper/adminapicalls";
import { IMAGESPATH } from "../../backend";
import { useNavigate } from "react-router-dom";

const Procedures = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  let navigate = useNavigate();

  const procedureStatus = ["Pending", "Release", "On Hold", "Incomplete"];

  const [accessoriesList, setAccessoriesList] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState(null);


  const [checklistList, setChecklistList] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  
  const [chapterList, setChapterList] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  
  const [procedure, setProcedure] = useState({
    _id: "",
    name: "",
    photo: "",
    status: "",
    comments: "",
    references: [
      {
        name: "",
        link: "",
      },
    ],
    checklist: [],
    accessories: [],
    chapters: [],
    description: "",
    orderId: 0,
    error: "",
    formData: "",
  });

  const {
    _id,
    name,
    photo,
    status,
    comments,
    references,
    checklist,
    accessories,
    chapters,
    description,
    orderId,
    error,
    formData,
  } = procedure;

  let emptyProcedure = {
    _id: null,
    name: "",
    photo: "",
    status: "",
    comments: "",
    references: [
      {
        name: "",
        link: "",
      },
    ],
    checklist: [],
    accessories: [],
    chapters: [],
    description: "",
    orderId: 0,
    error: "",
    formData: "",
  };
  const [file, setFile] = useState(null);

  const [procedures, setProcedures] = useState(null);
  const [proceduresListVisble, setProceduresListVisble] = useState(true);

  const [procedureDialog, setProcedureDialog] = useState(false);

  const [deleteProcedureDialog, setDeleteProcedureDialog] = useState(false);

  const [deleteProceduresDialog, setDeleteProceduresDialog] = useState(false);
  const [reorderProceduresDialog, setReorderProceduresDialog] = useState(false);

  const [selectedProcedures, setSelectedProcedures] = useState(null);
  
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  //picklist
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  //end of picklist

  const toast = useRef(null);
  const dt = useRef(null);
  const fileUploader = useRef(null);
  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllProcedures(user._id, token)
      .then((data) => {
        if (data.error) {
          setProcedure({ ...procedure, error: data.error });
        } else {
          setSource(data);
          setProcedures(data);
          setProcedure({ ...procedure, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));

    getAllAccessories(user._id, token)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Accessories's list not uploaded",
            detail: data.error,
            life: 5000,
          });
        } else {
          setAccessoriesList(data);
        }
      })
      .catch((err) => console.log(err));
  
  getAllChecklists(user._id, token)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Checklists list not uploaded",
            detail: data.error,
            life: 5000,
          });
        } else {
          setChecklistList(data);
        }
      })
      .catch((err) => console.log(err));
  

    getAllChaptersList(user._id, token)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Chapters list not uploaded",
            detail: data.error,
            life: 5000,
          });
        } else {
          setChapterList(data);
        }
      })
      .catch((err) => console.log(err));
    };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

  const onPickListChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
    let counter = 0;
    event.target.forEach((item) => {
      counter++;
      item.orderId = counter;
    });
    updateBulkProcedures(user._id, token, event.target)
      .then((data) => {
        if (data.error) {
          setProcedure({
            ...procedure,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Procedure Not Updated",
            detail: data.error,
            life: 5000,
          });
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Procedure Updated",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFileUpload = (event) => {
    try {
      setSubmitted(false);
      const data = new FormData();
      data.append("photo", file, file.name);
      uploadProcedurePhoto(user._id, token, data).then((res) => {
        setProcedure({ ...procedure, photo: res.data.filename });
        fileUploader.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Photo Uploaded",
          life: 3000,
        });
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  // ============START OF CUD OPERATIONS ==================
  const saveProcedure = () => {
    setSubmitted(true);
    setProcedure({ ...procedure });
    const updatedProcedure = !_id
      ? { ...procedure, orderId: procedures.length + 1 }
      : procedure;
    if (!_id) {
      setTimeout(() => {
        createProcedure(user._id, token, updatedProcedure)
          .then((data) => {
            if (data.error) {
              setProcedure({
                ...procedure,
                error: data.error,
                formData: new FormData(),
              });
              toast.current.show({
                severity: "error",
                summary: "Procedure Not Created",
                detail: data.error,
                life: 5000,
              });
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Procedure Created",
                life: 3000,
              });
            }
          })
          .catch((err) => console.log(err));
      }, 0);
    } else {
      updateProcedure(user._id, token, _id, updatedProcedure)
        .then((data) => {
          if (data.error) {
            setProcedure({
              ...procedure,
              error: data.error,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Procedure Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Procedure Updated",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
    }
    setSelectedChecklist(null);
    setSelectedAccessories(null)
    setSelectedChapter(null)
    setProcedureDialog(false);
    setProcedure(emptyProcedure);
  };

  const editProcedure = (procedure) => {
    setProcedure({ ...procedure });
    setSelectedAccessories(procedure.accessories)
    setSelectedChecklist(procedure.checklist)
    setSelectedChapter(procedure.chapters)
    setProcedureDialog(true);
  };

  const deleteProcedureDB = () => {
    deleteProcedure(user._id, token, _id)
      .then((data) => {
        if (data.error) {
          setProcedure({
            ...procedure,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Procedure Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteProcedureDialog(false);
          setProcedure(emptyProcedure);
        } else {
          preload();
          setProcedure(emptyProcedure);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Procedure Deleted",
            life: 3000,
          });
          setDeleteProcedureDialog(false);
          setProcedure(emptyProcedure);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSelectedProcedures = () => {
    let procedures = selectedProcedures;
    const procedureIds = [];
    {
      Object.entries(procedures).map(([key, value]) =>
        procedureIds.push(procedures[key]._id)
      );
    }
    deleteBulkProcedures(user._id, token, procedureIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Procedures Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteProceduresDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Procedures Deleted",
            life: 3000,
          });
          setDeleteProceduresDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewProcedure = () => {
    setProcedure(emptyProcedure);
    setSubmitted(false);
    setProcedureDialog(true);
  };

  const hideDialogProcedure = () => {
    setSubmitted(false);
    setSelectedChecklist(null);
    setSelectedAccessories(null)
    setSelectedChapter(null)
    setProcedureDialog(false);
  };

  const hideDeleteProcedureDialog = () => {
    setDeleteProcedureDialog(false);
  };

  const hideDeleteProceduresDialog = () => {
    setDeleteProceduresDialog(false);
  };

  const hideReorderProceduresDialog = () => {
    setReorderProceduresDialog(false);
    setTarget([]);
    setProceduresListVisble(true);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const procedureDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogProcedure}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveProcedure} />
    </React.Fragment>
  );

  const deleteProcedureDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProcedureDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProcedureDB}
      />
    </React.Fragment>
  );

  const deleteProceduresDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProceduresDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProcedures}
      />
    </React.Fragment>
  );

  const confirmDeleteProcedure = (procedure) => {
    setProcedure(procedure);
    setDeleteProcedureDialog(true);
  };

  const confirmDeleteSelectedProcedures = () => {
    setDeleteProceduresDialog(true);
  };

  const reorderProcedures = () => {
    setProceduresListVisble(false);
    setReorderProceduresDialog(true);
  };

  const actionBodyTemplateProcedure = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProcedure(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          className="mr-2"
          severity="danger"
          onClick={() => confirmDeleteProcedure(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

  // ============START OF EXPORT ==================
  const cols = [
    { field: "_id", header: "ID" },
    { field: "name", header: "Procedure Name" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, procedures);
        doc.save("procedures.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(procedures);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "procedures");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setProcedure({ ...procedure, [name]: value });
  };

  const handleFileChange = ({ files }) => {
    setFile(files[0]);
  };

  const handleChecklistChange = (e) => {
    const selectedChecklistIds = e.value.map(checklist => checklist._id);
  
    // Update both the selectedChecklists state and the checklists array in the accessory state
    setSelectedChecklist(e.value);
  
    setProcedure(prevProcedure => ({
      ...prevProcedure,
      checklist: selectedChecklistIds,
    }));
  };


  const handleChapterChange = (e) => {
    const selectedChapterIds = e.value.map(chapter => chapter._id);
    // Update both the selectedChecklists state and the checklists array in the accessory state
    setSelectedChapter(e.value);
    setProcedure(prevProcedure => ({
      ...prevProcedure,
      chapters: selectedChapterIds,
    }));
  };

  const handleAccessoryChange = (e) => {
    const selectedAccessoryIds = e.value.map(accessory => accessory._id);
  
    // Update both the selectedAccessorys state and the accessorys array in the accessory state
    setSelectedAccessories(e.value);
  
    setProcedure(prevProcedure => ({
      ...prevProcedure,
      accessories: selectedAccessoryIds,
    }));
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateProcedure = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          className="m-2"
          onClick={openNewProcedure}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          className="m-2"
          onClick={confirmDeleteSelectedProcedures}
          disabled={!selectedProcedures || !selectedProcedures.length}
        />

        <Button
          label="Reorder"
          icon="pi pi-arrows-v"
          severity="info"
          className="m-2"
          onClick={reorderProcedures}
        />
      </div>
    );
  };

  const rightToolbarTemplateProcedure = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help m-2"
          onClick={exportCSV}
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF STATUS SEVERITY ==================

  const getSeverity = (procedure) => {
    switch (procedure.status) {
      case "Pending":
        return "info";

      case "Release":
        return "success";

      case "On Hold":
        return "danger";

      case "Incomplete":
        return "warning";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };
  // ============END OF STATUS SEVERITY ==================

  // ============START OF TEMPLATEs ==================

  const procedureBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src={`${IMAGESPATH}/${rowData.photo}`}
          alt={rowData.photo}
          width="100px"
          className="shadow-4"
        />
        <br />
        <h5> {rowData.name}</h5>
      </div>
    );
  };

  const pickListItemTemplate = (procedure) => {
    return (
      <div className="d-flex flex-row p-2 align-items-center gap-3">
        <img
          className="w-4rem shadow-2 flex-shrink-0 border-round"
          style={{ width: "10%" }}
          src={`${IMAGESPATH}/${procedure.photo}`}
          alt={procedure.name}
        />
        <div className="flex-1 flex flex-column gap-2">
          <span className="font-bold">{procedure.name}</span>
        </div>
      </div>
    );
  };

  // ============START OF TEMPLATEs ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Procedures </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Procedures">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        {proceduresListVisble && (
          <div className="card">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplateProcedure}
              right={rightToolbarTemplateProcedure}
            ></Toolbar>

            <DataTable
              ref={dt}
              value={procedures}
              selection={selectedProcedures}
              onSelectionChange={(e) => setSelectedProcedures(e.value)}
              dataKey="_id"
              paginator
              resizableColumns
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} procedures"
              globalFilter={globalFilter}
              globalFilterFields={["name", "description", "status"]}
              header={header}
            >
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column
                body={actionBodyTemplateProcedure}
                exportable={false}
                header="Actions"
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                body={procedureBodyTemplate}
                filterField="name"
                header="Name"
                sortable
                filter
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="status"
                header="Status"
                filterField="status"
                body={statusBodyTemplate}
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="description"
                header="Description"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>
        )}
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={procedureDialog}
          style={{ width: "50rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Procedure Details"
          modal
          className="p-fluid"
          footer={procedureDialogFooter}
          onHide={hideDialogProcedure}
        >
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <InputText
              id="name"
              value={name}
              onChange={handleChange("name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !name,
              })}
            />
            {submitted && !name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Accessories
            </label>

            <MultiSelect
              value={selectedAccessories}
              onChange={(e) => handleAccessoryChange(e)}
              options={accessoriesList}
              optionLabel="name" // Assuming each object in accessoriesList has a 'name' property
              placeholder="Select Accessories"
              className="w-full md:w-20rem"
              display="chip"
              itemTemplate={(option) => option.name} // Use the 'name' property from the accessoriesList
            />
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              Checklist
            </label>

            <MultiSelect
              value={selectedChecklist}
              onChange={(e) => handleChecklistChange(e)}
              options={checklistList}
              optionLabel="name" // Assuming each object in accessoriesList has a 'name' property
              placeholder="Select Checklist"
              className="w-full md:w-20rem"
              display="chip"
              itemTemplate={(option) => option.name} // Use the 'name' property from the accessoriesList
            />
            
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              Linked Chapters
            </label>

            <MultiSelect
              value={selectedChapter}
              onChange={(e) => handleChapterChange(e)}
              options={chapterList}
              optionLabel="name"// Assuming each object in accessoriesList has a 'name' property
              placeholder="Select Chapter"
              className="w-full md:w-20rem"
              display="chip"
              itemTemplate={(option) => option.name} // Use the 'name' property from the accessoriesList
            />
           
          </div>

          <div className="field mt-3">
            <label htmlFor="photo" className="font-bold">
              Photo
            </label>
            <br />
            {photo && (
              <img
                src={`${IMAGESPATH}/${photo}`}
                alt={photo}
                style={{ height: 200, width: 200, margin: "auto" }}
                className="product-image block m-auto pb-3"
              />
            )}
            <FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !photo && (
              <small className="p-error">Photo is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="description" className="font-bold">
              Description
            </label>
            <InputTextarea
              id="description"
              value={description}
              onChange={handleChange("description")}
              required
              rows={3}
              cols={20}
            />
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              Status
            </label>

            <Dropdown
              value={status}
              onChange={handleChange("status")}
              options={procedureStatus}
              placeholder="Select a Status"
              showClear
              className={classNames({
                "p-invalid": submitted && !status,
              })}
            />
            {submitted && !status && (
              <small className="p-error">Status is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="description" className="font-bold">
              Comments
            </label>
            <InputTextarea
              id="description"
              value={comments}
              onChange={handleChange("comments")}
              required
              rows={3}
              cols={20}
            />
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteProcedureDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteProcedureDialogFooter}
          onHide={hideDeleteProcedureDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {procedure && (
              <span>
                Are you sure you want to delete <b>{name}</b> procedure?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteProceduresDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteProceduresDialogFooter}
          onHide={hideDeleteProceduresDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {procedure && (
              <span>
                Are you sure you want to delete the selected procedures?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}

        {/*  START OF REORDER CHAPTER FORM   */}

        {reorderProceduresDialog && (
          <div className="card p-4">
            <div className="row m-3">
              <div className="col-6 text-left">
                <h2 className="text-primary">Reorder Procedures List</h2>
              </div>
              <div className="col-6 text-right">
                <Button
                  label="Close Reorder"
                  className=""
                  onClick={hideReorderProceduresDialog}
                />
              </div>
            </div>
            <PickList
              source={source}
              target={target}
              onChange={onPickListChange}
              itemTemplate={pickListItemTemplate}
              filter
              filterBy="name"
              breakpoint="1400px"
              sourceHeader="Available"
              targetHeader="Selected"
              showSourceControls={false}
              sourceStyle={{ height: "24rem" }}
              targetStyle={{ height: "24rem" }}
              sourceFilterPlaceholder="Search by name"
              targetFilterPlaceholder="Search by name"
            />
          </div>
        )}

        {/*  END OF REORDER CHAPTER FORM   */}
      </div>
    </AdminBase>
  );
};
export default Procedures;
