import React, { useEffect, useRef, useState } from "react";
import { isAuthenticated } from "../auth/helper";
import AdminNavigation from "./AdminNavigation";
import AdminBase from "../core/admin/AdminBase";
import { Helmet } from "react-helmet";
import { ProgressBar } from "primereact/progressbar";
import { getStats } from "./helper/adminapicalls";
import { Toast } from "primereact/toast";

const AdminDashboard = () => {

  const { user, token } = isAuthenticated();

  
  const [usersRegistered, setUsersRegistered] = useState(0);
  const [usersActive, setUsersActive] = useState(0);
  const [totalPromoCodes, setTotalPromoCodes] = useState(0);
  const [activePromoCodes, setActivePromoCodes] = useState(0);
  const [renewals, setRenewals] = useState(0)
  const [individualLicenses, setIndividualLicenses] = useState(0)
  const [hospitalLicenses, setHospitalLicenses] = useState(0)
  const [totalChapters, setTotalChapters] = useState(0)
  const [activeChapters, setActiveChapters] = useState(0)
  const toast = useRef(null);

  const preload = () => {
    getStats(user._id,token)
    .then((stats) => {
      if(stats.error){
        toast.current.show({
          severity: "error",
          summary: "Fetching stats Failed",
          detail: stats.error,
          life: 5000,
        });
      }else{
        setUsersActive(stats.usersActive)
        setUsersRegistered(stats.usersRegistered)
        setTotalPromoCodes(stats.TotalPromoCodes)
        setActivePromoCodes(stats.ActivePromoCodes)
      }
    })
    .catch((err)=>{
      toast.current.show({
        severity: "error",
        summary: "Fetching stats Failed",
        detail: "Something went wrong",
        life: 5000,
      });
    })
  }

  useEffect(() => {
    preload()
  })
  


  const adminDashboardContent = () => {
    return (
      <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Users (Registered)
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {usersRegistered}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-calendar fa-2x text-primary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Users (Active)
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {usersActive}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-dollar-sign fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Promocodes Active
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {activePromoCodes} out of {totalPromoCodes}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-clipboard-list fa-2x text-info"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                    Renewals This Month
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {renewals}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-comments fa-2x text-danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Individual Licenses
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {individualLicenses}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-comments fa-2x text-primary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Hospital Licenses
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {hospitalLicenses}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-comments fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Hospital Licenses
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {hospitalLicenses}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-comments fa-2x text-info"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                    Chapters Active
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {activeChapters} out of {totalChapters}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-book fa-2x text-danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
       <Toast ref={toast} />
      <AdminBase title="Dashboard">{adminDashboardContent()}</AdminBase>
    </div>
  );
};

export default AdminDashboard;
