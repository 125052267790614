import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import { loginRequest } from './helper/msal/authConfig';
import { PageLayout } from './helper/msal/PageLayout';
import { MSSignIn } from './helper/SsoApiCalls';
import { useNavigate } from 'react-router-dom';
import { authenticate } from './helper';

  /**
* Renders information about the signed-in user or a button to retrieve data about the user
*/
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();

     
    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((data) => {
                console.log(data.idTokenClaims)
                console.log("***********************")
                MSSignIn(data.accessToken).then((data)=>{
                    authenticate(data, ()=> {
                        console.log(data)
                        performRedirect(data.user)
                    })
                });
               
            });
    }
  

    const performRedirect = (user) => {
        if (user) {
            if (user.verified === "No") {
                navigate("/profileVerificationPending");
            } else {
                if (user.role === "Admin") {
                    navigate("/admin/dashboard");
                } else {
                    navigate("/user/dashboard");
                }
            }
        }
    }

    useEffect(() => {
        RequestProfileData()
    }, [accounts])
    
    return (
        <>
                
        </>
    );
};


const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
                
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5>
                    <center>
                        Please sign-in to see your profile information.
                    </center>
                </h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function MSALogin() {
    return (
       <>
        <PageLayout>
            <center>
                <MainContent />
            </center>
        </PageLayout>
       </>
    );
}