import React, { useEffect, useRef, useState } from "react";
import UserBase from "../core/user/UserBase";
import { getChapter } from "./helper/userapicalls";
import { useNavigate, useParams } from "react-router-dom";
import { isAuthenticated } from "../auth/helper";
import { Toast } from "primereact/toast";
const ChapterAuthor = () => {
    let navigate = useNavigate();
    const { user, token } = isAuthenticated();
    const chapterParams = useParams()
    const [chapter, setChapter] = useState([])
    const chapterId = chapterParams.chapterId
    const toast = useRef(null);

    const preload =  () => {
        getChapter(user._id, token, chapterId)
          .then((data) => {
              setChapter(data);
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Something Went Wrong",
              detail: "Cannot Retrieve the Chapter Contents ",
              life: 5000,
            });
            sleep(5000).then(() => {
              navigate("/user/chapters");
            });
          });
      };

    useEffect(() => {
      preload()
    
    }, [])
    
    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }

      
    return (
    <UserBase title="Chapter Author">
        Hi this is author

    </UserBase>
    )
}

export default ChapterAuthor;