import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../../core/admin/AdminBase";
import { isAuthenticated } from "../../auth/helper";
import { MultiSelect } from "primereact/multiselect";
import { PickList } from "primereact/picklist";

import { Tag } from "primereact/tag";

import { FileUpload } from "primereact/fileupload";
import {
  createChapter,
  deleteBulkChapter,
  deleteChapter,
  getAllAuthors,
  getAllChapters,
  updateBulkChapters,
  updateChapter,
  uploadChapterPhoto,
} from "../helper/adminapicalls";
import { IMAGESPATH } from "../../backend";
import { useNavigate } from "react-router-dom";

const Chapters = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  let navigate = useNavigate();

  const chapterStatus = ["Pending", "Release", "On Hold", "Incomplete"];

  const [authorsList, setAuthorsList] = useState();

  const [chapter, setChapter] = useState({
    _id: "",
    name: "",
    photo: "",
    status: "",
    comments: "",
    author: "",
    orderId: 0,
    error: "",
    formData: "",
  });

  const { _id, name, photo, status, comments, author, orderId, error, formData } =
    chapter;

  let emptyChapter = {
    id: null,
    name: "",
    photo: "",
    status: "",
    comments: "",
    author: "",
    orderId: 0,
    error: "",
    formData: "",
  };
  const [file, setFile] = useState(null);

  const [chapters, setChapters] = useState(null);
  const [chaptersListVisble, setChaptersListVisble] = useState(true);

  const [chapterDialog, setChapterDialog] = useState(false);

  const [deleteChapterDialog, setDeleteChapterDialog] = useState(false);

  const [deleteChaptersDialog, setDeleteChaptersDialog] = useState(false);
  const [reorderChaptersDialog, setReorderChaptersDialog] = useState(false);

  const [selectedChapters, setSelectedChapters] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  //picklist
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  //end of picklist

  const toast = useRef(null);
  const dt = useRef(null);
  const fileUploader = useRef(null);
  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllChapters(user._id, token)
      .then((data) => {
        if (data.error) {
          setChapter({ ...chapter, error: data.error });
        } else {
          setSource(data);
          setChapters(data);
          setChapter({ ...chapter, formData: new FormData()});
        }
      })
      .catch((err) => console.log(err));

    getAllAuthors(user._id, token)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Author's list not uploaded",
            detail: data.error,
            life: 5000,
          });
        } else {
          setAuthorsList(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

  const onPickListChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
    let counter=0;
    event.target.forEach(item => {
      counter++;
      item.orderId = counter;
    });
    updateBulkChapters(user._id, token, event.target)
    .then((data) => {
      if (data.error) {
        setChapter({
          ...chapter,
          error: data.error,
          formData: new FormData(),
        });
        toast.current.show({
          severity: "error",
          summary: "Chapter Not Updated",
          detail: data.error,
          life: 5000,
        });
      } else {
        preload();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Chapter Updated",
          life: 3000,
        });
      }
    })
    .catch((err) => console.log(err));

  };

  const handleFileUpload = (event) => {
    try {
      setSubmitted(false);
      const data = new FormData();
      data.append("photo", file, file.name);
      uploadChapterPhoto(user._id, token, data).then((res) => {
        setChapter({ ...chapter, photo: res.data.filename });
        fileUploader.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Photo Uploaded",
          life: 3000,
        });
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  // ============START OF CUD OPERATIONS ==================
  const saveChapter = () => {
    setSubmitted(true);
    setChapter({ ...chapter});
    const updatedChapter = !_id
    ? { ...chapter, orderId: chapters.length + 1 }
    : chapter
    if (!_id) {
      setTimeout(() => {
        createChapter(user._id, token, updatedChapter)
        .then((data) => {
          if (data.error) {
            setChapter({
              ...chapter,
              error: data.error,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Chapter Not Created",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Chapter Created",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
      }, 0);
    } else {
      updateChapter(user._id, token, _id, updatedChapter)
        .then((data) => {
          if (data.error) {
            setChapter({
              ...chapter,
              error: data.error,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Chapter Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Chapter Updated",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
    }
    setSelectedAuthor(null);
    setChapterDialog(false);
    setChapter(emptyChapter);
  };

  const editChapter = (chapter) => {
    setChapter({ ...chapter });
    setSelectedAuthor(chapter.author);
    setChapterDialog(true);
  };

  const deleteChapterDB = () => {
    deleteChapter(user._id, token, _id)
      .then((data) => {
        if (data.error) {
          setChapter({
            ...chapter,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Chapter Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChapterDialog(false);
          setChapter(emptyChapter);
        } else {
          preload();
          setChapter(emptyChapter);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Chapter Deleted",
            life: 3000,
          });
          setDeleteChapterDialog(false);
          setChapter(emptyChapter);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSelectedChapters = () => {
    let chapters = selectedChapters;
    const chapterIds = [];
    {
      Object.entries(chapters).map(([key, value]) =>
        chapterIds.push(chapters[key]._id)
      );
    }
    deleteBulkChapter(user._id, token, chapterIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Chapters Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteChaptersDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Chapters Deleted",
            life: 3000,
          });
          setDeleteChaptersDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewChapter = () => {
    setChapter(emptyChapter);
    setSelectedAuthor(null);
    setSubmitted(false);
    setChapterDialog(true);
  };

  const hideDialogChapter = () => {
    setSubmitted(false);
    setChapterDialog(false);
  };

  const hideDeleteChapterDialog = () => {
    setDeleteChapterDialog(false);
  };

  const hideDeleteChaptersDialog = () => {
    setDeleteChaptersDialog(false);
  };

  const hideReorderChaptersDialog = () => {
    setReorderChaptersDialog(false);
    setTarget([])
    setChaptersListVisble(true);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const chapterDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogChapter}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveChapter} />
    </React.Fragment>
  );

  const deleteChapterDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChapterDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteChapterDB}
      />
    </React.Fragment>
  );

  const deleteChaptersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteChaptersDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedChapters}
      />
    </React.Fragment>
  );

  const confirmDeleteChapter = (chapter) => {
    setChapter(chapter);
    setDeleteChapterDialog(true);
  };

  const confirmDeleteSelectedChapters = () => {
    setDeleteChaptersDialog(true);
  };

  const reorderChapters = () => {
    setChaptersListVisble(false);
    setReorderChaptersDialog(true);
  };

  const actionBodyTemplateChapter = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editChapter(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          className="mr-2"
          severity="danger"
          onClick={() => confirmDeleteChapter(rowData)}
        />

        <Button
          icon="pi pi-plus"
          rounded
          outlined
          severity="help"
          onClick={() => navigate(`/admin/chapter/titles/${rowData._id}`)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

  // ============START OF EXPORT ==================
  const cols = [
    { field: "_id", header: "ID" },
    { field: "name", header: "Chapter Name" },
    { field: "author.firstname", header: "Author Firstname" },
    { field: "author.lastname", header: "Author Lastname" },
    { field: "author.designation", header: "Author Designation" },
    { field: "author.organisation", header: "Author Organisation" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, chapters);
        doc.save("chapters.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(chapters);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "chapters");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value =
      name === "author" ? event.target.value._id : event.target.value;
    if (name === "author") {
      setSelectedAuthor(event.target.value);
    }
    setChapter({ ...chapter, [name]: value });
  };

  const handleFileChange = ({ files }) => {
    setFile(files[0]);
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateChapter = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          className="m-2"
          onClick={openNewChapter}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          className="m-2"
          onClick={confirmDeleteSelectedChapters}
          disabled={!selectedChapters || !selectedChapters.length}
        />

        <Button
          label="Reorder"
          icon="pi pi-arrows-v"
          severity="info"
          className="m-2"
          onClick={reorderChapters}
        />
      </div>
    );
  };

  const rightToolbarTemplateChapter = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help m-2"
          onClick={exportCSV}
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF STATUS SEVERITY ==================

  const getSeverity = (chapter) => {
    switch (chapter.status) {
      case "Pending":
        return "info";

      case "Release":
        return "success";

      case "On Hold":
        return "danger";

      case "Incomplete":
        return "warning";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };
  // ============END OF STATUS SEVERITY ==================

  // ============START OF TEMPLATEs ==================

  const authorBodyTemplate = (rowData) => {
    const author = rowData.author;
    if (author) {
      return (
        <div className="flex align-items-center gap-2">
          <div className="d-inline">
            {" "}
            <img
              alt={`${author.firstname} ${author.lastname}`}
              src={`${IMAGESPATH}/${author.photo}`}
              width="50"
            />
            <br />
          </div>
          <div className="d-inline">
            {" "}
            <span>{`${author.firstname} ${author.lastname}`}</span>
            <br /> <span>{`${author.designation}`}</span> <br />{" "}
            <span>{`${author.organisation}`}</span>
          </div>
        </div>
      );
    }
  };

  const selectedAuthorTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={`${option.firstname} ${option.lastname}`}
            src={`${IMAGESPATH}/${option.photo}`}
            style={{ width: "50px" }}
          />
          <div>
            {`${option.salutation} ${option.firstname} ${option.lastname}`}{" "}
            <br />
            {`${option.designation} ${option.organisation}`}
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const authorOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={`${option.firstname} ${option.lastname}`}
          src={`${IMAGESPATH}/${option.photo}`}
          style={{ width: "50px" }}
        />
        <div>
          {`${option.salutation} ${option.firstname} ${option.lastname}`} <br />
          {`${option.designation} ${option.organisation}`}
        </div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedAuthor ? (
          <span>
            <b>
              {selectedAuthor.firstname} {selectedAuthor.lastname}
            </b>{" "}
            selected.
          </span>
        ) : (
          "No author selected."
        )}
      </div>
    );
  };

  const chapterBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src={`${IMAGESPATH}/${rowData.photo}`}
          alt={rowData.photo}
          width="100px"
          className="shadow-4"
        />
        <br />
        <h5> {rowData.name}</h5>
      </div>
    );
  };

  const pickListItemTemplate = (chapter) => {
    return (
      <div className="d-flex flex-row p-2 align-items-center gap-3">
        <img
          className="w-4rem shadow-2 flex-shrink-0 border-round"
          style={{ width: "10%" }}
          src={`${IMAGESPATH}/${chapter.photo}`}
          alt={chapter.name}
        />
        <div className="flex-1 flex flex-column gap-2">
          <span className="font-bold">{chapter.name}</span>
          <div className="flex align-items-center gap-2">
            <i className="pi pi-user text-sm me-2"></i>
            <span>
              {chapter.author.salutation}.{chapter.author.firstname}{" "}
              {chapter.author.lastname}
            </span>
          </div>
        </div>
      </div>
    );
  };

  // ============START OF TEMPLATEs ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Chapters </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Chapters">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        {chaptersListVisble && (
          <div className="card">
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplateChapter}
              right={rightToolbarTemplateChapter}
            ></Toolbar>

            <DataTable
              ref={dt}
              value={chapters}
              selection={selectedChapters}
              onSelectionChange={(e) => setSelectedChapters(e.value)}
              dataKey="_id"
              paginator
              resizableColumns
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} chapters"
              globalFilter={globalFilter}
              globalFilterFields={['name','author.firstname','status']}
              header={header}
            >
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column
                body={actionBodyTemplateChapter}
                exportable={false}
                header="Actions"
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                body={chapterBodyTemplate}
                filterField="name"
                header="Name"
                sortable
                filter
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                header="Author"
                sortable
                filterField="author.firstname"
                sortField="author.firstname"
                style={{ minWidth: "16rem" }}
                body={authorBodyTemplate}
              />
              <Column
                field="status"
                header="Status"
                filterField="status"
                body={statusBodyTemplate}
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="comments"
                header="Comments"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>
        )}
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={chapterDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Chapter Details"
          modal
          className="p-fluid"
          footer={chapterDialogFooter}
          onHide={hideDialogChapter}
        >
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <InputText
              id="name"
              value={name}
              onChange={handleChange("name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !name,
              })}
            />
            {submitted && !name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Author
            </label>

            <Dropdown
              value={selectedAuthor}
              onChange={handleChange("author")}
              options={authorsList}
              optionLabel="name"
              placeholder="Select an Author"
              valueTemplate={selectedAuthorTemplate}
              itemTemplate={authorOptionTemplate}
              className="w-full md:w-14rem"
              panelFooterTemplate={panelFooterTemplate}
            />

            {submitted && !author && (
              <small className="p-error">Author is required.</small>
            )}
          </div>

          <div className="field mt-3">
            <label htmlFor="photo" className="font-bold">
              Photo
            </label>
            <br />
            {photo && (
              <img
                src={`${IMAGESPATH}/${photo}`}
                alt={photo}
                style={{ height: 200, width: 200, margin: "auto" }}
                className="product-image block m-auto pb-3"
              />
            )}
            <FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !photo && (
              <small className="p-error">Photo is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              Status
            </label>

            <Dropdown
              value={status}
              onChange={handleChange("status")}
              options={chapterStatus}
              placeholder="Select a Status"
              showClear
              className={classNames({
                "p-invalid": submitted && !status,
              })}
            />
            {submitted && !status && (
              <small className="p-error">Status is required.</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="description" className="font-bold">
              Comments
            </label>
            <InputTextarea
              id="description"
              value={comments}
              onChange={handleChange("comments")}
              required
              rows={3}
              cols={20}
            />
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteChapterDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChapterDialogFooter}
          onHide={hideDeleteChapterDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapter && (
              <span>
                Are you sure you want to delete <b>{name}</b> chapter?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteChaptersDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteChaptersDialogFooter}
          onHide={hideDeleteChaptersDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {chapter && (
              <span>
                Are you sure you want to delete the selected chapters?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}

        {/*  START OF REORDER CHAPTER FORM   */}

        {reorderChaptersDialog && (
          <div className="card p-4">
            <div className="row m-3">
              <div className="col-6 text-left">
              <h2 className="text-primary">Reorder Chapters List</h2>
              </div>
              <div className="col-6 text-right">
                
                <Button
                  label="Close Reorder"
                  className=""
                  onClick={hideReorderChaptersDialog}
                />
              </div>
            </div>
            <PickList
              source={source}
              target={target}
              onChange={onPickListChange}
              itemTemplate={pickListItemTemplate}
              filter
              filterBy="name"
              breakpoint="1400px"
              sourceHeader="Available"
              targetHeader="Selected"
              showSourceControls={false}
              sourceStyle={{ height: "24rem" }}
              targetStyle={{ height: "24rem" }}
              sourceFilterPlaceholder="Search by name"
              targetFilterPlaceholder="Search by name"
            />
          </div>
        )}

        {/*  END OF REORDER CHAPTER FORM   */}
      </div>
    </AdminBase>
  );
};
export default Chapters;
