
import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';

export default function Uploads() {
    const [photo, setPhoto] = useState(null);


    const handleFileChange = (event) => {
        console.log(event.target.files)
        setPhoto(event.target.files);        
    }
    
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();
        for(var x = 0; x<photo.length; x++) {
            data.append('photo', photo[x])
        }
        axios.post("http://localhost:8080/api/upload", data)
        .then((res) => { 
    console.log(res)
    setPhoto('http://localhost:8080/images/'+res.data.filename)
  })
    
    }
    
    return ( 
        <div>
           <form >
            <div className="form-group" >
    
                <label htmlFor="photo">Upload File:</label>
                <input 
                className="form-control-file mb-3" 
                type="file" id="photo" 
                name={photo}
                accept=".jpg"
                multiple
                onChange={handleFileChange}
                />
    
                <button 
                className="btn btn-primary mt-3" 
                onClick={handleSubmit}
                >Upload</button>
            </div>
           </form>
    
           <img src={photo} alt="img"/>
       
        </div>
    )
}
        