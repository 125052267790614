import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Home from "./core/static/Home";
import Pricing from "./core/static/Pricing";
import Features from "./core/static/Features";
import Contact from "./core/static/Contact";
import Login from "./auth/Login";
import Register from "./auth/Register";

import AdminRoutes from "./auth/helper/AdminRoutes";
import PrivateRoutes from "./auth/helper/PrivateRoutes";
import AdminDashboard from "./admin/AdminDashboard";
import AddHospital from "./admin/Hospitals";
import PromoCodes from "./admin/PromoCodes";
import Hospitals from "./admin/Hospitals";
import Chapters from "./admin/chapters/Chapters";
import Users from "./admin/Users";
import Authors from "./admin/Authors";
import Uploads from "./admin/Uploads";
import AdminChapterTitlesBase from "./core/admin/AdminChapterTitlesBase";

import ChapterOverview from "./admin/chapters/ChapterOverview";
import ChapterBanner from "./admin/chapters/ChapterBanner";
import ChapterManageTitles from "./admin/chapters/ChapterManageTitles";
import UserDashboard from "./user/UserDashboard";
import ActivateEbook from "./user/ActivateEbook";
import EbookChapters from "./user/EbookChapters";
import Chapter from "./user/Chapter";
import ManagePricing from "./admin/ManagePricing";
import VerifyDelegateVerificationEmail from "./mailer/VerifyDelegateVerificationEmail"
import ProfileVerificationPending from "./auth/ProfileVerificationPending";
import ChapterImages from "./admin/chapters/ChapterImages";
import ChapterAuthor from "./user/ChapterAuthor";
import ChapterReferences from "./admin/chapters/ChapterReferences";
import ChapterAccessories from "./admin/chapters/ChapterAccessories";
import ChapterChecklists from "./admin/chapters/ChapterChecklist";
import Procedures from "./admin/chapters/Procedures";
import Accessories from "./admin/chapters/Accessories";

import MSALogin from "./auth/MSALogin";

import ForgotPassword from "./auth/ForgotPassword";


const Routers = () => {

  let {chapterId} = useParams()

  return (
    <BrowserRouter >
      <Routes >
        <Route path="/" element={<Home />} exact />
        <Route path="/features" element={<Features />} exact />
        <Route path="/pricing" element={<Pricing />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/register" element={<Register />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/forgotPassword" element={<ForgotPassword />} exact />
        
        
        <Route path="/msalogin" element={<MSALogin />} exact />
        
        {/* Mail Verification Routes */}
        <Route path="/verifyUser/:email/:code" element={<VerifyDelegateVerificationEmail />} exact />
       
       
        {/* Verification Page */}
        <Route path="/profileVerificationPending" element={<ProfileVerificationPending />} exact />

           
      {/* USER ROUTES */}
        <Route element={<PrivateRoutes/>} >
       
            <Route element={<UserDashboard/>} path="/user/dashboard" exact />
       
            <Route element={<ActivateEbook/>} path="/user/activate" exact />
            <Route element={<EbookChapters/>} path="/user/chapters" exact />
            <Route element={<Chapter/>} path="/user/chapter/:chapterId" exact />
            <Route element={<ChapterAuthor/>} path="/user/chapter/author/:chapterId" exact />
        </Route>
      {/* END OF USER ROUTES */}


  

      {/* ADMIN ROUTES */}
        <Route element={<AdminRoutes />}>
          <Route element={<Chapters />} path="/admin/chapters" exact />
          <Route element={<AdminDashboard />} path="/admin/dashboard" exact />
        
         {/* Start of Pricing Routes */}
          <Route element={<ManagePricing />} path="/admin/pricing" exact />
         {/* End of Pricing Routes */}


         
         {/* Start of Chapter Single Routes */}
          <Route element={<ChapterManageTitles />} path="/admin/chapter/titles/:chapterId" exact />
          <Route element={<ChapterManageTitles />} path="/admin/chapter/manageTitles/:chapterId" exact />
          <Route element={<ChapterOverview />} path="/admin/chapter/overview/:chapterId" exact />
          <Route element={<ChapterBanner />} path="/admin/chapter/banners/:chapterId" exact />
          <Route element={<ChapterImages />} path="/admin/chapter/images/:chapterId" exact />
          <Route element={<ChapterReferences />} path="/admin/chapter/references/:chapterId" exact />
          <Route element={<ChapterAccessories />} path="/admin/chapter/accessories/:chapterId" exact />
          <Route element={<ChapterChecklists />} path="/admin/chapter/checklists/:chapterId" exact />
      
      
        {/* End of Chapter Single Routes */}
          <Route element={<Uploads />} path="/admin/uploads" exact />
          <Route element={<Authors />} path="/admin/authors" exact />
          <Route element={<PromoCodes />} path="/admin/promoCodes" exact />
          <Route element={<Hospitals />} path="/admin/hospitals" exact />
          <Route element={<Users />} path="/admin/users" exact />
          <Route element={<Procedures />} path="/admin/procedures" exact />
          <Route element={<Accessories />} path="/admin/accessories" exact />
        </Route>
        {/* END OF ADMIN ROUTES */}
      </Routes>
    </BrowserRouter>
  );
};
export default Routers;
