import React from 'react'
import UserTopMenu from './UserTopMenu'
import UserMenu from './UserMenu'
import { Helmet } from "react-helmet";
import { isAuthenticated, logout } from "../../auth/helper";
import { NavLink, useNavigate } from "react-router-dom";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

const UserBase = ({ title = "Dashboard", children }) => {
    let navigate = useNavigate()
   let firstname ="scope"
   let lastname = "delegate"
  return (
    <div>
    <Helmet>
        <link
          href="/adminAssets/vendor/fontawesome-free/css/all.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
          rel="stylesheet"
        />
        <link href="/adminAssets/css/sb-admin-2.min.css" rel="stylesheet" />

   
        <script src="/adminAssets/vendor/jquery/jquery.min.js"></script>
        <script src="/adminAssets/vendor/bootstrap/js/bootstrap.js"></script>

      </Helmet>
      <div
        id="wrapper"
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          position: "fixed",
          overflowY: "auto",
        }}
      >
        {UserMenu()}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {UserTopMenu({firstname,lastname})}

            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{title}</h1>
                {/* <a
                  href="#"
                  className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                  <i className="fas fa-download fa-sm text-white-50"></i> Generate
                  Report
                </a> */}
              </div>
              {children}
            </div>
          </div>
          <footer className="sticky-footer bg-primary">
            <div className="container my-auto">
              <div className="copyright text-center my-auto text-white">
                <span>Copyright &copy; Scope Health UK 2024 | Powered By Maylancer Techios Pvt Ltd</span>
              </div>
            </div>
          </footer>
    
    <script src="/adminAssets/vendor/jquery-easing/jquery.easing.min.js"></script>

    <script src="/adminAssets/js/sb-admin-2.min.js"></script>

    <script src="/adminAssets/vendor/chart.js/Chart.min.js"></script>

    <script src="/adminAssets/js/demo/chart-area-demo.js"></script>
    <script src="/adminAssets/js/demo/chart-pie-demo.js"></script>   
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <NavLink className="btn btn-primary" onClick={logout}>
                Logout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserBase
