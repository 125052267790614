import axios from "axios";
import { API } from "../../backend";

export const ebookActivationStatus = (userId,token) => {
    return fetch(`${API}/user/ebookActivationStatus/${userId}`,
    {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
       return response.json()
    })
    .catch(err=>console.log(err))
}

export const activateEbook = (userId, token, promoCode) => {
    return fetch(`${API}/user/activateEbook/${userId}/${promoCode}`,
    {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
       return response.json()
    })
    .catch(err=>console.log(err))
}

export const getChapters = (userId, token) => {
    return fetch(`${API}/user/chapters/${userId}`,
    {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err=>console.log(err))
}

export const getChapter = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getChapterTitles = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/titles/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

// CHAPTER META DATA
export const getChapterAccessories = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/accessories/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterChecklists = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/checklists/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterReferences = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/references/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getChapterProcedures = (userId, token, chapterId) => {
    return fetch(`${API}/user/chapter/procedures/${userId}/${chapterId}`, {
        method: "GET",
        headers: {
            Authorization : `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

