import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../core/admin/AdminBase";
import { isAuthenticated } from "../auth/helper";
import { Tag } from "primereact/tag";
import {
  createAuthor,
  deleteAuthor,
  deleteBulkAuthor,
  getAllAuthors,
  updateAuthor,
  uploadFile,
} from "./helper/adminapicalls";
import { FileUpload } from "primereact/fileupload";

import { IMAGESPATH } from "../backend";

const Authors = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  const [file, setFile] = useState(null);

  
  const [author, setAuthor] = useState({
    _id: "",
    salutation: "",
    firstname: "",
    lastname: "",
    designation: "",
    organisation: "",
    scopelink: "",
    photo: "",
    formData: "",
  });

  const {
    _id,
    salutation,
    firstname,
    lastname,
    designation,
    organisation,
    scopelink,
    photo,
    formData,
  } = author;

  let emptyAuthor = {
    salutation: "",
    firstname: "",
    lastname: "",
    designation: "",
    organisation: "",
    scopelink: "",
    photo: "",
    formData: "",
  };

  const [authors, setAuthors] = useState(null);

  const [authorDialog, setAuthorDialog] = useState(false);

  const [deleteAuthorDialog, setDeleteAuthorDialog] = useState(false);

  const [deleteAuthorsDialog, setDeleteAuthorsDialog] = useState(false);

  const [selectedAuthors, setSelectedAuthors] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const fileUploader = useRef(null);

  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllAuthors(user._id, token)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Fetching Authors List Failed",
            detail: data.error,
            life: 5000,
          });
        } else {
          setAuthors(data);
          setAuthor({ ...author, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

  const handleFileUpload = (event) => {
    try {
      const data = new FormData();
      data.append("photo", file, file.name);
      uploadFile(user._id, token, data).then((res) => {
        setAuthor({ ...author, photo: res.data.filename });
        fileUploader.current.clear();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Photo Uploaded",
          life: 3000,
        });
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // ============START OF CUD OPERATIONS ==================
  const saveAuthor = () => {
    setSubmitted(true);
    setAuthor({ ...author });
    if (!_id) {
      createAuthor(user._id, token, author)
        .then((data) => {
          if (data.error) {
            setAuthor({
              ...author,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Author Not Created",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Author Created",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      updateAuthor(user._id, token, _id, author)
        .then((data) => {
          if (data.error) {
            setAuthor({
              ...author,
              formData: new FormData(),
            });
            toast.current.show({
              severity: "error",
              summary: "Author Not Updated",
              detail: data.error,
              life: 5000,
            });
          } else {
            preload();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Author Updated",
              life: 3000,
            });
          }
        })
        .catch((err) => console.log(err));
    }
    setAuthorDialog(false);
    setAuthor(emptyAuthor);
  };

  const editAuthor = (author) => {
    setAuthor({ ...author });
    setAuthorDialog(true);
  };

  const deleteAuthorDB = () => {
    deleteAuthor(user._id, token, _id)
      .then((data) => {
        if (data.error) {
          setAuthor({
            ...author,
            error: data.error,
            formData: new FormData(),
          });
          toast.current.show({
            severity: "error",
            summary: "Author Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteAuthorDialog(false);
          setAuthor(emptyAuthor);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Author Deleted",
            life: 3000,
          });
          setDeleteAuthorDialog(false);
          setAuthor(emptyAuthor);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSelectedAuthors = () => {
    let authors = selectedAuthors;
    const authorIds = [];
    {
      Object.entries(authors).map(([key, value]) =>
        authorIds.push(authors[key]._id)
      );
    }
    deleteBulkAuthor(user._id, token, authorIds)
      .then((data) => {
        if (data.error) {
          toast.current.show({
            severity: "error",
            summary: "Authors Not Deleted",
            detail: data.error,
            life: 5000,
          });
          setDeleteAuthorsDialog(false);
        } else {
          preload();
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Authors Deleted",
            life: 3000,
          });
          setDeleteAuthorsDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewAuthor = () => {
    setAuthor(emptyAuthor);
    setSubmitted(false);
    setAuthorDialog(true);
  };

  const hideDialogAuthor = () => {
    setSubmitted(false);
    setAuthorDialog(false);
  };

  const hideDeleteAuthorDialog = () => {
    setDeleteAuthorDialog(false);
  };

  const hideDeleteAuthorsDialog = () => {
    setDeleteAuthorsDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const authorDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogAuthor}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveAuthor} />
    </React.Fragment>
  );

  const deleteAuthorDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteAuthorDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteAuthorDB}
      />
    </React.Fragment>
  );

  const deleteAuthorsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteAuthorsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedAuthors}
      />
    </React.Fragment>
  );

  const confirmDeleteAuthor = (author) => {
    setAuthor(author);
    setDeleteAuthorDialog(true);
  };

  const confirmDeleteSelectedAuthors = () => {
    setDeleteAuthorsDialog(true);
  };

  const actionBodyTemplateAuthor = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editAuthor(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteAuthor(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================

  // ============START OF EXPORT ==================
  const cols = [
    { field: "_id", header: "ID" },
    { field: "salutation", header: "Salutation" },
    { field: "firstname", header: "First Name" },
    { field: "lastname", header: "Last Name" },
    { field: "designation", header: "Designation" },
    { field: "organisation", header: "Organisation" },
    { field: "scopelink", header: "Scope Link" },
    { field: "photo", header: "Photo" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, authors);
        doc.save("authors.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(authors);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "authors");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setAuthor({ ...author, error: "", [name]: value });
  };

  const handleFileChange = ({ files }) => {
     setFile(files[0]);
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateAuthor = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewAuthor}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedAuthors}
          disabled={!selectedAuthors || !selectedAuthors.length}
        />
      </div>
    );
  };

  const rightToolbarTemplateAuthor = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help m-2"
          onClick={exportCSV}
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          className="m-1"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Authors</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`${IMAGESPATH}/${rowData.photo}`}
        alt={rowData.photo}
        width="64px"
        className="shadow-4"
      />
    );
  };

  // ============END OF HEADER ==================

  return (
    <AdminBase title="Authors">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateAuthor}
            right={rightToolbarTemplateAuthor}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={authors}
            selection={selectedAuthors}
            onSelectionChange={(e) => setSelectedAuthors(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} authors"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateAuthor}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="salutation"
              header="Salutation"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="firstname"
              header="First Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="lastname"
              header="Last Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="designation"
              header="Designation"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="organisation"
              header="Organisation"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="scopelink"
              header="Scope Link"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              body={imageBodyTemplate}
              header="Photo"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={authorDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Author Details"
          modal
          className="p-fluid"
          footer={authorDialogFooter}
          onHide={hideDialogAuthor}
        >
          <div className="field mt-3">
            <label htmlFor="salution" className="font-bold">
              Salutation
            </label>
            <InputText
              id="salutation"
              value={salutation}
              onChange={handleChange("salutation")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !salutation,
              })}
            />
            {submitted && !salutation && (
              <small className="p-error">Salutation is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="firstname" className="font-bold">
              First Name
            </label>
            <InputText
              id="firstname"
              value={firstname}
              onChange={handleChange("firstname")}
              required
              className={classNames({
                "p-invalid": submitted && !firstname,
              })}
            />
            {submitted && !firstname && (
              <small className="p-error">First Name is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="lastname" className="font-bold">
              Last Name
            </label>
            <InputText
              id="lastname"
              value={lastname}
              onChange={handleChange("lastname")}
              required
              className={classNames({
                "p-invalid": submitted && !lastname,
              })}
            />
            {submitted && !lastname && (
              <small className="p-error">Last Name is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="designation" className="font-bold">
              Designation
            </label>
            <InputText
              id="designation"
              value={designation}
              onChange={handleChange("designation")}
              required
              className={classNames({
                "p-invalid": submitted && !designation,
              })}
            />
            {submitted && !designation && (
              <small className="p-error">Designation is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="organisation" className="font-bold">
              Organisation
            </label>
            <InputText
              id="organisation"
              value={organisation}
              onChange={handleChange("organisation")}
              required
              className={classNames({
                "p-invalid": submitted && !organisation,
              })}
            />
            {submitted && !organisation && (
              <small className="p-error">Organisation is required.</small>
            )}
          </div>
          <div className="field mt-3">
            <label htmlFor="scopelink" className="font-bold">
              Scope Link
            </label>
            <InputText
              id="scopelink"
              value={scopelink}
              onChange={handleChange("scopelink")}
              required
              className={classNames({
                "p-invalid": submitted && !scopelink,
              })}
            />
            {submitted && !scopelink && (
              <small className="p-error">Scope Link is required.</small>
            )}
          </div>

          <div className="field mt-3">
            <label htmlFor="photo" className="font-bold">
              Photo
            </label>
            <br />
            {photo && (
              <img
                src={`${IMAGESPATH}/${photo}`}
                alt={photo}
                style={{ height: 200, width: 200, margin: "auto" }}
                className="product-image block m-auto pb-3"
              />
            )}
            <FileUpload
              name="photo"
              ref={fileUploader}
              customUpload="true"
              onSelect={handleFileChange}
              onUpload={(e) => console.log(e)}
              uploadHandler={handleFileUpload}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
            {submitted && !photo && (
              <small className="p-error">Photo is required.</small>
            )}
          </div>
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteAuthorDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteAuthorDialogFooter}
          onHide={hideDeleteAuthorDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {author && (
              <span>
                Are you sure you want to delete{" "}
                <b>
                  {firstname} {lastname}
                </b>{" "}
                author?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteAuthorsDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteAuthorsDialogFooter}
          onHide={hideDeleteAuthorsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {author && (
              <span>Are you sure you want to delete the selected Authors?</span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminBase>
  );
};
export default Authors;
