import { API } from "../../backend";

export const MSSignIn = async (token) => {
    try {
        const requestBody = {
            token: token
        };
        const response = await fetch(`${API}/ssoMSLogin`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`Failed to sign in: ${response.status} - ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error signing in:", error);
        throw error; // Rethrow the error to be caught by the caller
    }
};

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */


export const GSignIn = async (data) => {
    return fetch(`${API}/ssoGLogin`, {
        method: "POST",
        headers: {
            Accept : "application/json",
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
};

  