import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { isAuthenticated } from "../../auth/helper";
import { getChapters } from "../../user/helper/userapicalls";
import { FRONTAPP } from "../../backend";
const UserMenu = () => {
  const { user, token } = isAuthenticated();

  const [chapters, setChapters] = useState([]);

  const preload = () => {
    getChapters(user._id, token)
      .then((data) => {
        setChapters(data);
        console.log(chapters);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  return (
    <div id="navbarScroll">
      {" "}
      <ul
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion position-sticky navbar-nav-scroll"
        style={{ overflowX: "hidden" }}
      >
        <a
          class="sidebar-brand d-flex align-items-center justify-content-center"
          href=""
        >
          <img
            src="/images/ebookLogoWhite.png"
            alt="Scope Ebook Logo"
            style={{ width: "100%" }}
          />
        </a>
        <hr class="sidebar-divider my-0" />
        <li class="nav-item active">
          <NavLink className="nav-link" to="/user/dashboard">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <hr class="sidebar-divider" />

        <div class="sidebar-heading">E-Book</div>
        <li class="nav-item">
          <NavLink className="nav-link" to="/user/activate">
            <i class="pi pi-file-o"></i>
            <span>Activation</span>
          </NavLink>
        </li>
        <li class="nav-item">
          <NavLink className="nav-link" to="/user/chapters">
            <i class="pi pi-file-o"></i>
            <span>Chapters</span>
          </NavLink>
        </li>
        {chapters.length >= 1 && (
          <span>
            <hr class="sidebar-divider" />

            <div class="sidebar-heading">Chapter Links</div>

            {chapters.map((chapter) => (
              <li key={chapter._id} className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={() => {
                    window.location.reload();
                    window.location.href = `/user/chapter/${chapter._id}`;
                  }}
                >
                  <span>{chapter.orderId}. </span>
                  <span>{chapter.name}</span>
                </NavLink>
              </li>
            ))}
          </span>
        )}

        <hr class="sidebar-divider" />

        <div class="sidebar-card d-none d-lg-flex">
          <img
            class="sidebar-card-illustration mb-2"
            style={{ height: "10rem" }}
            src="/images/scopeEbook.png"
            alt="..."
          />
        </div>
      </ul>
    </div>
  );
};

export default UserMenu;
