import React from "react";
import Base from "./Base";
import { Helmet } from "react-helmet";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
    <Helmet>
        <title>Scope Ebook - Home</title>
      </Helmet>
    <Base title="Scope Ebook - Home">
      <div class="main-banner" id="top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="caption header-text">
                <h6>SCOPeBook</h6>
                <div class="line-dec"></div>
                <h4>
                  Get your <em>Scope Ebook</em> And Explore the Field of <span>Therapeutic Endoscopy</span>
                </h4>
                <p className="text-dark">
                  A book brought to you by Scope Health UK.
                   SME Northern Award Winnng for the Best Health Care Training & Education Courses Provider 2022. Know More{" "}
                  <NavLink to="/">Home</NavLink>, <NavLink to="/features">Features</NavLink>,
                  and <NavLink to="/pricing">Pricing</NavLink>.
                </p>
                <div id="example-div" className="d-flex aligns-items-center" >
 
                <div className="main-button scroll-to-section">
                  <NavLink to="/features">Discover More</NavLink>
                </div>
                <span style={{marginTop:10}}>or</span>
                <div className="second-button">
                  <NavLink to="/contact">Check our FAQs</NavLink>
                </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/images/scopeEbook.png" />
            </div>
          </div>
        </div>
      </div>
    </Base>
    </div>
  );
};

export default Home;
