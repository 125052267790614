import React from "react";
import Base from "./Base";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Scope Ebook - Contact</title>
      </Helmet>

      <Base>
        <div class="contact-us section" id="contact">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-us-content">
                  <div class="row">
                    <div class="col-lg-12">
                      <form id="contact-form" action="" method="post">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="section-heading">
                              <h2>
                                <em>Contact Us</em> &amp; Get In{" "}
                                <span>Touch</span>
                              </h2>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="name"
                                name="name"
                                id="name"
                                placeholder="Your Name..."
                                autocomplete="on"
                                required
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="surname"
                                name="surname"
                                id="surname"
                                placeholder="Your Surname..."
                                autocomplete="on"
                                required
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                pattern="[^ @]*@[^ @]*"
                                placeholder="Your E-mail..."
                                required=""
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input
                                type="subject"
                                name="subject"
                                id="subject"
                                placeholder="Subject..."
                                autocomplete="on"
                              />
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <textarea
                                name="message"
                                id="message"
                                placeholder="Your Message"
                              ></textarea>
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <button
                                type="submit"
                                id="form-submit"
                                class="orange-button"
                              >
                                Send Message Now
                              </button>
                            </fieldset>
                          </div>
                        </div>
                      </form>
                      <div class="more-info">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="info-item">
                              <i class="fa fa-phone"></i>
                              <h4>
                                <a href="#">010-020-0340</a>
                              </h4>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="info-item">
                              <i class="fa fa-envelope"></i>
                              <h4>
                                <a href="#">admin@scopebook.uk</a>
                              </h4>
                            
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="info-item">
                              <i class="fa fa-map-marker"></i>
                              <h4>
                                <a href="#">
                                 United Kingdom
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Base>
    </div>
  );
};

export default Contact;
