import React, { useEffect } from "react";
import AdminChapterTitlesBase from "../../core/admin/AdminChapterTitlesBase";

const ChapterOverview = () => {
  const preload = () => {};

  useEffect(() => {
    preload();
  }, []);

  return (
    <AdminChapterTitlesBase>
      <div className="row">
        <div className="col-12 p-4">
          <h4 className="title">Chapter Overview</h4>
          <div className="row">
            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Titles
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Authors
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Banners
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Checklists
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total References
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Reference Videos
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Checklists
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Total Views
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="fas  fa-2x text-info">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End of Row */}
        </div>
      </div>
    </AdminChapterTitlesBase>
  );
};

export default ChapterOverview;
