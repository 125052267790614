import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AdminBase from "../core/admin/AdminBase";
import { isAuthenticated } from "../auth/helper";

import { Tag } from "primereact/tag";
import {
  createUser,
  deleteBulkUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from "./helper/adminapicalls";

const Users = () => {
  // =========================START OF DECLARATION OF VALUES==================
  const { user, token } = isAuthenticated();

  const verificationCodeStatusOptions = ["Inactive", "Active", "Revoked"];
  const verifiedOptions = ["Yes", "No"];
  const roleOptions = ["Admin", "Delegate"];

  const [userProfile, setUserProfile] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    verified: "",
    error: "",
    formData: "",
  });

  const {
    _id,
    firstname,
    lastname,
    email,
    verified,
    role,
    error,
    formData,
  } = userProfile;

  let emptyUserProfile = {
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    verified:"",
    role: "",
    error: "",
    formData: "",
  };

  const [userProfiles, setUserProfiles] = useState(null);

  const [userProfileDialog, setUserProfileDialog] = useState(false);

  const [deleteUserProfileDialog, setDeleteUserProfileDialog] = useState(false);

  const [deleteUserProfilesDialog, setDeleteUserProfilesDialog] = useState(false);

  const [selectedUserProfiles, setSelectedUserProfiles] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);

  // =========================END OF DECLARATION OF VALUES==================

  // =========================START OF PRELOAD ==================

  const preload = () => {
    getAllUsers(user._id, token)
      .then((data) => {
        // console.log(data)
        if (data.error) {
          setUserProfile({ ...userProfile, error: data.error });
        } else {
          setUserProfiles(data);
          setUserProfile({ ...userProfile, formData: new FormData() });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    preload();
  }, []);

  // =========================END OF PRELOAD==================

    // ============START OF CUD OPERATIONS ==================
    const saveUserProfile = (UserProfile) => {
        setSubmitted(true);
        setUserProfile({ ...userProfile, error: "", loading: true });
        if (!_id) {
          createUser(user._id, token, {
            firstname,
            lastname,
            email,
            role
          })
            .then((data) => {
              if (data.error) {
                setUserProfile({
                  ...userProfile,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "User Profile Not Created",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "User Profile Created",
                  life: 3000,
                });
                setUserProfileDialog(false);
                setUserProfile(emptyUserProfile);
              }
            })
            .catch((err) => console.log(err));
        } else {
          updateUser(user._id, token, _id, {
            firstname,
            lastname,
            email,
            role,
            verified
          })
            .then((data) => {
              if (data.error) {
                setUserProfile({
                  ...userProfile,
                  error: data.error,
                  formData: new FormData(),
                });
                toast.current.show({
                  severity: "error",
                  summary: "User Profile Not Updated",
                  detail: data.error,
                  life: 5000,
                });
              } else {
                preload();
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: "User Profile Updated",
                  life: 3000,
                });
                setUserProfileDialog(false);
                setUserProfile(emptyUserProfile);
              }
            })
            .catch((err) => console.log(err));
        }
      };
    
      const editUserProfile = (userProfile) => {
        setUserProfile({ ...userProfile });
        setUserProfileDialog(true);
      };
    
      const deleteUserProfileDB = () => {
        deleteUser(user._id, _id, token)
          .then((data) => {
            if (data.error) {
              setUserProfile({
                ...userProfile,
                error: data.error,
                formData: new FormData(),
              });
              toast.current.show({
                severity: "error",
                summary: "User Profile Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeleteUserProfileDialog(false);
              setUserProfile(emptyUserProfile);
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "User Profile Deleted",
                life: 3000,
              });
              setDeleteUserProfileDialog(false);
              setUserProfile(emptyUserProfile);
            }
          })
          .catch((err) => console.log(err));
      };
    
      const deleteSelectedUserProfiles = () => {
        let users = selectedUserProfiles;
        const userIds = [];
        {
          Object.entries(users).map(([key, value]) =>
            userIds.push(users[key]._id)
          );
        }
        deleteBulkUser(user._id, token, userIds)
          .then((data) => {
            if (data.error) {
              toast.current.show({
                severity: "error",
                summary: "User Profiles Not Deleted",
                detail: data.error,
                life: 5000,
              });
              setDeleteUserProfilesDialog(false);
            } else {
              preload();
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "User Profiles Deleted",
                life: 3000,
              });
              setDeleteUserProfilesDialog(false);
            }
          })
          .catch((err) => console.log(err));
      };
      // ============END OF CUD OPERATIONS ==================

  // =========================START OF DIALOGS OPEN AND HIDE ==================
  const openNewUserProfile = () => {
    setUserProfile(emptyUserProfile);
    setSubmitted(false);
    setUserProfileDialog(true);
  };

  const hideDialogUserProfile = () => {
    setSubmitted(false);
    setUserProfileDialog(false);
  };

  const hideDeleteUserProfileDialog = () => {
    setDeleteUserProfileDialog(false);
  };

  const hideDeleteUserProfilesDialog = () => {
    setDeleteUserProfilesDialog(false);
  };

  // =========================END OF DIALOGS OPEN AND HIDE ==================

  // ============START OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================
  const userProfileDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDialogUserProfile}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveUserProfile} />
    </React.Fragment>
  );

  const deleteUserProfileDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUserProfileDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteUserProfileDB}
      />
    </React.Fragment>
  );

  const deleteUserProfilesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUserProfilesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedUserProfiles}
      />
    </React.Fragment>
  );

  const confirmDeleteUserProfile = (userProfile) => {
    setUserProfile(userProfile);
    setDeleteUserProfileDialog(true);
  };

  const confirmDeleteSelectedUserProfiles = () => {
    setDeleteUserProfilesDialog(true);
  };

  const actionBodyTemplateUserProfile = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editUserProfile(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteUserProfile(rowData)}
        />
      </React.Fragment>
    );
  };

  // ============END OF CONFIRMATIONS DIALOGS OPEN AND HIDE ==================



  // ============START OF EXPORT ==================
  const cols = [
    { field: '_id', header: 'ID' },
    { field: 'firstname', header: 'First Name' },
    { field: 'lastname', header: 'Last Name' },
    { field: 'email', header: 'Email' },
    { field: 'verified', header: 'Verified' },
    { field: 'role', header: 'Role' },
    
];

const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  
  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);

            doc.autoTable(exportColumns, userProfiles);
            doc.save('users.pdf');
        });
    });
};

const exportExcel = () => {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(userProfiles);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'users');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};

  // ============END OF EXPORT ==================

  // ============START OF HANDLE CHANGE ==================
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setUserProfile({ ...userProfile, error: "", [name]: value });
  };
  // ============END OF HANDLE CHANGE ==================

  // ============START OF TOOLBARS ==================
  const leftToolbarTemplateUserProfile = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNewUserProfile}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelectedUserProfiles}
          disabled={!selectedUserProfiles || !selectedUserProfiles.length}
        />
      </div>
    );
  };

  const rightToolbarTemplateUserProfile = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2 float-end">
             <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help m-2"
        onClick={exportCSV}
      />
            <Button type="button" className="m-1" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" className="m-1" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
     
    );
  };

  // ============END OF TOOLBARS ==================

  // ============START OF STATUS SEVERITY ==================
  const getVerifiedSeverity = (userProfile) => {
    switch (userProfile.verified) {
      case "Yes":
        return "success";

      case "No":
        return "danger";

      default:
        return null;
    }
  };

  const verifiedBodyTemplate = (rowData) => {
    return <Tag value={rowData.verified} severity={getVerifiedSeverity(rowData)}></Tag>;
  };
  // ============END OF STATUS SEVERITY ==================

  // ============START OF HEADER ==================

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage UserProfiles</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  // ============END OF HEADER ==================

  return (
    <AdminBase title="UserProfiles">
      <div>
        <Toast ref={toast} />
        {/*  START OF DATA TABLE  */}
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplateUserProfile}
            right={rightToolbarTemplateUserProfile}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={userProfiles}
            selection={selectedUserProfiles}
            onSelectionChange={(e) => setSelectedUserProfiles(e.value)}
            dataKey="_id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              body={actionBodyTemplateUserProfile}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="firstname"
              header="First Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="lastname"
              header="Last Name"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="verified"
              header="Verified"
              body={verifiedBodyTemplate}
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
             <Column
              field="role"
              header="Role"
              sortable
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
        </div>
        {/*  END OF DATA TABLE  */}

        {/*  START OF CREATE & EDIT FORM   */}
        <Dialog
          visible={userProfileDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Chapter Details"
          modal
          className="p-fluid"
          footer={userProfileDialogFooter}
          onHide={hideDialogUserProfile}
        >
          <div className="field">
            <label htmlFor="firstname" className="font-bold">
              First Name
            </label>
            <InputText
              id="firstname"
              value={firstname}
              onChange={handleChange("firstname")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !firstname,
              })}
            />
            {submitted && !firstname && (
              <small className="p-error">First Name is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="lastname" className="font-bold">
              Last Name
            </label>
            <InputText
              id="lastname"
              value={lastname}
              onChange={handleChange("lastname")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !lastname,
              })}
            />
            {submitted && !lastname && (
              <small className="p-error">Last Name To is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="email" className="font-bold">
              Email
            </label>
            <InputText
              id="email"
              value={email}
              onChange={handleChange("email")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !email,
              })}
            />
            {submitted && !email && (
              <small className="p-error">Email To is required.</small>
            )}
          </div>
          <div className="field">
            <label htmlFor="Verified" className="font-bold">
              Verified
            </label>

            <Dropdown
              value={verified}
              onChange={handleChange("verified")}
              options={verifiedOptions}
              placeholder="Select a Verified"
              showClear
              className={classNames({
                "p-invalid": submitted && !verified,
              })}
            />
            {submitted && !verified && (
              <small className="p-error">Verified is required.</small>
            )}
          </div>
         
          <div className="field">
            <label htmlFor="role" className="font-bold">
              Role
            </label>

            <Dropdown
              value={role}
              onChange={handleChange("role")}
              options={roleOptions}
              placeholder="Select a Role"
              showClear
              className={classNames({
                "p-invalid": submitted && !role,
              })}
            />
            {submitted && !role && (
              <small className="p-error">Role is required.</small>
            )}
          </div>

          
        </Dialog>
        {/*  END OF CREATE & EDIT FORM   */}
        {/*  START OF DELETE FORM   */}
        <Dialog
          visible={deleteUserProfileDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteUserProfileDialogFooter}
          onHide={hideDeleteUserProfileDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {userProfile && (
              <span>
                Are you sure you want to delete <b>{firstname}</b> {" "}
                <b>{lastname}</b>?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF DELETE FORM   */}

        {/*  START OF MULTIPLE DELETE FORM   */}
        <Dialog
          visible={deleteUserProfilesDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteUserProfilesDialogFooter}
          onHide={hideDeleteUserProfilesDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {userProfile && (
              <span>
                Are you sure you want to delete the selected UserProfiles?
              </span>
            )}
          </div>
        </Dialog>
        {/*  END OF MULTIPLE DELETE FORM   */}
      </div>
    </AdminBase>
  );
};
export default Users;
